import React, { useState, useEffect } from 'react';
import { useNavigationLinks } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';

import Layout from './Layout';
import './ErrorPage.css';

const TIMEOUT_COUNT = 10;

function ErrorPage() {
  const [count, setCount] = useState(TIMEOUT_COUNT);
  const { goTo } = useNavigationLinks();
  const onClickLanding = () => goTo('landing');

  useEffect(() => {
    let timeout;
    if (count === 0) {
      onClickLanding();
    } else {
      timeout = setTimeout(() => setCount(count - 1), 1000);
    }
    return () => clearTimeout(timeout);
  });

  return (
    <Layout>
      <div id="errorPageDiv">
        <div className="error-page">
          <div className="text-right">
            <FontAwesomeIcon icon={faLockAlt} fixedWidth size="5x" />
          </div>
          <div>
            <h4 className="">NOT AUTHORIZED</h4>
            <div className="pb-4">
              <p>You are not authorized to access this page</p>
              <p>Please contact the system administration if you have any trouble.</p>
              <p>
                Redirect in <strong>{count}</strong>
              </p>
            </div>
            <button type="button" onClick={onClickLanding} className="btn btn-primary">
              Go To Main
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default React.memo(ErrorPage);
