import useApi from './useApi';

const useImpersonationService = () => {
  const api = useApi();

  const impersonate = async (id) => await api.post(`/impersonation/impersonate/${id}`);
  const stopImpersonate = async () => await api.put(`/impersonation/impersonate/stop`);
  const signIntoMission = async (id) => await api.post(`/impersonation/signIntoMission/${id}`);
  const terminateSignIntoMission = async () => await api.delete(`/impersonation/terminateSignIntoMission`);
  const search = async (name) => api.post('/impersonation/search', name);
  return {
    impersonate,
    stopImpersonate,
    signIntoMission,
    terminateSignIntoMission,
    search,
  };
};

export default useImpersonationService;
