import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useEmployeeSearchService } from 'hooks';
import { SessionContext } from 'contexts';
import { employeeType, roleType } from 'constants/index';

function UserSearch({
  value,
  setValue,
  isMulti,
  className,
  employeeTypeOption,
  isGlobal,
  menuPlacement,
  isDisabled,
  role,
  bureauId,
  placeholder,
}) {
  const MIN_INPUT_LEN = 3;
  const { sessionHelpers } = useContext(SessionContext);
  const service = useEmployeeSearchService();

  const getMissionId = () => (isGlobal ? 0 : sessionHelpers.getCurrentMissionId());

  const asyncSearch = async (inputValue) => {
    if (inputValue.length < MIN_INPUT_LEN) {
      return [];
    }

    const response = await service.search({
      missionId: getMissionId(),
      postId: 0,
      sectionId: 0,
      nameTerm: inputValue,
      employeeType: employeeTypeOption,
      emailTerm: '',
      positionTerm: '',
      isArchived: false,
      role,
      bureauId,
    });
    let result = null;
    if (response && response.ok) {
      result = response.data;
    }
    return result;
  };

  const loadOptions = (inputValue) => {
    const thePromise = new Promise((resolve, reject) => {
      let result = [];
      if (typeof inputValue === 'string' && inputValue.length >= MIN_INPUT_LEN) {
        result = asyncSearch(inputValue);
      }
      if (result) {
        resolve(result);
      } else {
        reject(new Error('error resolving the promise searching for users'));
      }
    });
    return thePromise;
  };

  const noOptionsMessage = (obj) => {
    if (obj.inputValue.length > 0) return 'No user found';
    return 'Start typing a name';
  };

  return (
    <AsyncSelect
      value={value}
      isMulti={isMulti}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={false}
      className={className}
      onChange={(items) => setValue(items)}
      getOptionLabel={(item) => item.name}
      getOptionValue={(item) => item.employeeId}
      menuPlacement={menuPlacement}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isDisabled}
      isClearable
      placeholder={placeholder}
    />
  );
}
UserSearch.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  setValue: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  employeeTypeOption: PropTypes.number,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
  isGlobal: PropTypes.bool,
  role: PropTypes.number,
  bureauId: PropTypes.number,
  placeholder: PropTypes.string,
};
UserSearch.defaultProps = {
  isMulti: false,
  className: null,
  employeeTypeOption: employeeType.ALL,
  menuPlacement: 'bottom',
  isDisabled: false,
  isGlobal: false,
  role: roleType.None,
  bureauId: 0,
  placeholder: null,
};
export default UserSearch;
