import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

function NoteItem({ value, readOnly, removeItem }) {
  const [isMouseOver, toogleMouseOver] = useState(false);
  const [isMouseOverBtn, toogleMouseOverBtn] = useState(false);
  const [isMouseClicked, toogleMouseClicked] = useState(false);

  return (
    <div className="comment-block">
      <div
        onMouseEnter={() => toogleMouseOver(true)}
        onMouseLeave={() => toogleMouseOver(false)}
        className={isMouseOver ? 'mouse-over-on' : ''}
      >
        {!readOnly && (
          <div className={`float-right ${isMouseOver ? 'd-block' : 'd-none'} ${isMouseClicked ? 'mouse-clicked' : ''}`}>
            <FontAwesomeIcon
              icon={faTrash}
              color={isMouseOverBtn ? 'red' : 'gray'}
              onMouseEnter={() => toogleMouseOverBtn(true)}
              onMouseLeave={() => toogleMouseOverBtn(false)}
              onMouseDown={() => toogleMouseClicked(true)}
              onMouseUp={() => toogleMouseClicked(false)}
              onClick={removeItem}
            />
          </div>
        )}
        {value}
      </div>
    </div>
  );
}
NoteItem.propTypes = {
  value: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  removeItem: PropTypes.func,
};
NoteItem.defaultProps = {
  readOnly: false,
  removeItem: undefined,
};

export default NoteItem;
