import React, { useCallback, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faHandPaper, faUserSlash } from '@fortawesome/pro-light-svg-icons';
import {
  faFlagAlt,
  faThumbsUp as fasThumbsUp,
  faThumbsDown as fasThumbsDown,
  faEquals,
  faClock,
  faArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'reactstrap';
import { formOfRecognition, nominationStatus, votingStatus } from 'constants/index';
import { ActionButton } from 'components';
import MessageModal from 'components/MessageModal';

const initialState = {
  tooltipApprovedBy: false,
  tooltipDisapprovedBy: false,
  tooltipDowngradedBy: false,
  tooltipOnHoldBy: false,
  tooltipRecusedBy: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_APPROVED_BY':
      return { ...state, tooltipApprovedBy: !state.tooltipApprovedBy };
    case 'TOGGLE_DISAPPROVED_BY':
      return { ...state, tooltipDisapprovedBy: !state.tooltipDisapprovedBy };
    case 'TOGGLE_DOWNGRADED_BY':
      return { ...state, tooltipDowngradedBy: !state.tooltipDowngradedBy };
    case 'TOGGLE_ONHOLD_BY':
      return { ...state, tooltipOnHoldBy: !state.tooltipOnHoldBy };
    case 'TOGGLE_RECUSED_BY':
      return { ...state, tooltipRecusedBy: !state.tooltipRecusedBy };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function VotingNominationItem({ nomination, showCheckbox, onClickCheck, helperFunctions }) {
  const [store, dispatch] = useReducer(reducer, initialState);
  const [openCriteria, setOpenCriteria] = useState(false);

  const getColorState = useCallback((status) => {
    let colorClass = 'text-warning';
    if (status === votingStatus.APPROVED) colorClass = 'text-success';
    if (status === votingStatus.DISAPPROVED) colorClass = 'text-danger';
    if (status === votingStatus.DOWNGRADED) colorClass = 'text-primary';
    return colorClass;
  }, []);

  const getStatusIcon = (status) => {
    if (status === votingStatus.APPROVED) return fasThumbsUp;
    if (status === votingStatus.DISAPPROVED) return fasThumbsDown;
    if (status === votingStatus.DOWNGRADED) return faArrowDown;
    if (status === votingStatus.PENDING_TIE) return faEquals;
    if (status === votingStatus.PENDING) return faClock;
    return faFlagAlt;
  };

  const readyToBeSignedOff = (status) =>
    !helperFunctions.isSignOffMode() ||
    status === votingStatus.APPROVED ||
    status === votingStatus.DISAPPROVED ||
    status === votingStatus.DOWNGRADED;

  const getFormOfRecognitionUnit = useCallback((option) => {
    switch (option) {
      case formOfRecognition.CASH_ONLY:
        return 'USD';
      case formOfRecognition.TIMEOFF_ONLY:
        return 'Hours';
      default:
        return '';
    }
  }, []);

  const getFormattedTooltip = (value, label) => {
    if (!value) {
      return 'Loading...';
    }
    if (value instanceof Array && !value.length) {
      return 'N/A';
    }

    return (
      <>
        <div className="mb-2">{label}</div>
        {value.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx}>{item}</div>
        ))}
      </>
    );
  };

  return (
    <div className="container-fluid py-2 px-0">
      <div className="row mx-0">
        <div className="col-2 text-nowrap">
          {showCheckbox && (
            <Checkbox
              className="mr-1 pt-1"
              value={nomination.id}
              checked={nomination.selected}
              onChange={onClickCheck}
              disabled={
                !readyToBeSignedOff(
                  helperFunctions.isBureauVoting() ? nomination.bureauVotingStatus : nomination.votingStatus
                )
              }
              onMouseDown={(e) => e.stopPropagation()}
            />
          )}
          {nomination.nominationKey || ''}
          {helperFunctions.isSignOffMode() && (
            <FontAwesomeIcon
              icon={getStatusIcon(
                helperFunctions.isBureauVoting() ? nomination.bureauVotingStatus : nomination.votingStatus
              )}
              className={`ml-2 ${getColorState(
                helperFunctions.isBureauVoting() ? nomination.bureauVotingStatus : nomination.votingStatus
              )}`}
            />
          )}
        </div>
        {[nominationStatus.PENDING_BUREAU_COMMITTEE_VOTE, nominationStatus.PENDING_BUREAU_COMMITTEE_CHAIR].includes(
          nomination.nominationStatus
        ) ? (
          <div className="col-2 text-center m-auto text-nowrap d-flex align-items-center justify-content-center">
            {nomination.awardTypeName}
            <ActionButton
              tooltip="Click to view Award Criteria"
              className="p-button-text p-button-plain"
              icon={['far', 'info-circle']}
              onClick={() => setOpenCriteria(true)}
            />
          </div>
        ) : (
          <div className="col-2 text-center m-auto text-nowrap">{nomination.awardTypeName}</div>
        )}

        <div className="col-2 text-center">
          {nomination.recommendedAmount} {getFormOfRecognitionUnit(nomination.formOfRecognition)}
        </div>
        <div className="col-3">{nomination.nominees && nomination.nominees.map((item) => item.value).join('; ')}</div>
        {nomination.summary &&
          !helperFunctions.isSignOffMode() &&
          !nomination.showVotes &&
          (nomination.hasRecused ? (
            <div className="col-3 text-center text-nowrap font-italic">Recused</div>
          ) : (
            <div className="col-3 text-center text-nowrap font-italic">Not voted yet</div>
          ))}

        {nomination.summary && (helperFunctions.isSignOffMode() || (nomination.showVotes && !nomination.hasRecused)) && (
          <div className="col-3 text-center text-nowrap">
            <Tooltip
              placement="bottom"
              isOpen={store.tooltipApprovedBy}
              target={`thumbsup_${nomination.id}`}
              toggle={() => dispatch({ type: 'TOGGLE_APPROVED_BY' })}
            >
              {getFormattedTooltip(nomination.approvedBy, 'Approved by:')}
            </Tooltip>
            <Tooltip
              placement="bottom"
              isOpen={store.tooltipDisapprovedBy}
              target={`thumbsdown_${nomination.id}`}
              toggle={() => dispatch({ type: 'TOGGLE_DISAPPROVED_BY' })}
            >
              {getFormattedTooltip(nomination.disapprovedBy, 'Disapproved by:')}
            </Tooltip>
            <Tooltip
              placement="bottom"
              isOpen={store.tooltipDowngradedBy}
              target={`arrowdown_${nomination.id}`}
              toggle={() => dispatch({ type: 'TOGGLE_DOWNGRADED_BY' })}
            >
              {getFormattedTooltip(nomination.downgradedBy, 'Downgraded by:')}
            </Tooltip>
            <Tooltip
              placement="bottom"
              isOpen={store.tooltipOnHoldBy}
              target={`handpaper_${nomination.id}`}
              toggle={() => dispatch({ type: 'TOGGLE_ONHOLD_BY' })}
            >
              {getFormattedTooltip(nomination.onHoldBy, 'On Hold by:')}
            </Tooltip>
            <Tooltip
              placement="bottom"
              isOpen={store.tooltipRecusedBy}
              target={`userslash_${nomination.id}`}
              toggle={() => dispatch({ type: 'TOGGLE_RECUSED_BY' })}
            >
              {getFormattedTooltip(nomination.recusedBy, 'Recused by:')}
            </Tooltip>

            <FontAwesomeIcon
              id={`thumbsup_${nomination.id}`}
              icon={faThumbsUp}
              onMouseEnter={() => helperFunctions.getVotersNames(nomination.id)}
            />
            <span className="mx-2">{nomination.summary.totalApproved}</span>
            <FontAwesomeIcon
              id={`thumbsdown_${nomination.id}`}
              icon={faThumbsDown}
              onMouseEnter={() => helperFunctions.getVotersNames(nomination.id)}
            />
            <span className="mx-2">{nomination.summary.totalDisapproved}</span>
            <FontAwesomeIcon
              id={`arrowdown_${nomination.id}`}
              icon={faArrowDown}
              onMouseEnter={() => helperFunctions.getVotersNames(nomination.id)}
            />
            <span className="mx-2">{nomination.summary.totalDowngraded}</span>
            <FontAwesomeIcon
              id={`handpaper_${nomination.id}`}
              icon={faHandPaper}
              onMouseEnter={() => helperFunctions.getVotersNames(nomination.id)}
            />
            <span className="mx-2">{nomination.summary.totalOnHold}</span>
            <FontAwesomeIcon
              id={`userslash_${nomination.id}`}
              icon={faUserSlash}
              onMouseEnter={() => helperFunctions.getVotersNames(nomination.id)}
            />
            <span className="mx-2">{nomination.summary.totalRecused}</span>
          </div>
        )}
      </div>
      {openCriteria && (
        <MessageModal
          isModalOpened={openCriteria}
          onClickCloseModal={() => setOpenCriteria(false)}
          header="Award Criteria"
          message={nomination.awardCriteria}
          showCancel={false}
          isInnerHTML
        />
      )}
    </div>
  );
}
VotingNominationItem.propTypes = {
  nomination: PropTypes.object.isRequired,
  showCheckbox: PropTypes.bool.isRequired,
  onClickCheck: PropTypes.func.isRequired,
  helperFunctions: PropTypes.object.isRequired,
};

export default React.memo(VotingNominationItem);
