
import React from 'react';
import { Link } from 'react-router-dom';
import gtmLogo from 'assets/images/GTM-Logo-LockUp-Reverse.png';
import awardsLogo from 'assets/images/oas_logo.png';

function TopBar() {
  return (
    <header className="bg-dark d-print-none">
      <div className="row">
        <div id="brand" className="col-12">
          <nav className="navbar bg-dark mx-auto col-12">
            <div className="col-md-4">
              <Link className="navbar-brand" to="/">
                <img
                  src={gtmLogo}
                  className="align-top gtm-logo d-none d-sm-inline"
                  alt="Bureau of Global Talent Management logo with Official Seal of the United States"
                />
                <img src={awardsLogo} className="align-top awards-logo d-none d-sm-inline" alt="ADG logo" />
              </Link>
            </div>
          </nav>
        </div>
        <hr className="header-divider" />
      </div>
    </header>
  );
}
export default React.memo(TopBar);
