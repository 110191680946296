import React, { useEffect, useContext, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Skeleton } from 'primereact/skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdBadge, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { SessionContext } from 'contexts';
import { useEmployeeSearchService } from 'hooks';
import { dynamicSort } from 'utils/sorting';
import { requestStatus, employeeType } from 'constants/index';

const loadingRows = new Array(4);
const rowLoadingTemplate = () => <Skeleton />;

const initialState = {
  availableParamsRequest: requestStatus.REQUEST_NOT_INITIATED,
  availableParams: {
    loaded: false,
    bureaus: [],
    missions: [],
    posts: [],
    sections: [],
    missionsAndBureaus: [],
  },
  searchParams: {
    selectedMissionId: 0,
    selectedPostId: 0,
    selectedSectionId: 0,
    isLocalStaff: true,
    nameTerm: '',
    emailTerm: '',
    positionTerm: '',
    isArchivedNominees: false,
  },
  employeeTypeFilter: employeeType.ALL,
  searchResult: [],
  selectedRecords: [],
  searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_AVAILABLE_PARAMS':
      return {
        ...state,
        availableParams: { ...state.availableParams, ...action.data },
      };

    case 'UPDATE_FILTER':
      return { ...state, employeeTypeFilter: action.data };

    case 'START_REQUEST_SEARCH_PARAMS':
      return {
        ...state,
        availableParamsRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'UPDATE_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: { ...state.searchParams, ...action.data },
        availableParamsRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_SEARCH_RESULT':
      return {
        ...state,
        searchResult: action.data,
        searchRequestStatus: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_SELECTED_RECORDS':
      return { ...state, selectedRecords: action.data };

    case 'START_SEARCH_REQUEST':
      return {
        ...state,
        searchRequestStatus: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'RESET_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: initialState.searchParams,
        searchResult: initialState.searchResult,
        selectedRecords: initialState.selectedRecords,
        employeeTypeFilter: initialState.employeeTypeFilter,
        searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
      };
    case 'CLEAR_SELECTED_RECORDS':
      return {
        ...state,
        searchResult: [],
        selectedRecords: initialState.selectedRecords,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const getMissionsAndBureausList = (bureaus, missions) => {
  const result = missions.map((item) => {
    const bureauName = bureaus.find((elem) => elem.key === item.bureauId).value;
    return { key: item.id, value: `${bureauName} - ${item.name}` };
  });
  return result.sort(dynamicSort('value'));
};

function EmployeeSearchModal({ isModalOpened, onClickCloseModal, addSelected }) {
  const { sessionStore } = useContext(SessionContext);
  // const { sectionId, postId } = sessionStore.employeeUser;
  const missionId = sessionStore.currentMissionId;
  const [store, dispatch] = useReducer(reducer, initialState);
  const service = useEmployeeSearchService();

  const setParamValue = (paramValue) => {
    dispatch({
      type: 'UPDATE_SEARCH_PARAMS',
      data: { ...paramValue },
    });
  };

  const setSelectedRecords = (selectedRecords) => {
    dispatch({
      type: 'UPDATE_SELECTED_RECORDS',
      data: selectedRecords,
    });
  };
  const onClickSelect = (closeModal) => {
    addSelected(store.selectedRecords);
    if (closeModal) {
      onClickCloseModal();
    }
  };
  const onClickReset = useCallback(() => {
    dispatch({
      type: 'RESET_SEARCH_PARAMS',
    });
    dispatch({
      type: 'UPDATE_SEARCH_PARAMS',
      data: {
        selectedMissionId: missionId,
        selectedPostId: 0, // TODO
        selectedSectionId: 0,
      },
    });
  }, [missionId]);

  function autoOpenPostsDropdown(length) {
    if (length > 1) {
      document.getElementById('searchposts').click();
    }
  }

  const hasSelectedFields = () => store.selectedRecords.length > 0;

  const nameWithBadgeTemplate = (rowData) => {
    console.log('rowData', rowData);
    let colorClass = 'badge-les';
    let titleDesc = 'Local Staff';
    const archivedClass = 'archived-emp';
    const archivedTitle = 'Archived Employee';
    if (!rowData.isLocalStaff) {
      colorClass = 'badge-usdh';
      titleDesc = 'USDH';
    }
    return (
      <>
        <i className={colorClass} title={titleDesc}>
          <FontAwesomeIcon size="lg" icon={faIdBadge} fixedWidth />
        </i>
        <span>{rowData.name}</span>
        {rowData.isArchived && (
          <i className={archivedClass} title={archivedTitle}>
            <FontAwesomeIcon size="lg" icon={faExclamationCircle} fixedWidth />
          </i>
        )}
      </>
    );
  };

  async function onSearchClick() {
    const searchRequestData = {
      missionId: store.searchParams.selectedMissionId,
      postId: store.searchParams.selectedPostId,
      sectionId: store.searchParams.selectedSectionId,
      isLocalStaff: store.searchParams.isLocalStaff,
      nameTerm: store.searchParams.nameTerm,
      emailTerm: store.searchParams.emailTerm,
      positionTerm: store.searchParams.positionTerm,
      isArchivedNominees: true,
    };
    dispatch({ type: 'START_SEARCH_REQUEST' });
    const response = await service.search(searchRequestData);
    if (response && response.ok) {
      const employeeResultList = response.data.map((item) => ({
        ...item,
        assignment: `${item.sectionName} @ ${item.postName}`,
        usdhSupervisorName: `${item.supervisorName}`,
      }));
      dispatch({ type: 'UPDATE_SEARCH_RESULT', data: employeeResultList });
    }
  }

  async function loadPosts(selectedMissionId) {
    const response = await service.getAvailablePostsByMission(selectedMissionId);
    if (response && response.ok) {
      dispatch({
        type: 'UPDATE_AVAILABLE_PARAMS',
        data: {
          posts: response.data,
        },
      });
      dispatch({
        type: 'UPDATE_SEARCH_PARAMS',
        data: {
          selectedPostId: response.data[0].key,
        },
      });
    }
    autoOpenPostsDropdown(response.data.length);
  }

  useEffect(() => {
    if (!isModalOpened) {
      dispatch({ type: 'CLEAR_SELECTED_RECORDS' });
      onClickReset();
    }
  }, [isModalOpened, onClickReset]);

  useEffect(() => {
    async function loadSearchParameters() {
      dispatch({ type: 'START_REQUEST_SEARCH_PARAMS' });
      const response = await service.getSearchAvailableParameters(missionId);
      if (response && response.ok) {
        dispatch({
          type: 'UPDATE_AVAILABLE_PARAMS',
          data: {
            bureaus: response.data.bureaus,
            sections: response.data.sections,
            missions: response.data.missions,
            posts: response.data.posts,
            missionsAndBureaus: getMissionsAndBureausList(response.data.bureaus, response.data.missions),
          },
        });
        dispatch({
          type: 'UPDATE_SEARCH_PARAMS',
          data: {
            selectedMissionId: missionId,
            selectedPostId: 0,
            selectedSectionId: 0,
          },
        });
      }
    }

    if (missionId && store.availableParamsRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadSearchParameters();
    }
  }, [service, missionId, store.availableParamsRequest]);

  const filteredResult = useCallback(
    () =>
      store.searchResult.filter((item) => {
        if (store.employeeTypeFilter === employeeType.ALL) {
          return true;
        }
        return item.isLocalStaff === (store.employeeTypeFilter === employeeType.LESTAFF);
      }),
    [store.employeeTypeFilter, store.searchResult]
  );

  return (
    <Modal isOpen={isModalOpened} toggle={onClickCloseModal} size="xl" className="search-employee-modal">
      <ModalHeader toggle={onClickCloseModal}>Search Employee</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="9">
            <Row>
              <Col>
                Bureau / Mission
                <div>
                  <Dropdown
                    value={store.searchParams.selectedMissionId}
                    options={store.availableParams.missionsAndBureaus}
                    optionLabel="value"
                    optionValue="key"
                    filterBy="value"
                    filter
                    showClear
                    className="w-100"
                    onChange={(e) => {
                      setParamValue({ selectedMissionId: e.value });
                      loadPosts(e.value);
                    }}
                  />
                </div>
              </Col>
              <Col>
                Post
                <div>
                  <Dropdown
                    id="searchposts"
                    value={store.searchParams.selectedPostId}
                    options={store.availableParams.posts}
                    optionLabel="value"
                    optionValue="key"
                    showClear
                    className="w-100"
                    onChange={(e) => setParamValue({ selectedPostId: e.value })}
                  />
                </div>
              </Col>
              <Col>
                Section
                <div>
                  <Dropdown
                    value={store.searchParams.selectedSectionId}
                    options={store.availableParams.sections}
                    optionLabel="value"
                    optionValue="key"
                    filterBy="value"
                    filter
                    showClear
                    className="w-100"
                    onChange={(e) => setParamValue({ selectedSectionId: e.value })}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                Employee&apos;s Name
                <InputText
                  value={store.searchParams.nameTerm}
                  onChange={(e) => setParamValue({ nameTerm: e.target.value })}
                  className="w-100"
                />
              </Col>
              <Col>
                Email
                <InputText
                  value={store.searchParams.emailTerm}
                  onChange={(e) => setParamValue({ emailTerm: e.target.value })}
                  className="w-100"
                />
              </Col>
              <Col>
                Job Title
                <InputText
                  value={store.searchParams.positionTerm}
                  onChange={(e) => setParamValue({ positionTerm: e.target.value })}
                  className="w-100"
                />
              </Col>
            </Row>
          </Col>
          <Col md="3" className="d-flex flex-column justify-content-center">
            <Button
              label="Search"
              icon="pi pi-search"
              className="p-button-primary m-1 w-80 align-self-center"
              onClick={() => onSearchClick()}
            />
            <Button
              label="Reset"
              icon="pi pi-replay"
              className="p-button-primary p-button-outlined m-1 w-80 align-self-center"
              onClick={() => onClickReset()}
            />
          </Col>
        </Row>
        <Row>
          <Container className="mt-2 nominee-modal-result">
            {store.searchRequestStatus === requestStatus.REQUEST_NOT_INITIATED && (
              <div>Please, fill the parameters and click on Search Button</div>
            )}
            {store.searchRequestStatus === requestStatus.REQUEST_IN_PROGRESS && (
              <DataTable value={loadingRows} className="p-datatable-striped">
                <Column selectionMode="multiple" headerStyle={{ width: '2em' }} body={rowLoadingTemplate} />
                <Column field="name" header="Name" body={rowLoadingTemplate} />
                <Column field="email" header="Email" body={rowLoadingTemplate} />
                <Column field="positionTitle" header="Position" body={rowLoadingTemplate} />
                <Column field="assignment" header="Section/Post" body={rowLoadingTemplate} />
              </DataTable>
            )}
            {store.searchRequestStatus === requestStatus.REQUEST_COMPLETED && (
              <DataTable
                value={filteredResult()}
                scrollable
                scrollHeight="flex"
                metaKeySelection={false}
                selection={store.selectedRecords}
                onSelectionChange={(e) => setSelectedRecords(e.value)}
                dataKey="employeeId"
                size="normal"
              >
                <Column selectionMode="multiple" style={{ flex: '0 0 2rem' }} />
                <Column field="name" header="Name" body={nameWithBadgeTemplate} />
                <Column field="email" header="Email" />
                <Column field="positionTitle" header="Position" />
                <Column field="assignment" header="Section/Post" />
              </DataTable>
            )}
          </Container>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="row w-100">
          <div className="col">
            <FontAwesomeIcon
              size="lg"
              icon={faFilter}
              fixedWidth
              className="mb-1 mr-1 color-primary"
              title="Employee type filter"
            />
            <span className="p-buttonset ml-1">
              <Button
                type="button"
                label="All"
                className={`p-button-primary ${
                  store.employeeTypeFilter === employeeType.ALL ? '' : 'p-button-outlined'
                }`}
                onClick={() => dispatch({ type: 'UPDATE_FILTER', data: employeeType.ALL })}
              />

              <Button
                type="button"
                label="LES"
                className={`p-button-primary ${
                  store.employeeTypeFilter === employeeType.LESTAFF ? '' : 'p-button-outlined'
                }`}
                onClick={() =>
                  dispatch({
                    type: 'UPDATE_FILTER',
                    data: employeeType.LESTAFF,
                  })
                }
              />
              <Button
                type="button"
                label="USDH"
                className={`p-button-primary ${
                  store.employeeTypeFilter === employeeType.USDH ? '' : 'p-button-outlined'
                }`}
                onClick={() => dispatch({ type: 'UPDATE_FILTER', data: employeeType.USDH })}
              />
            </span>
          </div>
          <div className="col text-right">
            <Button
              label="Select"
              icon="pi pi-check"
              className="p-button-primary mr-2 w-100px"
              onClick={() => onClickSelect()}
              disabled={!hasSelectedFields()}
            />
            <Button
              label="Select & Close"
              icon="pi pi-check-circle"
              className="p-button-primary mr-2 w-150px"
              onClick={() => onClickSelect(true)}
              disabled={!hasSelectedFields()}
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-secondary w-100px"
              onClick={() => onClickCloseModal()}
            />
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}
EmployeeSearchModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  onClickCloseModal: PropTypes.func.isRequired,
  addSelected: PropTypes.func.isRequired,
};

export default React.memo(EmployeeSearchModal);
