import React, { useCallback, useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { nominationAction } from 'constants/index';

const getTypesFromNominationsList = (nominations) => {
  const typesList = nominations.map((item) => ({ value: item.awardTypeId, text: item.awardTypeName }));
  typesList.unshift({ value: 0, text: 'All' });
  return uniqBy(typesList, 'value');
};

function SignOffToolbar({
  formStore,
  helperFunctions,
  onClickSelect,
  onClickSignOff,
  onClickTypeFilter,
  onClickChangeSelected,
  changeAndSignOffSelected,
}) {
  const [isApplyChangesReadOnly, setIsApplyChangesReadOnly] = useState(true);
  const [selectedNominations, setSelectedNominations] = useState([]);

  useEffect(() => {
    setSelectedNominations(helperFunctions.getSelected());
  }, [helperFunctions, setSelectedNominations]);

  const isReadOnly = useCallback(() => {
    if (selectedNominations?.length === 0) return true;
    if (selectedNominations.every((x) => x.awardTypeId === selectedNominations[0].awardTypeId)) return false;
    return true;
  }, [selectedNominations]);

  useEffect(() => {
    if (!isReadOnly()) {
      if (selectedNominations.length > 0) {
        if (selectedNominations.every((x) => x.isNominationChanged === true)) setIsApplyChangesReadOnly(false);
        else setIsApplyChangesReadOnly(true);
      }
    }
  }, [isReadOnly, selectedNominations, isApplyChangesReadOnly]);

  return (
    <div className="card container-fluid py-1 px-0 signoff-toolbar border-0">
      <div className="row">
        <div className="col-3 text-center">Select Nominations</div>
        <div className="col-3 text-center">Filter by Award Type</div>
        <div className="col-3 text-center" />
        <div className="col-3 text-center">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col-3 text-center btn-group" role="group">
          <Button
            label="All"
            icon="pi pi-check-square"
            className="p-button-outlined mr-1 w-100"
            onClick={() => onClickSelect(true)}
          />
          <Button
            label="None"
            icon="pi pi-times"
            className="p-button-outlined ml-1 w-100"
            onClick={() => onClickSelect(false)}
          />
        </div>
        <div className="col-2 text-center">
          <Dropdown
            value={formStore.filterType}
            options={getTypesFromNominationsList(formStore.loadedNominations)}
            onChange={(e) => onClickTypeFilter(e.value)}
            optionValue="value"
            optionLabel="text"
            className="w-100"
          />
        </div>
        {formStore.currentMode.toLowerCase() === 'exec' ? (
          <>
            <div className="col-2 text-center">
              <Button
                label="Change Selected"
                icon="pi pi-bolt"
                className="w-100 p-button-success"
                disabled={isReadOnly()}
                onClick={() => onClickChangeSelected()}
              />
            </div>
            <div className="col-1 text-center">
              <Button
                label="Save"
                icon="pi pi-save"
                className="w-100 p-button-secondary"
                hidden={isApplyChangesReadOnly}
                onClick={() => changeAndSignOffSelected(nominationAction.CHANGE)}
              />
            </div>
            <div className="col- text-center">
              <Button
                label="Save and Submit"
                icon="pi pi-send"
                className="w-100 p-button-secondary"
                hidden={isApplyChangesReadOnly}
                onClick={() => changeAndSignOffSelected(nominationAction.FORWARD)}
              />
            </div>
          </>
        ) : (
          <div className="col-4 text-center" />
        )}

        <div className="col-2 text-center">
          <Button
            label="Sign Off Selected"
            icon="pi pi-check"
            className="w-100 p-button-primary"
            disabled={helperFunctions.getSelected()?.length === 0}
            onClick={() => onClickSignOff()}
          />
        </div>
      </div>
    </div>
  );
}

SignOffToolbar.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  onClickSelect: PropTypes.func.isRequired,
  onClickSignOff: PropTypes.func.isRequired,
  onClickTypeFilter: PropTypes.func.isRequired,
  onClickChangeSelected: PropTypes.func.isRequired,
  changeAndSignOffSelected: PropTypes.func.isRequired,
};

export default SignOffToolbar;
