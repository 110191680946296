import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';
import { Button } from 'primereact/button';
import { roleType } from 'constants/index';
import UserSearch from 'components/UserSearch';

function MissionRoles({ store, dispatch, onSaveClick }) {
  return (
    <>
      <Row className="mt-3">
        <Col xs="12" md="12" lg="12" xl="12">
          <Card>
            <div>
              <table className="table w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="col-4">Mission Roles</th>
                    <th className="col-8">Assignees</th>
                  </tr>
                </thead>
                <tbody>
                  {store.missionAssignedEmployees.map((role) => (
                    <tr key={role.roleKey}>
                      <td>{role.roleName}</td>
                      <td>
                        <UserSearch
                          value={role.employees}
                          setValue={(assignees) => {
                            dispatch({
                              type: 'UPDATE_ASSIGNED_ROLES',
                              data: { roleKey: role.roleKey, assignees },
                            });
                          }}
                          isMulti
                          role={roleType[role.roleKey]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="p-3">
        <Button label="Save" icon="pi pi-save" className="p-button-primary min-w-150px" onClick={() => onSaveClick()} />
      </Row>
    </>
  );
}
MissionRoles.propTypes = {
  store: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
};

export default MissionRoles;
