import React, { useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { NotificationContext } from 'contexts';
import { requestStatus, nominationAction, processType } from 'constants/index';
import { useSignOffForm } from 'hooks/signoff';
import MessageModal from 'components/MessageModal';
import SignOffFormHeader from './SignOffHeader';
import SignOffNominationsList from './SignOffNominationsList';
import SignOffToolbar from './SignOffToolbar';
import VotingNominationDetailsModal from '../VotingForm/VotingNominationDetailsModal';
import ExecSignOffModal from './ExecSignOffModal';

function SignOffForm() {
  const { showSuccess, showWarn } = useContext(NotificationContext);
  const { formStore, dispatch, helperFunctions } = useSignOffForm();
  const { mode, navPostId, awardProcessTypeId, navNominationStatus, navCycleId } = useParams();
  const { loadListRequest } = formStore;

  if (mode.toLowerCase() !== formStore.currentMode) {
    dispatch({ type: 'SET_CURRENT_MODE', data: mode });
  }

  const getTitle = useCallback(() => {
    let title = 'Finance';
    if (helperFunctions.isExecMode()) title = 'Exec';
    if (helperFunctions.isManagementMode()) title = 'Management';
    if (helperFunctions.isAnytimeMode()) title = 'Anytime Approver';
    return `${title} - Sign Off`;
  }, [helperFunctions]);

  useEffect(() => {
    let navigationFilter = null;
    const load = async () => {
      helperFunctions.getAvailableCycles();
      await helperFunctions.loadNominations(navigationFilter);
    };

    if (loadListRequest === requestStatus.REQUEST_NOT_INITIATED) {
      if (
        (navCycleId !== undefined ||
          navNominationStatus !== undefined ||
          awardProcessTypeId !== undefined ||
          navPostId !== undefined) &&
        formStore.currentCycleId === 0 &&
        formStore.currentPostId === 0
      ) {
        const isAnytimeMode = awardProcessTypeId !== undefined && Number(awardProcessTypeId) === processType.ANYTIME;
        navigationFilter = {
          cycleId: isAnytimeMode ? 0 : Number(navCycleId),
          postId: Number(navPostId ?? 0),
          statusList: [Number(navNominationStatus)],
        };

        helperFunctions.setNavigationFilter(navigationFilter);
      }

      load(navigationFilter);
    }
  }, [
    loadListRequest,
    helperFunctions,
    formStore.currentNominationId,
    awardProcessTypeId,
    navCycleId,
    navPostId,
    navNominationStatus,
    formStore.currentCycleId,
    formStore.currentPostId,
  ]);

  const onClickCheck = (event) => {
    const nominationId = event.value;
    event.originalEvent.stopPropagation();

    helperFunctions.onClickCheck(nominationId);
    return false;
  };

  const onClickSignOff = async () => {
    if (helperFunctions.getSelected().length === 0) {
      showWarn('You should select at least one nomination.');
      return;
    }

    confirmDialog({
      message: 'Are you sure you want to sign off the selected nominations?',
      header: 'Sign Off Nomination',
      icon: 'pi pi-info-circle',
      acceptClassName: 'ui-button-success',
      accept: async () => {
        if (await helperFunctions.signOffSelected()) {
          showSuccess('The nominations were successfully signed off.');
        }
      },
    });
  };

  const onClickApplyChanges = async (nomination, nominationActionType) => {
    let response = false;
    let successMessageTerm = 'applied';

    confirmDialog({
      message: 'Are you sure you want to apply Changes?',
      header: 'Apply Nomination Changes',
      icon: 'pi pi-check-circle',
      acceptClassName: 'p-button-success',
      accept: async () => {
        if (nominationActionType === nominationAction.CHANGE) {
          response = await helperFunctions.overrideNomination(nomination, nominationAction.CHANGE);
          successMessageTerm = 'saved';
        } else if (nominationActionType === nominationAction.FORWARD) {
          response = await helperFunctions.overrideNomination(nomination, nominationAction.FORWARD);
          successMessageTerm = 'saved and signed Off';
        } else {
          response = await helperFunctions.saveFiscalStrips(nomination);
        }
        if (response) {
          if (nominationActionType === nominationAction.NONE) {
            helperFunctions.disableSaveButton(nomination.id);
          }

          showSuccess(`The nomination changes were successfully ${successMessageTerm}.`);
        }
      },
    });
  };

  const onClickEndorse = async (nomination, action) => {
    let successMessage = '';
    if ([nominationAction.REJECT, nominationAction.RETURN].includes(action) && !nomination.comment.trim()) {
      showWarn('You must type a comment for this action.');
      return;
    }

    if (action === nominationAction.REJECT) {
      confirmDialog({
        message: 'Are you sure you want to reject this nomination?',
        header: 'Reject Nomination',
        icon: 'pi pi-times-circle"',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          if (await helperFunctions.endorseNomination(nomination, action)) {
            showSuccess('The nomination was successfully rejected.');
          }
        },
      });
      return;
    }

    if (action === nominationAction.CHANGE) {
      confirmDialog({
        message: 'Are you sure you want to apply Changes?',
        header: 'Apply Nomination Changes',
        icon: 'pi pi-check-circle',
        acceptClassName: 'p-button-success',
        accept: async () => {
          if (await helperFunctions.overrideNomination(nomination)) {
            showSuccess('The nomination changes were successfully applied.');
          }
        },
      });
      return;
    }

    if (action === nominationAction.OTHER) {
      dispatch({ type: 'OPEN_EXEC_SIGNOFF_MODAL', data: nomination.id });
      return;
    }

    if (action === nominationAction.RETURN) {
      successMessage = `The nomination was returned to ${
        helperFunctions.isFinanceMode() ? 'Financial Analyst to edit Fiscal Strip' : 'HR for review.'
      } `;
    }

    if (action === nominationAction.FORWARD) {
      successMessage = 'The nomination was successfully signed off.';
    }

    if (action === nominationAction.NONE) {
      successMessage = 'The nomination changes were successully saved.';
    }

    if (await helperFunctions.endorseNomination(nomination, action)) {
      showSuccess(successMessage);
    }
  };

  const onSaveNominationChanges = (updatedNominations) => {
    if (typeof updatedNominations === 'object' && !Array.isArray(updatedNominations)) {
      dispatch({ type: 'UPDATE_NOMINATION_CHANGES', data: updatedNominations });
    } else {
      dispatch({ type: 'SET_NOMINATION_CHANGE_FOR_MULTIPLE_NOMINATIONS', data: updatedNominations });
    }
    dispatch({ type: 'CLOSE_EXEC_SIGNOFF_MODAL' });
    dispatch({ type: 'SET_MESSAGE_MODAL_OPEN' });
  };

  const onCloseMessageModalClick = () => {
    dispatch({ type: 'SET_MESSAGE_MODAL_CLOSE' });
  };

  const onClickChangeSelected = () => {
    helperFunctions.onChangeModalClick(helperFunctions.getSelected()[0]?.id);
  };

  const changeAndSignOffSelected = async (action) => {
    if (await helperFunctions.changeAndSignOffSelected(action)) {
      showSuccess(
        `The selected nomination(s) were changed ${
          action === nominationAction.FORWARD ? ',overridden and signedOff ' : ''
        }successfully.`
      );
    }
  };

  return (
    <>
      <h4>{getTitle()}</h4>

      <SignOffFormHeader formStore={formStore} helperFunctions={helperFunctions} />

      <SignOffToolbar
        formStore={formStore}
        helperFunctions={helperFunctions}
        onClickSelect={helperFunctions.onClickSelectAll}
        onClickSignOff={onClickSignOff}
        onClickTypeFilter={helperFunctions.onClickTypeFilter}
        onClickChangeSelected={onClickChangeSelected}
        changeAndSignOffSelected={changeAndSignOffSelected}
      />

      <SignOffNominationsList
        formStore={formStore}
        helperFunctions={helperFunctions}
        onClickCheck={(e) => onClickCheck(e)}
        onClickEndorse={onClickEndorse}
        onClickApplyChanges={onClickApplyChanges}
      />

      <VotingNominationDetailsModal
        isModalOpened={formStore.detailModalOpened}
        nomination={formStore.detailedNomination}
        onClickClose={() => dispatch({ type: 'CLOSE_NOMINATION_DETAILS_MODAL' })}
      />

      {formStore.execSignOffModalOpened && (
        <ExecSignOffModal
          isModalOpened={formStore.execSignOffModalOpened}
          onClickClose={() => dispatch({ type: 'CLOSE_EXEC_SIGNOFF_MODAL' })}
          formStore={formStore}
          currentNomination={formStore.filteredNominations.find(
            (nomination) => nomination.id === formStore.currentNominationId
          )}
          onUpdateChanges={onSaveNominationChanges}
          selectedNominations={helperFunctions.getSelected()}
        />
      )}

      {formStore.isMessageModalOpen && (
        <MessageModal
          isModalOpened={formStore.isMessageModalOpen}
          onClickCloseModal={onCloseMessageModalClick}
          header="Changes Confirmation"
          message="Please click the `Save` or `Save & SignOff` button to apply changes."
          showCancel={false}
        />
      )}

      <div className="debug mt-3">
        debug Info =&gt; form Mode:
        {mode}
      </div>
    </>
  );
}

export default SignOffForm;
