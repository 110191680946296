const roleType = {
  None: 0,
  USDHSupervisor: 30,
  LocalSupervisor: 40,
  LocalAwardsCoordinator: 50,
  USDHAwardsCoordinator: 60,
  JCACChair: 80,
  ExecOfficer: 100,
  MGTOfficer: 120,
  FinancialAnalyst: 140,
  FinancialOfficer: 150,
  AnytimeApprover: 500,
};

export default roleType;
