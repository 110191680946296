import { formatDate } from 'utils/dateHelpers';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { useNominationService } from 'hooks/nomination';
import fontkit from '@pdf-lib/fontkit';
import download from 'downloadjs';

import { Document, Packer } from 'docx';

import { CertirifateCreator } from './Certificates/wordCertificateCreator';

export const useCertificate = () => {
  const nominationService = useNominationService();

  const generateCertificate = async (selectedNiominations, missionId, sealUrl, signatureUrl, signer) => {
    const getCurrentDoc = async (formStore) => {
      const scriptFonturl = '/Certificates/ITCEDSCR.TTF';

      let formUrl = null;
      const columns = [];
      let sealImage = null;
      let signatureImage = null;
      let typeOfSealImage = 'png';
      let typeOfSignatureImage = 'png';

      if (sealUrl) {
        typeOfSealImage = sealUrl.substring('data:image/'.length, sealUrl.indexOf(';base64'));
      } else {
        typeOfSealImage = null;
      }

      if (signatureUrl) {
        typeOfSignatureImage = signatureUrl.substring('data:image/'.length, signatureUrl.indexOf(';base64'));
      } else {
        typeOfSignatureImage = null;
      }

      const sealImageBytes = await fetch(sealUrl).then((res) => res.arrayBuffer());
      const signatureImageBytes = await fetch(signatureUrl).then((res) => res.arrayBuffer());

      const nomineesList = formStore.nominationEmployees.map((a) =>
        a.name
          .toLowerCase()
          .replace(/\b\w/g, (l) => {
            return l.toUpperCase();
          })
          .replaceAll('Nmn', '')
      );
      const numberOfNominees = formStore.nominationEmployees.length;

      switch (true) {
        case numberOfNominees === 1:
          formUrl = '/Certificates/Empty-certificate-Landscape-test.pdf';
          break;
        case numberOfNominees > 1 && numberOfNominees <= 10:
          formUrl = '/Certificates/Empty-certificate-1column-10.pdf';
          columns[0] = nomineesList.slice(0, 20);
          break;
        case numberOfNominees > 10 && numberOfNominees <= 20:
          formUrl = '/Certificates/Empty-certificate-2columns-10.pdf';
          columns[0] = nomineesList.slice(0, 10);
          columns[1] = nomineesList.slice(10, 20);
          break;
        case numberOfNominees > 20 && numberOfNominees <= 40:
          formUrl = '/Certificates/Empty-certificate-2columns.pdf';
          columns[0] = nomineesList.slice(0, 20);
          columns[1] = nomineesList.slice(20, 40);
          break;
        case numberOfNominees > 40 && numberOfNominees <= 60:
          formUrl = '/Certificates/Empty-certificate-3columns.pdf';
          columns[0] = nomineesList.slice(0, 20);
          columns[1] = nomineesList.slice(20, 40);
          columns[2] = nomineesList.slice(40, 60);
          break;
        default:
          break;
      }

      const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
      const currentPdfDoc = await PDFDocument.load(formPdfBytes);

      // Embed Fonts
      currentPdfDoc.registerFontkit(fontkit);
      const fontBytes = await fetch(scriptFonturl).then((res) => res.arrayBuffer());
      const customScriptFont = await currentPdfDoc.embedFont(fontBytes);
      const customTimesFont = await currentPdfDoc.embedFont(StandardFonts.TimesRoman);

      if (typeOfSealImage === null) {
        sealImage = null;
      } else if (typeOfSealImage === 'png') {
        sealImage = await currentPdfDoc.embedPng(sealImageBytes);
      } else if (typeOfSealImage === 'jpeg') {
        sealImage = await currentPdfDoc.embedJpg(sealImageBytes);
      }

      if (typeOfSignatureImage === null) {
        signatureImage = null;
      } else if (typeOfSignatureImage === 'png') {
        signatureImage = await currentPdfDoc.embedPng(signatureImageBytes);
      } else if (typeOfSignatureImage === 'jpeg') {
        signatureImage = await currentPdfDoc.embedJpg(signatureImageBytes);
      }

      const form = currentPdfDoc.getForm();

      if (sealUrl) {
        const sealImageField = form.getButton('missionseal');
        sealImageField.setImage(sealImage);
      }

      if (signatureUrl) {
        const sealImageField = form.getButton('signature');
        sealImageField.setImage(signatureImage);
      }

      const mission = form.getTextField('mission');
      mission.setText(`Mission ${formStore.missionName}`);
      const awardType = form.getTextField('award_type');
      awardType.setText(formStore.awardTypeName);
      awardType.defaultUpdateAppearances(customTimesFont);
      const citation = form.getTextField('citation');
      citation.setText(formStore.citation);
      const date = form.getTextField('date');
      date.setText(formatDate(formStore.submissionDate));
      const signerField = form.getTextField('signer');
      signerField.setText(signer.length < 1 ? formStore.execOfficerName ?? '' : signer);

      if (columns.length === 0) {
        const nominees = form.getTextField('nominees');
        nominees.setText(nomineesList[0]);
        nominees.defaultUpdateAppearances(customScriptFont);
      } else if (columns.length === 1) {
        const nominees1 = form.getTextField('nominees');
        nominees1.setText(columns[0].join('\n'));
        nominees1.defaultUpdateAppearances(customScriptFont);
      } else if (columns.length === 2) {
        const nominees1 = form.getTextField('nominees1');
        const nominees2 = form.getTextField('nominees2');
        nominees1.setText(columns[0].join('\n'));
        nominees2.setText(columns[1].join('\n'));
        nominees1.defaultUpdateAppearances(customScriptFont);
        nominees2.defaultUpdateAppearances(customScriptFont);
      } else if (columns.length === 3) {
        const nominees1 = form.getTextField('nominees1');
        const nominees2 = form.getTextField('nominees2');
        const nominees3 = form.getTextField('nominees3');
        nominees1.setText(columns[0].join('\n'));
        nominees2.setText(columns[1].join('\n'));
        nominees3.setText(columns[2].join('\n'));
        nominees1.defaultUpdateAppearances(customScriptFont);
        nominees2.defaultUpdateAppearances(customScriptFont);
        nominees3.defaultUpdateAppearances(customScriptFont);
      }

      form.getFields().forEach((formField) => formField.enableReadOnly());

      return currentPdfDoc;
    };

    const certificateData = await nominationService.getCertificateData(selectedNiominations);

    const mergePDFPages = async (pages) => {
      const pdfDoc = await PDFDocument.create();
      await Promise.all(
        pages.map(async (page) => {
          const convertedPdfDoc = await PDFDocument.load(page);
          const [convertedPage] = await pdfDoc.copyPages(convertedPdfDoc, [0]);
          pdfDoc.addPage(convertedPage);
        })
      );
      const pdfBytes = await pdfDoc.save();
      const docUrl = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
      console.log('certificate url: ', docUrl);
      const newWindow = window.open(docUrl, '_blank');
      // delay time for generate pdf
      setTimeout(() => {
        newWindow.document.title = 'certificates';
      }, 10000);
    };

    const pages = [];

    if (certificateData && certificateData.ok) {
      await certificateData.data.reduce(
        (promiseChain, nomination) =>
          promiseChain.then(async () => {
            const currentPDF = await getCurrentDoc(nomination);
            const currentPDFBytes = await currentPDF.save();
            pages.push(currentPDFBytes);
          }),
        Promise.resolve()
      );
    }

    await mergePDFPages(pages);
  };

  const generateWordCertificate = async (selectedNiominations, missionId, sealUrl, signatureUrl, signer) => {
    const createNewSection = (currentNomination) => {
      return CertirifateCreator(currentNomination, missionId, sealUrl, signatureUrl, signer);
    };

    const certificateData = await nominationService.getCertificateData(selectedNiominations);

    const sections = [];
    if (certificateData && certificateData.ok) {
      await certificateData.data.reduce(
        (promiseChain, nomination) =>
          promiseChain.then(async () => {
            sections.push(createNewSection(nomination));
          }),
        Promise.resolve()
      );
    }
    const doc = new Document({ sections });

    Packer.toBlob(doc).then((blob) => {
      download(blob, 'Certificate.docx');
    });
  };
  return {
    generateCertificate,
    generateWordCertificate,
  };
};

export default useCertificate;
