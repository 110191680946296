import useApi from '../useApi';

export const useBureauService = () => {
  const api = useApi();

  const getBureaus = async () => {
    return api.get('/Bureau/GetList');
  };

  // const getBureauMissions = async (missionId) => {
  //   return api.get(`/Bureau/GetBureauMissions/${missionId}`);
  // };

  const updateBureau = async (data) => {
    return api.put('/Bureau/Update', data);
  };

  const getRoleEmployees = async (bureauId) => {
    return api.get(`/Bureau/GetRoleEmployees/${bureauId}`);
  };

  const saveRoleEmployees = async (bureauId, data) => {
    return api.post(`/Bureau/SaveBureauEmployees/${bureauId}`, data);
  };

  return {
    getBureaus,
    updateBureau,
    getRoleEmployees,
    saveRoleEmployees,
  };
};

export default useBureauService;
