import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputTextarea } from 'primereact/inputtextarea';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { requestStatus } from 'constants/index';
import { useMissionProfileService } from 'hooks/admin';

function CertificatePrintModal({
  isModalOpened,
  onClickCloseModal,
  nominationList,
  generateCertificates,
  generateCertificatesRequest,
  missionId,
}) {
  const missionProfileService = useMissionProfileService();

  const [printSeal, setPrintSeal] = useState(true);
  const [printSignature, setprintSignature] = useState(true);
  const [sealUrl, setsealUrl] = useState(null);
  const [signatureUrl, setsignatureUrl] = useState(null);
  const [signer, setSigner] = useState('');

  const generate = async (format) => {
    const sealImage = printSeal ? sealUrl : null;
    const signatureImage = printSignature ? signatureUrl : null;

    await generateCertificates(sealImage, signatureImage, signer, format);

    onClickCloseModal();
  };

  const cancel = () => {
    onClickCloseModal();
  };

  useMemo(async () => {
    const response = await missionProfileService.loadMissionProfile(missionId);
    if (response && response.ok) {
      setsealUrl(response.data.missionSealImage);
      setsignatureUrl(response.data.missionCertificateSignatureImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() =>  {
  //   const loadFromMissionProfile = async () => {
  //     const response = await missionProfileService.loadMissionProfile(missionId);
  //     if (response && response.ok) {
  //       setsealUrl(response.data.missionSealImage);
  //       setsignatureUrl(response.data.missionCertificateSignatureImage);
  //     }
  //   }
  //   loadFromMissionProfile()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Modal centered scrollable className="mh-80" isOpen={isModalOpened} toggle={onClickCloseModal} size="lg">
      <ModalHeader toggle={onClickCloseModal}>
        <p>
          <strong>({nominationList.length})</strong> Certificates are ready to be printed
        </p>
      </ModalHeader>

      <ModalBody>
        <div className="row">
          <div className="col-6 text-center">
            <h6>Include Seal?</h6>
            {sealUrl ? (
              <>
                <InputSwitch checked={printSeal} onChange={(e) => setPrintSeal(e.target.value)} />

                <div style={{ filter: !printSeal ? 'blur(15px)' : '' }}>
                  {sealUrl && <Image src={sealUrl} alt="Image" width="200" />}
                </div>
              </>
            ) : (
              <p className="font-italic">Mission Seal is not available. Image can be uploaded in Mission Profile</p>
            )}
          </div>
          <div className="col-6 text-center">
            <h6>Signature Image</h6>
            {signatureUrl ? (
              <>
                <InputSwitch checked={printSignature} onChange={(e) => setprintSignature(e.target.value)} />
                <div style={{ filter: !printSignature ? 'blur(9px)' : '' }}>
                  {signatureUrl && <Image src={signatureUrl} alt="Image" width="200" />}
                </div>{' '}
              </>
            ) : (
              <p className="font-italic">Signature is not available. Image can be uploaded in Mission Profile</p>
            )}
          </div>
        </div>
        <Divider />
        <h6>Signer</h6>
        <InputTextarea
          value={signer}
          className="form-control"
          autoResize
          rows={1}
          placeholder="Name and Title of the signer"
          onChange={(evt) => setSigner(evt.target.value)}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          label="Generate PDF"
          disabled={generateCertificatesRequest === requestStatus.REQUEST_IN_PROGRESS}
          onClick={() => generate('PDF')}
          icon={
            generateCertificatesRequest === requestStatus.REQUEST_IN_PROGRESS ? (
              <ProgressSpinner
                className="mr-2"
                style={{ width: '20px', height: '20px', color: 'white' }}
                strokeWidth="8"
              />
            ) : (
              'pi pi-print'
            )
          }
        />
        <Button
          label="Generate Word Document"
          disabled={generateCertificatesRequest === requestStatus.REQUEST_IN_PROGRESS}
          onClick={() => generate('WORD')}
          icon={
            generateCertificatesRequest === requestStatus.REQUEST_IN_PROGRESS ? (
              <ProgressSpinner
                className="mr-2"
                style={{ width: '20px', height: '20px', color: 'white' }}
                strokeWidth="8"
              />
            ) : (
              'pi pi-print'
            )
          }
        />
        <Button
          label="Cancel"
          icon="pi pi-times-circle"
          onClick={() => cancel()}
          className="p-button-secondary w-100px"
        />
      </ModalFooter>
    </Modal>
  );
}
CertificatePrintModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  onClickCloseModal: PropTypes.func.isRequired,
  nominationList: PropTypes.array.isRequired,
  generateCertificates: PropTypes.func.isRequired,
  generateCertificatesRequest: PropTypes.number.isRequired,
  missionId: PropTypes.number.isRequired,
};

CertificatePrintModal.defaultProps = {};

export default CertificatePrintModal;
