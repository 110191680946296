import React from 'react';
import PropTypes from 'prop-types';

function FormBlock({ Rows }) {
  function renderLine(line) {
    return line ? <div className="py-1 mb-0">{line}</div> : <div />;
  }

  return (
    <div className="w-100">
      {Rows.map((item, idx) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx}>
            {renderLine(item.line1)}
            {renderLine(item.line2)}
            {renderLine(item.line3)}
            {renderLine(item.line4)}
          </div>
        );
      })}
    </div>
  );
}

FormBlock.propTypes = {
  Rows: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default React.memo(FormBlock);
