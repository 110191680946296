const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const CALLBACK_PATH = '/login/callback';
const HOST = window.location.host;
const REDIRECT_URI = `https://${HOST}${CALLBACK_PATH}`;
const LOGOUT_REDIRECT_URI = `https://${HOST}/Logout`;
const SCOPES = 'openid profile email';
const ISSUER = process.env.REACT_APP_ISSUER || `https://${OKTA_DOMAIN}/oauth2/default`;

const oktaConfig = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  postLogoutRedirectUri: LOGOUT_REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
  pkce: true,
};

export default oktaConfig;
