/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { formatDate } from 'utils/dateHelpers';

import {
  AlignmentType,
  Paragraph,
  ImageRun,
  RelativeHorizontalPosition,
  RelativeVerticalPosition,
  Table,
  TableAnchorType,
  TableCell,
  TableLayoutType,
  TableRow,
  WidthType,
  BorderStyle,
  OverlapType,
} from 'docx';
import LandscapeCertificate from './LandscapeCertificate';
import PortraitCertificate from './PortraitCertificate';

// let signatureImageDimension = {};

// async function calculateAspectRatioFit(maxWidth, maxHeight, image) {
//   try {
//     const dimensions = await getImageSize(image);

//     const ratio = Math.min(maxWidth / dimensions.width, maxHeight / dimensions.height);
//     const signatureImageDimension = { width: dimensions.width * ratio, height: dimensions.height * ratio };

//     return signatureImageDimension;
//   } catch (error) {
//     console.error(error);
//   }

//   return null;
// }

export function CertirifateCreator(nomination, missionId, sealUrl, signatureUrl, signer) {
  const columns = [];
  const numberOfNominees = nomination.nominationEmployees.length;
  const nomineesList = nomination.nominationEmployees.map((a) =>
    a.name
      .toLowerCase()
      .replace(/\b\w/g, (l) => {
        return l.toUpperCase();
      })
      .replaceAll('Nmn', '')
  );
  const mission = `Mission ${nomination.missionName}`;
  const awardType = nomination.awardTypeName;
  // eslint-disable-next-line prefer-destructuring
  const citation = nomination.citation;
  const date = formatDate(nomination.submissionDate);
  const signerField = signer.length < 1 ? nomination.execOfficerName ?? '' : signer;
  const borders = {
    top: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
    bottom: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
    left: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
    right: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
  };
  const footertable = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders,
            children: [
              new Paragraph({
                spacing: { line: 70, before: 620, after: 1 },
                alignment: AlignmentType.LEFT,
                text: date,
              }),
            ],
          }),

          new TableCell({
            borders,
            children: [
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  signatureUrl
                    ? new ImageRun({
                        data: signatureUrl,
                        transformation: {
                          width: 110,
                          height: 40,
                        },
                      })
                    : [],
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        borders,
        children: [
          new TableCell({
            borders,
            children: [
              new Paragraph({
                spacing: { line: 220, before: 170, after: 1 },
                alignment: AlignmentType.LEFT,
                text: '____________________________',
              }),
            ],
          }),
          new TableCell({
            borders,
            children: [
              new Paragraph({
                spacing: { line: 220, before: 60, after: 1 },
                alignment: AlignmentType.RIGHT,
                text: '____________________________',
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        borders,
        children: [
          new TableCell({
            borders,
            children: [
              new Paragraph({
                spacing: { line: 220, before: 90, after: 1 },
                alignment: AlignmentType.LEFT,
                text: 'Date',
              }),
            ],
          }),
          new TableCell({
            borders,
            children: [
              new Paragraph({
                spacing: { line: 220, before: 90, after: 1 },
                alignment: AlignmentType.RIGHT,
                text: signerField,
              }),
            ],
          }),
        ],
      }),
    ],
    float: {
      horizontalAnchor: TableAnchorType.PAGE,
      verticalAnchor: TableAnchorType.MARGIN,
      relativeHorizontalPosition: RelativeHorizontalPosition.CENTER,
      relativeVerticalPosition: RelativeVerticalPosition.BOTTOM,
      overlap: OverlapType.NEVER,
      leftFromText: 1000,
      rightFromText: 2000,
      // topFromText: 1500,
      // bottomFromText: 30,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
  });

  switch (true) {
    case numberOfNominees === 1:
      return LandscapeCertificate({ mission, awardType, nomineesList, citation, footertable, sealUrl, columns });

    case numberOfNominees > 1 && numberOfNominees <= 10:
      columns[0] = nomineesList.slice(0, 20);
      return PortraitCertificate({ mission, awardType, nomineesList, citation, footertable, sealUrl, columns });

    case numberOfNominees > 10 && numberOfNominees <= 20:
      columns[0] = nomineesList.slice(0, 10);
      columns[1] = nomineesList.slice(10, 20);
      return PortraitCertificate({ mission, awardType, nomineesList, citation, footertable, sealUrl, columns });

    case numberOfNominees > 20 && numberOfNominees <= 40:
      columns[0] = nomineesList.slice(0, 20);
      columns[1] = nomineesList.slice(20, 40);
      return PortraitCertificate({ mission, awardType, nomineesList, citation, footertable, sealUrl, columns });

    case numberOfNominees > 40 && numberOfNominees <= 60:
      columns[0] = nomineesList.slice(0, 20);
      columns[1] = nomineesList.slice(20, 40);
      columns[2] = nomineesList.slice(40, 60);
      return LandscapeCertificate({ mission, awardType, nomineesList, citation, footertable, sealUrl, columns });

    default:
      break;
  }

  return null;
}

export default CertirifateCreator;
