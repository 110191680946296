import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'primereact/button';

function YesNoModal({ isModalOpened, header, message, onClickYes, onClickNo }) {
  return (
    <Modal centered scrollable className="mh-100" isOpen={isModalOpened} toggle={onClickNo} size="md">
      <ModalHeader toggle={onClickNo}>{header}</ModalHeader>
      <ModalBody>{message}</ModalBody>

      <ModalFooter>
        <Button
          label="Ok"
          icon="pi pi-check-circle"
          onClick={() => onClickYes()}
          className="p-button-primary w-100px"
        />

        <Button
          label="Cancel"
          icon="pi pi-cross-circle"
          onClick={() => onClickNo()}
          className="p-button-secondary w-100px"
        />
      </ModalFooter>
    </Modal>
  );
}

YesNoModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClickYes: PropTypes.func.isRequired,
  onClickNo: PropTypes.func.isRequired,
};

export default YesNoModal;
