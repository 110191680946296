import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'primereact/calendar';
import { Row, Col, FormGroup, Label } from 'reactstrap';

function PeriodOfPerformance({ nomination, helperFunctions, readOnly }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="mt-0">Period of Performance</h6>
        <div className="flex justify-content-center">
          <Row>
            <Col md="2">
              <FormGroup>
                <Label for="periodOfPerformanceStartDate" className="text-nowrap">
                  Start Date
                </Label>
                <Calendar
                  value={
                    nomination &&
                    nomination.periodOfPerformanceStartDate &&
                    new Date(nomination.periodOfPerformanceStartDate)
                  }
                  className="input-group mb-3"
                  Label="Start Date"
                  showIcon
                  onChange={(e) => helperFunctions.onChangePeriodOfPerformance(e.value, 'start')}
                  readOnlyInput
                  disabled={readOnly}
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label for="periodOfPerformanceEndDate" className="text-nowrap">
                  End Date
                </Label>
                <Calendar
                  value={
                    nomination &&
                    nomination.periodOfPerformanceEndDate &&
                    new Date(nomination.periodOfPerformanceEndDate)
                  }
                  Label="End Date"
                  className="input-group mb-3"
                  onChange={(e) => helperFunctions.onChangePeriodOfPerformance(e.value, 'end')}
                  showIcon
                  readOnlyInput
                  disabled={readOnly}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

PeriodOfPerformance.propTypes = {
  nomination: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object,
  readOnly: PropTypes.bool,
};

PeriodOfPerformance.defaultProps = {
  readOnly: false,
  helperFunctions: null,
};

export default memo(PeriodOfPerformance);
