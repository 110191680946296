import { approvalStatus, requestStatus, nominationAction } from 'constants/index';

const initNominationForm = {
  userSessionRequest: requestStatus.REQUEST_NOT_INITIATED,
  nominationLoadRequest: requestStatus.REQUEST_NOT_INITIATED,
  employeeHistoryLoadRequest: requestStatus.REQUEST_NOT_INITIATED,
  historyLoadRequest: requestStatus.REQUEST_NOT_INITIATED,
  nominationSaveRequest: requestStatus.REQUEST_NOT_INITIATED,
  reasonsAndTypesRequest: requestStatus.REQUEST_NOT_INITIATED,
  generateCertificatesRequest: requestStatus.REQUEST_NOT_INITIATED,
  nomination: {
    isValid: false,
    id: null,
    isEditable: false,
    isJustificationCitationEditable: false,
    isGroup: false,
    nominationStatus: 1,
    nominationStatusName: 'Draft',
    nominatorUserId: null,
    nominatorName: null,
    nominatorPositionTitle: null,
    missionId: null,
    missionName: null,
    postId: null,
    postName: null,
    sectionId: null,
    bureauId: null,
    bureauCycleId: 0,
    sectionShortName: null,
    awardReasons: [],
    awardTypeId: 0,
    awardTypeName: null,
    otherReason: '',
    originalAwardReasons: [],
    originalAwardTypeId: 0,
    originalOtherReason: '',
    awardProcessType: 0,
    loadedAwardProcessType: 0,
    cycleId: 0,
    justification: '',
    citation: '',
    periodOfPerformanceStartDate: null,
    periodOfPerformanceEndDate: null,
    formOfRecognition: null,
    selectedFormOfRecognition: null,
    proposedAmount: 0,
    recommendedAmount: 0,
    approvedAmount: 0,
    fiscalStrip: '',
    nominees: [],
    notes: [],
    isAutoSave: false,
    isJustificationSaved: false,
    isJustificationDirty: false,
    isCitationSaved: false,
    isCitationDirty: false,
    returnToStatuses: [],
  },
  validationResultErrors: [],
  reasons: [],
  types: [],
  availableActions: [],
  employeeHistory: {
    employeeId: 0,
    name: '',
    currentPositionTitle: '',
    previousPositionTitle: '',
    dateOfHire: '',
    ongoingNominationCount: 0,
    awardsReceived: [],
    grade: '',
  },
  availableCycles: [],
  assignedCyclesRoles: [],
  isReasonAndTypeModalOpened: false,
  isEmployeeSearchModalOpened: false,
  isNominationHistoryModalOpened: false,
  isNominationApproverModalOpened: false,
  isPrintFormModalOpened: false,
  isErrorDetailsModalOpened: false,
  isRightSideBarVisible: false,
  isRejectedNomineesVisible: false,
  awardProcessTypeForPrintModal: null,
  nominationIdforPrintModal: null,
  isSendToOPSModalOpened: false,
  isConfirmationModalOpened: false,
  isReturnToModalOpened: false,
  isCertificatePrintModalOpened: false,
  confirmationModalTitle: '',
  confirmationCommentRequired: false,
  confirmationComment: '',
  confirmationActionButton: {
    clientId: '',
    label: 'Confirm',
    icon: 'pi pi-check',
    action: 0,
    successMessage: 'Operation successful.',
    cssClass: '',
  },
  fiscalData: null,
  nominationAction: nominationAction.FORWARD,
};

const nominationFormReducer = (state, action) => {
  let idx = -1;
  switch (action.type) {
    case 'SET_NEW_NOMINATION':
      return {
        ...initNominationForm,
        nomination: { ...initNominationForm.nomination, ...action.data, isEditable: true },
        userSessionRequest: requestStatus.REQUEST_COMPLETED,
        nominationLoadRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'FORCE_RELOAD':
      return { ...state, nominationLoadRequest: requestStatus.REQUEST_NOT_INITIATED };

    case 'START_SAVE_REQUEST':
      return { ...state, nominationSaveRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'COMPLETE_SAVE_REQUEST':
      return { ...state, nominationSaveRequest: requestStatus.REQUEST_COMPLETED };

    case 'START_REQUEST_REASONS_AND_TYPES':
      return { ...state, reasonsAndTypesRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_REASONS_AND_TYPES':
      return { ...state, ...action.data, reasonsAndTypesRequest: requestStatus.REQUEST_COMPLETED };

    case 'SET_SELECTED_REASONS':
      return { ...state, nomination: { ...state.nomination, awardReasons: action.data } };

    case 'START_CERTIFICATE_GENERATION':
      return { ...state, generateCertificatesRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'COMPLETE_CERTIFICATE_GENERATION':
      return { ...state, generateCertificatesRequest: requestStatus.REQUEST_COMPLETED };

    case 'SET_SELECTED_TYPE':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          awardTypeId: action.data.awardTypeId,
          awardTypeName: action.data.awardTypeName,
          awardProcessType: action.data.awardProcessType,
          proposedFormOfRecognition: action.data.proposedFormOfRecognition,
          formOfRecognition: action.data.formOfRecognition,
        },
      };

    case 'SET_OTHER_REASON':
      return { ...state, nomination: { ...state.nomination, otherReason: action.data } };

    case 'OPEN_REASON_AND_TYPES_MODAL':
      return {
        ...state,
        isReasonAndTypeModalOpened: true,
        nomination: {
          ...state.nomination,
          originalAwardReasons: [...state.nomination.awardReasons],
          originalOtherReason: state.nomination.otherReason,
          originalAwardTypeId: state.nomination.awardTypeId,
          originalAwardTypeName: state.nomination.awardTypeName,
        },
      };

    case 'CANCEL_REASON_AND_TYPES_MODAL':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          awardReasons: [...state.nomination.originalAwardReasons],
          otherReason: state.nomination.originalOtherReason,
          awardTypeId: state.nomination.originalAwardTypeId,
          awardTypeName: state.nomination.originalAwardTypeName,
        },
        isReasonAndTypeModalOpened: false,
      };

    case 'CLOSE_REASON_AND_TYPES_MODAL':
      return { ...state, isReasonAndTypeModalOpened: false };

    case 'OPEN_PRINT_FORM_MODAL':
      return {
        ...state,
        isPrintFormModalOpened: true,
        nominationIdforPrintModal: action.data.currentNominationId,
        awardProcessTypeForPrintModal: action.data.currentAwardProcessType,
      };

    case 'CLOSE_PRINT_FORM_MODAL':
      return { ...state, isPrintFormModalOpened: false };

    case 'OPEN_CONFIRMATION_MODAL':
      return {
        ...state,
        isConfirmationModalOpened: true,
        confirmationComment: '',
        confirmationModalTitle: action.data.title,
        confirmationCommentRequired: action.data.isCommentRequired,
        confirmationActionButton: action.data.actionButton,
      };
    case 'OPEN_RETURN_TO_MODAL':
      return {
        ...state,
        isReturnToModalOpened: true,
      };

    case 'CLOSE_RETURN_TO_MODAL':
      return {
        ...state,
        isReturnToModalOpened: false,
      };

    case 'CLOSE_CONFIRMATION_MODAL':
      return { ...state, isConfirmationModalOpened: false };

    case 'UPDATE_CONFIRMATION_COMMENT':
      return { ...state, confirmationComment: action.data };

    case 'OPEN_EMPLOYEE_SEARCH_MODAL':
      return { ...state, isEmployeeSearchModalOpened: true };

    case 'OPEN_EMPLOYEE_HISTORY_MODAL':
      return {
        ...state,
        isNominationHistoryModalOpened: true,
        employeeHistoryLoadRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'OPEN_NOMINATION_APPROVER_MODAL':
      return {
        ...state,
        isNominationApproverModalOpened: true,
      };

    case 'CLOSE_EMPLOYEE_SEARCH_MODAL':
      return { ...state, isEmployeeSearchModalOpened: false };

    case 'CLOSE_EMPLOYEE_HISTORY_MODAL':
      return { ...state, isNominationHistoryModalOpened: false };

    case 'CLOSE_NOMINATION_APPROVER_MODAL':
      return { ...state, isNominationApproverModalOpened: false };

    case 'OPEN_ERROR_DETAILS_MODAL':
      return { ...state, isErrorDetailsModalOpened: true };

    case 'CLOSE_ERROR_DETAILS_MODAL':
      return { ...state, isErrorDetailsModalOpened: false };

    case 'ADD_NOMINEES':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          nominees: [
            ...state.nomination.nominees,
            ...action.data.filter((item) => {
              const found = state.nomination.nominees.find((nominee) => nominee.employeeId === item.employeeId);
              return !found;
            }),
          ],
          isValid: undefined,
        },
        validationResultErrors: [],
      };

    case 'REMOVE_NOMINEE_IDX': // idx = action.data
      idx = state.nomination.nominees.findIndex((item) => item.employeeId === action.data.employeeId);
      return {
        ...state,
        nomination: {
          ...state.nomination,
          nominees: [...state.nomination.nominees.slice(0, idx), ...state.nomination.nominees.slice(idx + 1)],
          isValid: undefined,
        },
        validationResultErrors: [],
      };

    case 'UPDATE_GROUP_FLAG': // idx = action.data
      return {
        ...state,
        nomination: {
          ...state.nomination,
          isGroup: state.nomination.nominees.length > 1,
        },
      };

    case 'UPDATE_NOMINEE':
      idx = state.nomination.nominees.findIndex((item) => item.id === action.data.id);
      return {
        ...state,
        nomination: {
          ...state.nomination,
          nominees: [
            ...state.nomination.nominees.slice(0, idx),
            { ...action.data },
            ...state.nomination.nominees.slice(idx + 1),
          ],
          isValid: undefined,
        },
        validationResultErrors: [],
      };

    case 'TOGGLE_NOMINEE_EDIT_POSITION':
      idx = state.nomination.nominees.findIndex((item) => item.employeeId === action.data.employeeId);
      return {
        ...state,
        nomination: {
          ...state.nomination,
          nominees: [
            ...state.nomination.nominees.slice(0, idx),
            {
              ...state.nomination.nominees[idx],
              isPositionEditable: !state.nomination.nominees[idx].isPositionEditable,
            },
            ...state.nomination.nominees.slice(idx + 1),
          ],
        },
      };

    case 'UPDATE_NEW_RECORD_ID':
      return { ...state, nomination: { ...state.nomination, nominationId: action.data } };

    case 'UPDATE_PREVIOUS_POSITION':
      idx = state.nomination.nominees.findIndex((item) => item.employeeId === action.data.employeeId);
      return {
        ...state,
        nomination: {
          ...state.nomination,
          nominees: [
            ...state.nomination.nominees.slice(0, idx),
            { ...state.nomination.nominees[idx], previousPositionTitle: action.data.previousPositionTitle },
            ...state.nomination.nominees.slice(idx + 1),
          ],
        },
      };

    case 'UPDATE_CYCLE':
      return {
        ...state,
        nomination: { ...state.nomination, cycleId: action.data },
      };

    case 'UPDATE_BUREAU_CYCLE':
      return {
        ...state,
        nomination: { ...state.nomination, bureauCycleId: action.data },
      };

    case 'UPDATE_JUSTIFICATION':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          justification: action.data,
          isJustificationDirty: true,
          isValid: undefined,
        },
        validationResultErrors: [],
      };

    case 'RESET_JUSTIFICATION_FLAG':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          isJustificationDirty: false,
          isValid: undefined,
        },
        validationResultErrors: [],
      };

    case 'RESET_CITATION_FLAG':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          isCitationDirty: false,
          isValid: undefined,
        },
        validationResultErrors: [],
      };

    case 'UPDATE_JUSTIFICATION_SAVED': {
      return {
        ...state,
        nomination: {
          ...state.nomination,
          isJustificationSaved: action.data,
        },
      };
    }
    case 'UPDATE_CITATION_SAVED': {
      return {
        ...state,
        nomination: {
          ...state.nomination,
          isCitationSaved: action.data,
        },
      };
    }

    case 'UPDATE_CITATION':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          citation: action.data,
          isCitationDirty: true,
          isValid: undefined,
        },
        validationResultErrors: [],
      };

    case 'SET_AUTO_SAVE':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          isAutoSave: action.data,
        },
      };

    case 'UPDATE_FORM_OF_RECOGNITION': {
      if (action.data.mode === 'new' || action.data.mode === 'edit') {
        return {
          ...state,
          nomination: {
            ...state.nomination,
            isProposedAmountEditable: true,
            proposedFormOfRecognition: action.data.formOfRecognition,
            formOfRecognition: action.data.formOfRecognition,
            isValid: undefined,
          },
          validationResultErrors: [],
        };
      }

      if (action.data.mode === 'review') {
        return {
          ...state,
          nomination: {
            ...state.nomination,
            recommendedFormOfRecognition: action.data.formOfRecognition,
            formOfRecognition: action.data.formOfRecognition,
            isValid: undefined,
          },
          validationResultErrors: [],
        };
      }
      return {
        ...state,
        nomination: {
          ...state.nomination,
          approvedFormOfRecognition: action.data,
          formOfRecognition: action.data.formOfRecognition,
          isValid: undefined,
        },
        validationResultErrors: [],
      };
    }

    case 'UPDATE_PERIOD_OF_PERFORMANCE_START_DATE':
      return {
        ...state,
        nomination: { ...state.nomination, periodOfPerformanceStartDate: action.data, isValid: undefined },
        validationResultErrors: [],
      };

    case 'UPDATE_PERIOD_OF_PERFORMANCE_END_DATE':
      return {
        ...state,
        nomination: { ...state.nomination, periodOfPerformanceEndDate: action.data, isValid: undefined },
        validationResultErrors: [],
      };

    case 'UPDATE_PROPOSED_AMOUNT':
      return {
        ...state,
        nomination: { ...state.nomination, proposedAmount: action.data, isValid: undefined },
        validationResultErrors: [],
      };

    case 'UPDATE_RECOMMENDED_AMOUNT':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          recommendedAmount: action.data,
          isValid: undefined,
        },
        validationResultErrors: [],
      };

    case 'UPDATE_APPROVED_AMOUNT':
      return {
        ...state,
        nomination: { ...state.nomination, approvedAmount: action.data, isValid: undefined },
        validationResultErrors: [],
      };

    case 'UPDATE_FISCALSTRIP':
      return {
        ...state,
        nomination: { ...state.nomination, fiscalStrip: action.data, isValid: undefined },
        validationResultErrors: [],
      };

    case 'UPDATE_INDIVIDUAL_FISCAL_STRIP':
      idx = state.nomination.nominees.findIndex((item) => item.employeeId === action.data.nomineeEmployeeId);
      return {
        ...state,
        isFiscalStripModalOpen: false,
        fiscalStripUpdated: true,
        nomination: {
          ...state.nomination,
          nominees: [
            ...state.nomination.nominees.slice(0, idx),
            {
              ...state.nomination.nominees[idx],
              fiscalStrip: action.data.fiscalStrip,
            },
            ...state.nomination.nominees.slice(idx + 1),
          ],
        },
      };

    case 'UPDATE_FISCAL_STRIP_TO_ALL_NOMINEES':
      return {
        ...state,
        nomination: {
          ...state.nomination,
          nominees: [
            ...state.nomination.nominees.map((x) => {
              return x.approvalStatus === approvalStatus.DISAPPROVED
                ? x
                : {
                    ...x,
                    fiscalStrip: action.data.value,
                  };
            }),
          ],
        },
      };

    case 'OPEN_FISCAL_STRIP_MODAL':
      return {
        ...state,
        isFiscalStripModalOpen: true,
        fiscalData: {
          type: 'nominee',
          nomineeEmployeeId: action.data.nomineeEmployeeId,
          fiscalStrip: action.data.nomineeFiscalStrip,
        },
      };

    case 'CLOSE_FISCAL_STRIP_MODAL':
      return {
        ...state,
        isFiscalStripModalOpen: false,
      };

    // end for fiscal strip

    case 'UPDATE_VALIDATION_RESULT':
      return {
        ...state,
        nomination: { ...state.nomination, isValid: false },
        validationResultErrors: action.data,
      };

    case 'CLEAR_VALIDATION_RESULT':
      return { ...state, nomination: { ...state.nomination, isValid: undefined }, validationResultErrors: [] };

    case 'RESET':
      return { ...initNominationForm };

    case 'START_LOAD_NOMINATION':
      return { ...state, nominationLoadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'START_LOAD_HISTORY':
      return { ...state, historyLoadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_NOMINATION':
      return { ...state, nomination: action.data, nominationLoadRequest: requestStatus.REQUEST_COMPLETED };

    case 'SET_HISTORY_AND_COMMENTS':
      return {
        ...state,
        nomination: { ...state.nomination, history: action.data },
        historyLoadRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'SET_AVAILABLE_ACTIONS_AND_CYCLES':
      return { ...state, ...action.data };

    case 'TOGGLE_RIGHT_SIDEBAR':
      return { ...state, isRightSideBarVisible: !state.isRightSideBarVisible };

    case 'TOGGLE_REJECTED_NOMINEES_VISIBILITY':
      return { ...state, isRejectedNomineesVisible: !state.isRejectedNomineesVisible };

    case 'ADD_NOTE':
      return {
        ...state,
        nomination: { ...state.nomination, notes: [...state.nomination.notes, ...action.data], isValid: undefined },
        historyLoadRequest: requestStatus.REQUEST_NOT_INITIATED,
        validationResultErrors: [],
      };

    case 'REMOVE_NOTE_IDX': // idx = action.data
      return {
        ...state,
        nomination: {
          ...state.nomination,
          notes: [...state.nomination.notes.slice(0, action.data), ...state.nomination.notes.slice(action.data + 1)],
        },
      };

    case 'OPEN_SEND_TO_OPS_MODAL':
      return { ...state, isSendToOPSModalOpened: true, nominationAction: nominationAction.FORWARD };

    case 'CLOSE_SEND_TO_OPS_MODAL':
      return { ...state, isSendToOPSModalOpened: false };

    case 'CLOSE_CERTIFICATE_PRINT_MODAL':
      return { ...state, isCertificatePrintModalOpened: false };

    case 'OPEN_CERTIFICATE_PRINT_MODAL':
      return { ...state, isCertificatePrintModalOpened: true };

    case 'UPDATE_EMPLOYEE_HISTORY':
      return {
        ...state,
        employeeHistory: action.data,
        employeeHistoryLoadRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'SET_MESSAGE_MODAL_OPEN':
      return { ...state, isMessageModalOpen: true };

    case 'SET_MESSAGE_MODAL_CLOSE':
      return { ...state, isMessageModalOpen: false };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { nominationFormReducer, initNominationForm };
