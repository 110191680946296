import React, { useState, useContext, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { SessionContext } from 'contexts';
import { useEmployeeService } from 'hooks/admin';
import { useNavigationLinks } from 'hooks';

function SignIntoMission() {
  const { sessionStore, sessionHelpers } = useContext(SessionContext);
  const employeeService = useEmployeeService();
  const { goTo } = useNavigationLinks();
  const [loading, setLoading] = useState(false);
  const [missions, setMissions] = useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState();
  const tempMissionName = missions?.find((x) => x.organizationId === sessionStore?.temporaryOrgId)?.name;

  useEffect(() => {
    async function loadMissionParameter() {
      const response = await employeeService.getMissions();
      if (response && response.ok) {
        setMissions(response.data);
        setLoading(true);
      }
    }

    if (!loading) {
      loadMissionParameter();
    }
  }, [employeeService, loading, setLoading, setMissions]);

  const setSignIntoMission = async () => {
    sessionHelpers.startSignIntoMission(selectedOrganizationId);
    goTo('home');
  };

  const stopSignIntoMission = async () => {
    sessionHelpers.terminateSignIntoMission();
    goTo('home');
  };

  return (
    <>
      <h5>Mission</h5>
      <div className="row">
        <div className="col-12">Select a mission</div>
        <div className="col-3">
          <Dropdown
            value={selectedOrganizationId}
            options={missions}
            optionLabel="name"
            optionValue="organizationId"
            filterBy="name"
            filter
            className="w-100"
            onChange={(e) => {
              setSelectedOrganizationId(e.value);
            }}
          />
        </div>
      </div>
      <div className="row my-2">
        <div className="col-12">You are impersonating as HR Officer for the selected mission</div>
      </div>
      <div className="row">
        <div className="row mt-3 mx-1">
          <div className="col-12">
            {selectedOrganizationId && (
              <Button
                label="Sign Into Mission"
                icon="pi pi-sign-in"
                className="p-button-primary"
                onClick={() => setSignIntoMission(true)}
              />
            )}
          </div>
          {tempMissionName && (
            <div className="row mt-3 mx-1">
              <div className="col-12">
                <Button
                  label={`Log Out from Mission - ${tempMissionName}`}
                  icon="pi pi-sign-out"
                  className="p-button-secondary"
                  onClick={() => stopSignIntoMission()}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(SignIntoMission);
