import { requestStatus, processType } from 'constants/index';

const initAwardTypesForm = {
  loadedAwardTypes: [],
  loadedCycles: [],
  editAwardType: null,
  currentCycleId: null,
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
  isModalOpened: false,
  typeFilter: processType.ANYTIME,
};

const awardTypeFormReducer = (state, action) => {
  let idx = -1;
  let nextLoadRequest = state.loadRequest;
  let nextCurrentCycleId = -1;
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'OPEN_EDIT_MODAL':
      return { ...state, isModalOpened: true, editAwardType: action.data };

    case 'CLOSE_EDIT_MODAL':
      return { ...state, isModalOpened: false };

    case 'UPDATE_AWARD_TYPE':
      idx = state.loadedAwardTypes.findIndex((item) => item.id === state.editAwardType.id);
      return {
        ...state,
        loadedAwardTypes: [
          ...state.loadedAwardTypes.slice(0, idx),
          { ...state.editAwardType },
          ...state.loadedAwardTypes.slice(idx + 1),
        ],
        isModalOpened: false,
      };

    case 'UPDATE_CYCLE':
      if (state.currentCycleId !== action.data) nextLoadRequest = requestStatus.REQUEST_NOT_INITIATED;
      return { ...state, currentCycleId: action.data, loadRequest: nextLoadRequest };

    case 'LOAD_DATA':
      return {
        ...state,
        loadRequest: requestStatus.REQUEST_COMPLETED,
        loadedAwardTypes: action.data,
      };

    case 'LOAD_CYCLES':
      nextCurrentCycleId = action.data.length ? action.data[0].id : nextCurrentCycleId;
      return {
        ...state,
        loadedCycles: action.data,
        currentCycleId: nextCurrentCycleId,
      };

    case 'UPDATE_TYPE_FILTER':
      if (state.typeFilter !== action.data) nextLoadRequest = requestStatus.REQUEST_NOT_INITIATED;
      return { ...state, typeFilter: action.data, loadRequest: nextLoadRequest };

    case 'UPDATE_DATA':
      return { ...state, ...action.data };

    case 'OVERRIDE_DATA': {
      idx = state.loadedAwardTypes.findIndex((item) => item.id === action.data.oldId);

      return {
        ...state,
        loadedAwardTypes: [
          ...state.loadedAwardTypes.slice(0, idx),
          {
            ...state.loadedAwardTypes[idx],
            id: action.data.awardType.id,
          },
          ...state.loadedAwardTypes.slice(idx + 1),
        ],
      };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { awardTypeFormReducer, initAwardTypesForm };
