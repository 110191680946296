import React from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons';

import './FileUpload.css';

function FileUpload({ file, setFile }) {
  const onFileChange = (event) => {
    const reader = new FileReader();
    const fileObj = event.target.files[0];

    // interesting properties of fileObj
    // - lastModifiedDate
    // - name
    // - size (in bytes)
    // - type

    reader.onloadend = () => {
      setFile(reader.result);
    };

    reader.readAsDataURL(fileObj);
  };

  function renderFilePreview() {
    if (file) {
      return (
        <div className="image-container">
          <img src={file} alt="preview" width="100%" height="100%" />
        </div>
      );
    }
    return (
      <div className="image-container text-center">
        <FontAwesomeIcon size="8x" icon={faFileImage} fixedWidth className="no-image" />
      </div>
    );
  }

  return (
    <>
      {renderFilePreview()}
      <CustomInput type="file" id="fileUpload" onChange={onFileChange} accept="image/png, image/jpeg" />
    </>
  );
}
FileUpload.propTypes = {
  file: PropTypes.string,
  setFile: PropTypes.func.isRequired,
};
FileUpload.defaultProps = {
  file: null,
};

export default FileUpload;
