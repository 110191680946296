const nominationListMode = {
  FINANCE_SIGNOFF: 1,
  EXEC_SIGNOFF: 2,
  MANAGEMENT_SIGNOFF: 3,
  FINANCIAL_INFORMATION: 4,
  ANYTIME_SIGNOFF: 5,
  SUPERVISOR_ONGOING: 6,
  CEREMONY_ASSIGNMENT: 7,
};

export default nominationListMode;
