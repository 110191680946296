import useApi from '../useApi';

export const useMissionProfileService = () => {
  const api = useApi();

  const loadMissionProfile = async (missionId) => {
    if (!missionId) {
      return null;
    }
    return api.get(`/missionprofile/LoadMissionProfile/${missionId}`);
  };

  const saveMissionProfile = async (data) => api.post('/missionprofile/SaveMissionProfile', data);

  return {
    loadMissionProfile,
    saveMissionProfile,
  };
};

export default useMissionProfileService;
