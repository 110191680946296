/* eslint-disable dot-notation */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Label, Modal, ModalBody, ModalHeader, ModalFooter, Row, Container, Form } from 'reactstrap';
import useFiscalStripValidation from 'hooks/admin/useFiscalStripValidation';
import InputField from 'components/InputField';

function FiscalStripModal({
  fiscalData,
  nominationId,
  isFiscalStripModalOpen,
  onClickToggleModal,
  selectedFiscalStrip,
  onSave,
}) {
  const { validate } = useFiscalStripValidation();
  const [validationResult, setValidationResult] = useState();
  const title = 'Assign Fiscal Strip';
  const actionBtnLabel = fiscalData.type !== 'nominee' && selectedFiscalStrip ? 'Update' : 'Done';
  const fiscalStrip = fiscalData.type === 'nomination' ? selectedFiscalStrip : fiscalData.fiscalStrip;

  const fiscalStripArray = fiscalStrip && fiscalStrip.split('/');
  const userModel = {
    agency: null,
    bbfy: null,
    ebfy: null,
    appropriation: null,
    bureau: undefined,
    allotment: null,
    operatingAllowance: null,
    obligation: null,
    functionCode: null,
    objectCode: null,
    projectCode: null,
    organizationCode: null,
  };

  if (fiscalStripArray) {
    fiscalStripArray.forEach((item, i) => {
      if (item.toLowerCase() === 'na') {
        fiscalStripArray[i] = null;
      }
    });
  }

  if (fiscalStripArray && fiscalStripArray.length > 1) {
    [
      userModel.agency,
      userModel.bbfy,
      userModel.ebfy,
      userModel.appropriation,
      userModel.bureau,
      userModel.allotment,
      userModel.operatingAllowance,
      userModel.obligation,
      userModel.functionCode,
      userModel.objectCode,
      userModel.projectCode,
      userModel.organizationCode,
    ] = fiscalStripArray;
  }

  const [data, setData] = useState(userModel);

  const isFormDirty = JSON.stringify(data) !== JSON.stringify(userModel);

  const applyValidation = async (model) => {
    const validationResult1 = await validate(model);

    setValidationResult(validationResult1);
  };

  const changeHandler = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const target = e.target.id;
    setData((prevData) => {
      const newData = {
        ...prevData,
        [target]: val,
      };
      applyValidation(newData);
      return newData;
    });
  };

  const onClickSave = async () => {
    const fiscalStripData = {
      nominationId,
      nomineeEmployeeId: fiscalData.nomineeEmployeeId,
      data: {
        ...data,
        bbfy: Number(data.bbfy),
        ebfy: Number(data.ebfy),
        operatingAllowance: data.operatingAllowance,
      },
    };

    applyValidation(fiscalStripData.data);
    if (validationResult.isValid) {
      onSave(fiscalStripData);
    }
  };

  return (
    <Modal isOpen={isFiscalStripModalOpen} toggle={onClickToggleModal} size="xl">
      <ModalHeader toggle={onClickToggleModal}>{title}</ModalHeader>
      <Container fluid className="min-h-350px max-h-90 w-100">
        <ModalBody>
          <Form>
            <div>
              <Row>
                <h6>{fiscalStrip ? `Edit:  ${fiscalStrip}` : 'New Fiscal Strip'}</h6>
              </Row>

              <FormGroup>
                <div className="d-flex">
                  <Label for="agency" sm={2}>
                    Agency
                  </Label>
                  <InputField
                    id="agency"
                    type="number"
                    value={data.agency}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['agency']}
                  />
                  <Label for="bureau" sm={2}>
                    Bureau
                  </Label>
                  <InputField
                    id="bureau"
                    value={data.bureau}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['bureau']}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="d-flex">
                  <Label for="bbfy" sm={2}>
                    BBFY
                  </Label>
                  <InputField
                    type="number"
                    id="bbfy"
                    value={data.bbfy}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['bbfy']}
                  />
                  <Label for="ebfy" sm={2}>
                    EBFY
                  </Label>
                  <InputField
                    type="number"
                    id="ebfy"
                    value={data.ebfy}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['ebfy']}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="d-flex">
                  <Label for="appropriation" sm={2}>
                    Appropriation
                  </Label>
                  <InputField
                    id="appropriation"
                    name="Appropriation"
                    value={data.appropriation}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['appropriation']}
                  />
                  <Label for="allotment" sm={2}>
                    Allotment
                  </Label>
                  <InputField
                    id="allotment"
                    name="Allotment"
                    value={data.allotment}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['allotment']}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="d-flex">
                  <Label for="operatingAllowance" sm={2}>
                    Operating Allowance
                  </Label>
                  <InputField
                    id="operatingAllowance"
                    name="Operating Allowance"
                    value={data.operatingAllowance}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['operatingAllowance']}
                  />
                  <Label for="obligation" sm={2}>
                    Obligation
                  </Label>
                  <InputField
                    id="obligation"
                    name="Obligation"
                    value={data.obligation}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['obligation']}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="d-flex">
                  <Label for="objectCode" sm={2}>
                    Object Code
                  </Label>
                  <InputField
                    id="objectCode"
                    name="Object Code"
                    value={data.objectCode}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['objectCode']}
                  />
                  <Label for="functionCode" sm={2}>
                    Function Code
                  </Label>
                  <InputField
                    id="functionCode"
                    name="Function Code"
                    value={data.functionCode}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['functionCode']}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="d-flex">
                  <Label for="projectCode" sm={2}>
                    Project Code
                  </Label>
                  <InputField
                    id="projectCode"
                    name="Project Code"
                    value={data.projectCode}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['projectCode']}
                  />
                  <Label for="organizationCode" sm={2}>
                    Organization Code
                  </Label>
                  <InputField
                    id="organizationCode"
                    name="Organization Code"
                    type="number"
                    value={data.organizationCode}
                    onChange={changeHandler}
                    errors={validationResult?.error && validationResult.error['organizationCode']}
                  />
                </div>
              </FormGroup>
            </div>
          </Form>
        </ModalBody>
      </Container>
      <ModalFooter>
        <Button
          className="min-w-150px"
          color="primary"
          onClick={() => onClickSave(fiscalData.nomineeEmployeeId !== null)}
          disabled={!isFormDirty}
        >
          {actionBtnLabel}
        </Button>
        <Button className="min-w-150px" color="secondary" onClick={() => onClickToggleModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

FiscalStripModal.propTypes = {
  fiscalData: PropTypes.object,
  nominationId: PropTypes.number,
  isFiscalStripModalOpen: PropTypes.bool.isRequired,
  onClickToggleModal: PropTypes.func.isRequired,
  selectedFiscalStrip: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

FiscalStripModal.defaultProps = {
  nominationId: null,
  fiscalData: null,
  selectedFiscalStrip: null,
};

export default React.memo(FiscalStripModal);
