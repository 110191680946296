import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faUserCog as faUserCog2,
  faUserShield,
  faAngleDown,
  faAngleRight,
  faChartBar,
  faPuzzlePiece,
  faFlask,
  faHistory,
  faUserTie as faUserTie2,
  faUserClock,
  faGraduationCap,
  faFileCertificate,
  faBadgeCheck as faBadgeCheck2,
  faUserFriends as faUserFriends2,
  faUsersCog,
  faUsers,
  faGlobeStand,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faFile,
  faList,
  faListAlt,
  faGavel,
  faVoteYea,
  faStamp,
  faClipboardCheck,
  faUserTie,
  faDrawCircle,
  faMoneyCheckEditAlt,
  faFileInvoiceDollar,
  faThumbsDown,
  faSortCircle,
  faShareAll,
  faFlagCheckered,
  faExternalLinkAlt,
  faUserFriends,
  faBuilding,
  faAward,
  faUserCog,
  faBadgeCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { useNavigationLinks } from 'hooks';

library.add(
  faHome,
  faUserCog,
  faUserCog2,
  faUsersCog,
  faUserShield,
  faAngleDown,
  faAngleRight,
  faChartBar,
  faPuzzlePiece,
  faFlask,
  faUserFriends,
  faUserFriends2,
  faFile,
  faList,
  faListAlt,
  faGavel,
  faFileInvoiceDollar,
  faMoneyCheckEditAlt,
  faUserTie,
  faUserTie2,
  faVoteYea,
  faStamp,
  faClipboardCheck,
  faDrawCircle,
  faBuilding,
  faHistory,
  faThumbsDown,
  faSortCircle,
  faShareAll,
  faFlagCheckered,
  faExternalLinkAlt,
  faAward,
  faUserClock,
  faGraduationCap,
  faFileCertificate,
  faBadgeCheck,
  faBadgeCheck2,
  faUsers,
  faGlobeStand
);

function MenuItem({ label, icon, target, subItems }) {
  const { getURL } = useNavigationLinks();
  const [isExpanded, setExpanded] = useState(true);
  const onClickExpand = (event) => {
    setExpanded(!isExpanded);
    event.preventDefault();
  };
  const hasSubMenu = subItems && subItems.length > 0;
  const isExpandedClass = isExpanded ? 'd-block' : 'd-none';

  if (hasSubMenu)
    return (
      <>
        <a className="nav-link clearfix" href="#_" onClick={onClickExpand}>
          <span className="font-weight-bold text-primary">{label}</span>
          <span className="float-right ">
            <FontAwesomeIcon
              className="mr-2 ml-3"
              icon={isExpanded ? 'angle-down' : 'angle-right'}
              fixedWidth
              size="lg"
            />
          </span>
        </a>
        <div className="border-bottom">
          <div className={isExpandedClass}>
            {subItems.map((item) => (
              <nav className="nav flex-column" key={item.key}>
                <Link className="nav-link" to={getURL(item.key)}>
                  <FontAwesomeIcon className="mr-1" icon={item.icon} fixedWidth size="lg" />
                  {item.label}
                </Link>
              </nav>
            ))}
          </div>
        </div>
      </>
    );

  return (
    <nav className="nav flex-column border-bottom">
      <Link className="nav-link" to={target}>
        <FontAwesomeIcon className="mr-1" icon={icon} fixedWidth />
        {label}
      </Link>
    </nav>
  );
}
MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.arrayOf(PropTypes.string),
  target: PropTypes.string,
  subItems: PropTypes.arrayOf(PropTypes.object),
};
MenuItem.defaultProps = {
  icon: [],
  target: '',
  subItems: [],
};
export default MenuItem;
