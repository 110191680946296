import React, { useRef, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'primereact/toast';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';

const NotificationContext = React.createContext({});

const initState = {
  title: '',
  message: '',
  icon: null,
  titleClassName: '',
  isModalOpened: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL':
      return { ...state, isModalOpened: false, title: '', message: '' };

    case 'SHOW_MESSAGE':
      return { ...state, ...action.data, isModalOpened: true };

    case 'SET_API_ERROR':
      return { ...state, apiError: action.apiError };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function NotificationProvider({ children }) {
  const toast = useRef(null);
  const [store, dispatcher] = useReducer(reducer, initState);

  const showSuccess = (message) =>
    toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 5000 });
  const showWarn = (message) =>
    toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
  const showInfo = (message) =>
    toast.current.show({ severity: 'info', summary: 'Information', detail: message, life: 5000 });
  const showError = (message) =>
    toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });

  const showAlertMessage = (message, title, icon, titleClassName) => {
    dispatcher({ type: 'SHOW_MESSAGE', data: { message, title, icon, titleClassName } });
  };

  const closeModal = () => dispatcher({ type: 'CLOSE_MODAL' });

  const renderMessage = (message) => {
    if (Array.isArray(message)) {
      // eslint-disable-next-line react/no-array-index-key
      return message.map((msg, idx) => <div key={idx}>{msg}</div>);
    }
    return message;
  };

  const contextValue = useMemo(() => ({ showSuccess, showInfo, showWarn, showError, showAlertMessage }), []);

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <Toast ref={toast} />
      <Modal isOpen={store.isModalOpened} toggle={closeModal}>
        <ModalHeader toggle={closeModal} className={store.titleClassName}>
          {store.icon && <FontAwesomeIcon icon={store.icon} className="mr-2" />}
          {store.title}
        </ModalHeader>
        <ModalBody>{renderMessage(store.message)}</ModalBody>
        <ModalFooter>
          <Button label="Ok" icon="pi pi-check" onClick={closeModal} />
        </ModalFooter>
      </Modal>
    </NotificationContext.Provider>
  );
}
NotificationProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { NotificationContext, NotificationProvider };
