import React, { useReducer, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/pro-light-svg-icons';
import { approvalStatus, requestStatus } from 'constants/index';
import { useNominationService } from 'hooks/nomination';
import { formatDate } from 'utils/dateHelpers';
import stateSeal from 'assets/images/us-department-of-state-1.svg';
import PropTypes from 'prop-types';
import { nominationStatus, formOfRecognition } from '../../../constants/index';
import FormBlock from './FormBlock';
import './Form.css';

const initState = {
  requestStatus: requestStatus.REQUEST_NOT_INITIATED,
  submissionDate: null,
  approvalDate: null,
  finalApprovedDate: null,
  chairApprovalDate: null,
  chairName: null,
  nominatorName: '',
  nominatorTitle: '',
  nominatorAgency: '',
  nominationKey: '',
  missionName: '',
  postName: '',
  recommededAmmount: '',
  approvedAmount: '',
  finalApproverName: '',
  awardReasons: [],
  formOfRecognition: '',
  postBugetOfficer: '',
  justification: '',
  citation: '',
  nominationEmployees: [],
  otherReason: null,
  execApprovalDate: null,
  execOfficerName: null,
  financeOfficerName: null,
  chairTitle: null,
  execOfficerTitle: null,
  financeOfficerTitle: null,
  part4Status: null,
  part5Status: null,
  part7Status: null,
  part7Remark: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, requestStatus: requestStatus.REQUEST_IN_PROGRESS };

    case 'LOAD_DATA':
      return {
        ...state,
        ...action.data,
        requestStatus: requestStatus.REQUEST_COMPLETED,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
// eslint-disable-next-line react/display-name
const NominationServiceAwardForm = React.forwardRef(({ nominationId }, ref) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const service = useNominationService();

  useEffect(() => {
    const loadData = async () => {
      dispatch({ type: 'START_LOADING' });
      const response = await service.getFormData(nominationId);
      if (response && response.ok) {
        console.log('form data', response.data);
        dispatch({ type: 'LOAD_DATA', data: response.data });
      }
    };
    if (state.requestStatus === requestStatus.REQUEST_NOT_INITIATED) {
      loadData();
    }
  }, [nominationId, service, state.requestStatus]);

  const GreyHeaderRow = useCallback((text) => {
    return (
      <div className="row ">
        <div className="col-12 center-block text-center bg-light pt-0 px-0 border-b align-items-center">
          <p className="h6 pt-2">{text}</p>
        </div>
      </div>
    );
  }, []);

  const getUnitSymbol = (option) => {
    if (!option) return '';
    return option === formOfRecognition.CASH_ONLY ? 'USD' : 'Hours';
  };

  const renderCheckbox = (text) => {
    return (
      <>
        <FontAwesomeIcon
          icon={
            state.awardReasons.some((item) => item.awardReasonName === text || text === 'true')
              ? faCheckSquare
              : faSquare
          }
          size="lg"
        />{' '}
        {text}
      </>
    );
  };

  const renderFiscalStrips = () => {
    return (
      <>
        {state.nominationEmployees
          .filter((x) => x.approvalStatus !== approvalStatus.DISAPPROVED)
          .map((nominee) => (
            <div className="row border-dotted-b" key={nominee.fiscalStrip}>
              <p className="mb-1 col-6">{nominee.name}</p>
              <p className="mb-1 col-6 text-break"> {nominee.fiscalStrip}</p>
            </div>
          ))}
      </>
    );
  };

  const renderAwardReasons = () => {
    return (
      <>
        {state.awardReasons.map((item) => (
          <>
            {/* <FontAwesomeIcon className="pr-1" icon={faCheckSquare} size="lg" /> */}
            <span className="pr-2" key={item.id}>
              {item.awardReasonName === 'Other' && state.otherReason ? state.otherReason : item.awardReasonName}{' '}
            </span>
          </>
        ))}
      </>
    );
  };

  const renderAwardTypes = () => {
    return (
      <>
        {/* <FontAwesomeIcon className="pr-1" icon={faCheckSquare} size="lg" /> */}
        <span className="pr-2">{state.awardTypeName}</span>
      </>
    );
  };

  const typeOfAward = () => {
    const individualOrGroup = state.nominationEmployees.length;
    if (individualOrGroup > 1) {
      return <p>Group</p>;
    }
    return <p>Individual</p>;
  };

  // const displayPartVIIStatus = () => {
  //   return
  //     // <span className="pr-2">line1:
  //     //   <FontAwesomeIcon icon="faSquare" size="lg" />
  //     //   line2:
  //     //   <FontAwesomeIcon icon="faSquare" size="lg" />
  //     //   </span>
  //     <>hi</>;

  // };

  const displayNominationStatus = () => {
    let returnStatus = '';
    switch (state.nominationStatus) {
      case nominationStatus.DISAPPROVED:
        returnStatus = 'DISAPPROVED';
        break;
      case nominationStatus.DISAPPROVED_BY_SUPERVISOR:
        returnStatus = 'DISAPPROVED';
        break;
      case nominationStatus.DELETED:
        returnStatus = 'DISAPPROVED';
        break;
      default:
        returnStatus = 'APPROVED';
    }
    return returnStatus;
  };

  const renderSupervisors = () => {
    const uniqueSupervisors = state.nominationEmployees
      .filter((x) => x.approvalStatus === approvalStatus.APPROVED)
      .map((item) => item?.usdhSupervisorName);
    return (
      <>
        {uniqueSupervisors
          .filter((q, idx) => uniqueSupervisors.indexOf(q) === idx)
          .map((item) => (
            <span className="pr-2" key={item?.uSDHSupervisorId}>
              {item}
              {'; '}
            </span>
          ))}
      </>
    );
  };

  return (
    <div ref={ref} key={state.nominationKey} className="px-3">
      <div className="container-fluid mb-0 pt-0 pb-0 printable-form">
        {GreyHeaderRow('FOR OFFICE USE')}
        <div className="row border-b">
          <div className="col-3 border-r">
            <FormBlock
              Rows={[
                {
                  line1: 'Nominator Agency',
                  line2: state.nominatorAgency,
                },
              ]}
            />
          </div>
          <div className="col-3 border-r">
            <FormBlock
              Rows={[
                {
                  line1: 'Date Received by HR',
                  line2: formatDate(state.usdhApprovalDate),
                },
              ]}
            />
          </div>
          <div className="col-3 border-r" />
          <div className="col-3">
            <FormBlock
              Rows={[
                {
                  line1: 'OAS Nomination ID',
                  line2: state.nominationKey,
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-12">
            <div className="pl-2 pt-2 pb-2 text-center">
              <div className="pt-3 float-left position-absolute">
                <img className="usseal" src={stateSeal} alt="US Department of State" />
              </div>
            </div>
            <div className="pt-2 text-center font-arial">US Mission {state.missionName}</div>
            <div className="h3 pt-2 text-center font-weight-bold font-arial">{typeOfAward()}</div>
            <div className="pl-2 text-center h2 font-weight-bold font-arial">NOMINATION FOR AWARD</div>
          </div>
        </div>
        {GreyHeaderRow('Part I - NOMINATION ')}

        <div className="row border-b">
          <div className="col-4 border-r py-1 mb-0">Name of Nominee (Last, First, MI)</div>
          <div className="col-2 border-r py-1 mb-0">Payroll ID (Grade)</div>
          {/* <div className="col-1 border-r py-1 mb-0">Grade</div> */}
          <div className="col-2 border-r py-1 mb-0">Nominee Post and Agency</div>
          <div className="col-2 border-r py-1 mb-0">Nominee Present Position Title</div>
          <div className="col-2 py-1 mb-0">Nominee Position During Nomination Period If Different Than Present</div>
        </div>
        {state.nominationEmployees
          .filter((x) => x.approvalStatus === approvalStatus.APPROVED)
          .map((nominee, idx) => (
            <div
              className={`row {$idx < state.nominationEmployees.length - 1 ? 'border-dotted-b' : 'border-b'}`}
              key={nominee.employeeId}
            >
              <div className="col-4 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee.name,
                    },
                  ]}
                />
              </div>
              <div className="col-2 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: `${nominee.payrollId ?? 'N/A'} (${nominee.grade ?? 'N/A'})`,
                    },
                  ]}
                />
              </div>
              {/* <div className="col-1 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee.grade,
                    },
                  ]}
                />
              </div> */}
              <div className="col-2 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: `${nominee.postName}  ${nominee.agency}`,
                    },
                  ]}
                />
              </div>
              <div className="col-2 border-r">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee.positionTitle,
                    },
                  ]}
                />
              </div>
              <div className="col-2">
                <FormBlock
                  Rows={[
                    {
                      line2: nominee.previousPositionTitle,
                    },
                  ]}
                />
              </div>
            </div>
          ))}

        {GreyHeaderRow('PART II - REASON FOR AWARD | TYPE OF AWARD')}
        <div className="row border-b">
          <div className="col-12">
            <FormBlock
              Rows={[
                {
                  line1: (
                    <span>
                      {renderAwardReasons()} |&nbsp;&nbsp; {renderAwardTypes()}
                    </span>
                  ),
                },
              ]}
            />
          </div>

          {/* <div className="col-3">
            <FormBlock
              Rows={[
                {
                  line1: <span>{renderCheckbox('Performance')}</span>,
                  line2: <span>{renderCheckbox('Special Act')}</span>,
                },
              ]}
            />
          </div> */}
          {/* <div className="col-3">
            <FormBlock
              Rows={[
                {
                  line1: <span>{renderCheckbox('Customer Service')}</span>,
                  line2: <span>{renderCheckbox('Innovation')}</span>,
                },
              ]}
            />
          </div>
          <div className="col-3">
            <FormBlock
              Rows={[
                {
                  line1: <span>{renderCheckbox('Teamwork')}</span>,
                  line2: <span>{renderCheckbox('Crisis Management')}</span>,
                },
              ]}
            />
          </div>
          <div className="col-3">
            <FormBlock
              Rows={[
                {
                  line2: (
                    <span>
                      {renderCheckbox('Other')} :{state.otherReason && state.otherReason}
                    </span>
                  ),
                },
              ]}
            />
          </div> */}
        </div>
        {GreyHeaderRow('PART III - TYPE OF AWARD RECOMMENDED')}
        <div className="row border-b">
          <div className="col-12">
            <FormBlock
              Rows={[
                {
                  line1: <span>{renderAwardTypes()}</span>,
                },
              ]}
            />
          </div>
          {/* <div className="col-4">
            <FormBlock
              Rows={[
                {
                  line1: <span>{renderCheckbox('Honor Award')}</span>,
                  line2: <span>{renderCheckbox('LE Staff Employee Award')}</span>,
                  line3: <span>{renderCheckbox('Eagle Award')}</span>,
                },
              ]}
            />
          </div>
          <div className="col-4">
            <FormBlock
              Rows={[
                {
                  line1: <span>{renderCheckbox('Good Idea! Award')}</span>,
                  line2: <span>{renderCheckbox('Safe Driver Award')}</span>,
                  line3: <span>{renderCheckbox('Time-Off Award')}</span>,
                },
              ]}
            />
          </div>
          <div className="col-4">
            <FormBlock
              Rows={[
                {
                  line1: <span>{renderCheckbox('“Other Mission" Award:')}</span>,
                  line2: <span>{renderCheckbox('Other Agency Award:')}</span>,
                },
              ]}
            />
          </div> */}
        </div>

        <div className="row border-b">
          <div className="col-4">
            <FormBlock
              Rows={[
                {
                  line1: 'Form of Recognition and Amount',
                  line2: `${state.recommededAmmount} ${getUnitSymbol(state.formOfRecognition)} `,
                },
              ]}
            />
          </div>
          {/* <div className="col-4 border-r">
            <FormBlock
              Rows={[
                {
                  line1: 'Approved Form of Recognition and Amount',
                  line2: state.approvedAmount,
                },
              ]}
            />
          </div>
          <div className="col-4">
            <FormBlock
              Rows={[
                {
                  line1: 'Approved Award Type',
                },
              ]}
            />
          </div> */}
        </div>
        <div className="row border-b">
          <div className="col-12 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Justification for Award',
                  line2: <div dangerouslySetInnerHTML={{ __html: state.justification }} />,
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-12 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Citation for Award',
                  line2: <div dangerouslySetInnerHTML={{ __html: state.citation }} />,
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1: 'Nominated By (Full Name, Title)',
                  line2: `${state.nominatorName}  -  ${state.nominatorTitle ?? 'N/A'}`,
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.submissionDate),
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-10 border-r">
            <FormBlock
              Rows={[
                {
                  line1:
                    'Approved By (Supervisor’s Full Name, Title).  Applicable only if nominated by other than supervisor.',
                  line2: <span>{renderSupervisors()}</span>,
                },
              ]}
            />
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.usdhApprovalDate),
                },
              ]}
            />
          </div>
        </div>
        {/* {GreyHeaderRow(
          'PART II – ACTION TAKEN/TIME OFF FROM DUTY AWARD – Optional – for period not to exceed one work day.'
        )}

        <div className="row border-b">
          <div className="col-7 border-r px-0">
            <div className="border-b">
              <div className="col-12">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Bureau/Post Approval (Name, Title)',
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <div className="col-12">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Bureau/Post Approval Signature',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col-5">
            <span>Date (mm/dd/yyyy)</span>
          </div>
        </div> */}

        {GreyHeaderRow('PART IV – ACTION TAKEN BY JOINT COUNTRY AWARDS COMMITTEE')}

        <div className="row border-b">
          <div className="col-10 border-r">
            <div className="row ">
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: state.part4Status,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="row ">
              {/* <div className="col-12 ">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Approved Award Type',
                    },
                  ]}
                />
              </div> */}
            </div>

            <div className="col-12 px-0">
              {/* <FormBlock
                Rows={[
                  {
                    line1: 'Approved Form of Recognition and Amount',
                    line2: <span>Cash: {state.approvedAmount} </span>,
                  },
                ]}
              /> */}
            </div>
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.chairApprovalDate),
                },
              ]}
            />
          </div>

          {/* <div className="col-6">
            <FormBlock
              Rows={[
                {
                  line1: 'Remarks',
                },
              ]}
            />
          </div> */}
        </div>
        <div className="row border-b">
          <div className="col-12 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Committee Chairperson (Full Name, Title)',
                  line2: `${state.chairName} - ${state.chairTitle}`,
                },
              ]}
            />
          </div>
        </div>
        {GreyHeaderRow('PART V – ACTION TAKEN BY CHIEF OF MISSION')}

        <div className="row border-b">
          <div className="col-10 border-r">
            <div className="row ">
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: state.part5Status,
                    },
                  ]}
                />
              </div>
            </div>
            {/* <div className="row border-b">
              <div className="col-12 ">
                <FormBlock
                  Rows={[
                    {
                      line1: 'Approved Award Type',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="col-12 px-0">
              <FormBlock
                Rows={[
                  {
                    line1: 'Approved Form of Recognition and Amount',
                    line2: <span>Cash: {state.approvedAmount} </span>,
                  },
                ]}
              />
            </div> */}
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                  line2: formatDate(state.execApprovalDate),
                },
              ]}
            />
          </div>

          {/* <div className="col-6">
            <FormBlock
              Rows={[
                {
                  line1: 'Remarks',
                },
              ]}
            />
          </div> */}
        </div>
        <div className="row border-b">
          <div className="col-12 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Chief of Mission (Full Name, Title)',
                  line2: state.execOfficerName ? `${state.execOfficerName} -  ${state.execOfficerTitle}` : 'N/A',
                },
              ]}
            />
          </div>
        </div>

        {GreyHeaderRow('PART VI – ACTION TAKEN BY AREA AWARDS COMMITTEE')}

        <div className="row border-b">
          <div className="col-10 border-r">
            <div className="row ">
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: <span>{renderCheckbox('APPROVED')}</span>,
                      line2: <span>{renderCheckbox('DISAPPROVED')}</span>,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-12 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Full Name of Committee Chairperson:',
                },
              ]}
            />
            <br />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-12 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Remarks',
                },
              ]}
            />
            <br />
            <br />
            <br />
          </div>
        </div>

        {GreyHeaderRow('PART VII – ACTION TAKEN BY DEPARTMENT AWARDS COMMITTEE')}

        <div className="row border-b">
          <div className="col-10 border-r">
            <div className="row ">
              <div className="pl-2 ">
                <FormBlock
                  Rows={[
                    {
                      line1: <span>{state.part7Status ? state.part7Status : renderCheckbox('APPROVED')}</span>,
                      line2: <span>{!state.part7Status && renderCheckbox('DISAPPROVED')}</span>,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col-2 text-right">
            <FormBlock
              Rows={[
                {
                  line1: 'Date (mm/dd/yyyy)',
                },
              ]}
            />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-12 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Full Name and Title:',
                },
              ]}
            />
            <br />
          </div>
        </div>
        <div className="row border-b">
          <div className="col-12 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Remarks',
                  line2: <div dangerouslySetInnerHTML={{ __html: state.part7Remark }} />,
                },
              ]}
            />
            <br />
            <br />
            <br />
          </div>
        </div>

        {GreyHeaderRow('PART VIII – FISCAL DATA')}
        <div className="row border-b">
          <div className="col-12 border-r">
            <FormBlock
              Rows={[
                {
                  line1: 'Post Budget Officer (Full Name, Title)',
                  line2: state.financeOfficerName
                    ? `${state.financeOfficerName} -  ${state.financeOfficerTitle}`
                    : 'N/A',
                },
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-2 border-r">
            <FormBlock
              Rows={[
                {
                  line1: 'Award Amount',
                  line2: `${state.approvedAmount} ${getUnitSymbol(state.formOfRecognition)}`,
                },
              ]}
            />
          </div>
          <div className="col-10 ">
            <FormBlock
              Rows={[
                {
                  line1: 'Account Classification Assigned',
                  line2: renderFiscalStrips(),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

NominationServiceAwardForm.propTypes = {
  nominationId: PropTypes.number,
};

NominationServiceAwardForm.defaultProps = {
  nominationId: null,
};

export default NominationServiceAwardForm;
