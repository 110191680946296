import useApi from './useApi';

const useEmployeeSearchService = () => {
  const api = useApi();

  const getSearchAvailableParameters = async (missionId) =>
    api.get(
      missionId ? `/employeeSearch/GetAvailableParameters/${missionId}` : `/employeeSearch/GetAvailableParameters`
    );

  const getAvailablePostsByMission = async (missionId) =>
    api.get(`/employeeSearch/GetAvailablePostByMission/${missionId}`);

  const search = async (searchDataRequest) => api.post('/employeeSearch/Search/', searchDataRequest);

  return {
    getSearchAvailableParameters,
    getAvailablePostsByMission,
    search,
  };
};

export default useEmployeeSearchService;
