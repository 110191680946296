import useApi from '../useApi';

export const usePostProfileService = () => {
  const api = useApi();

  const getPostsByMission = async (missionId) => {
    if (!missionId) {
      return null;
    }
    return api.get(`/postProfile/GetPostsByMission/${missionId}`);
  };

  const load = async (postId) => {
    if (!postId) {
      return null;
    }
    return api.get(`/postProfile/LoadPostProfile/${postId}`);
  };

  const save = async (data) => api.post('/postProfile/SavePostProfile', data);

  return {
    getPostsByMission,
    load,
    save,
  };
};

export default usePostProfileService;
