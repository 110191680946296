import React, { useState, useEffect, useContext } from 'react';
import { Switch } from 'react-router';
import { SecureRoute } from '@okta/okta-react';
import { useIdleTimer } from 'react-idle-timer';

import { appConfig } from 'config';
import { SessionContext } from 'contexts';
import { Layout } from 'views/Layout';
import { requestStatus } from 'constants/index';
import { useAuthorization, useNavigationLinks } from 'hooks';

import Home from 'views/Home/Home';
import Loading from 'views/Shared/Loading';
import TimeoutWarning from 'views/Shared/TimeoutWarning';
import NominationForm from 'views/NominationForm/NominationForm';
import VotingForm from 'views/VotingForm/VotingForm';
import AllAwards from 'views/Reports/AllAwards';
import TimeOff from 'views/Reports/TimeOff';
import MissionProfile from 'views/Admin/Mission/MissionProfile';
import Ceremony from 'views/Admin/Mission/Ceremony';
import CeremonyList from 'views/Admin/Mission/CeremonyList';
import PostProfileList from 'views/Admin/PostProfile/PostProfileList';
import PostProfileSettings from 'views/Admin/PostProfile/PostProfileSettings';
import MissionSpecificAwards from 'views/Admin/Mission/MissionSpecificAwards';
import GlobalAwards from 'views/Admin/ManageAwards/GlobalAwards';
import AwardTypeSettings from 'views/Admin/AwardType/AwardTypeSettings';
import CycleList from 'views/Admin/Cycle/CycleList';
import CycleSettings from 'views/Admin/Cycle/CycleSettings';
import EmployeeForm from 'views/Admin/Employee/EmployeeForm';
import Impersonate from 'views/Admin/Employee/Impersonate';
import AssignProxy from 'views/Admin/Employee/AssignProxy';
import MyAssignedRoles from 'views/Admin/Employee/MyAssignedRoles';
import FormCustomerServiceAward from 'views/Reports/Forms/CustomerServiceAwardForm';
import FormNominationServiceAward from 'views/Reports/Forms/NominationServiceAwardForm';
import NominationsList from 'views/NominationsList/NominationsList';
import SignOffForm from 'views/SignOff/SignOffForm';
import EmployeeSearch from 'views/Admin/Employee/EmployeeSearch';
import ManageAwards from 'views/Admin/ManageAwards/ManageAwards';
import SupervisedBy from 'views/Admin/Employee/SupervisedBy';
import ArchivedEmployee from 'views/Admin/Employee/ArchivedEmployee';
import AssignProxySpecial from 'views/Admin/Employee/AssignProxySpecial';
import AdminRoles from 'views/Admin/Employee/AdminRoles';
import Bureaus from 'views/Admin/Bureaus/Bureaus';
import Missions from 'views/Admin/Missions/Missions';
import BureauManageRoles from 'views/Admin/Bureaus/BureauManageRoles';
import BureauCycleList from 'views/Bureau/Coordinator/BureauCycleList';
import BureauCycleSettings from 'views/Bureau/Coordinator/BureauCycleSettings';
import BureauVoting from 'views/Bureau/Committee/BureauVoting';
import BureauAwards from 'views/Reports/Forms/BureauAwards';
import SignIntoMission from 'views/Admin/Employee/SignIntoMission';

function SecureApp() {
  const { sessionStore, sessionHelpers } = useContext(SessionContext);
  const [isTimeoutWarningOpen, setTimeoutWarningOpen] = useState(false);
  const { getMenuItemsForUser } = useAuthorization();
  const { goTo } = useNavigationLinks();

  const timeout = 1000 * 60 * appConfig.timeoutWarning;
  const handleOnIdle = () => {
    setTimeoutWarningOpen(true);
  };

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
    events: ['keydown', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown'],
  });

  useEffect(() => {
    if (sessionHelpers.isAuthenticated() && sessionStore.sessionRequest === requestStatus.REQUEST_NOT_INITIATED) {
      sessionHelpers.initSession();
    }
    if (sessionHelpers.isAuthenticated() === false) {
      goTo('logout');
    }
  }, [sessionHelpers, sessionStore.sessionRequest, goTo]);

  const isLoading = sessionStore.sessionRequest !== requestStatus.REQUEST_COMPLETED;

  function switchRoutes() {
    return (
      <Switch>
        <SecureRoute path="/Home" component={Home} />
        <SecureRoute path="/Nomination/Form/:mode/:nominationId?" component={NominationForm} />
        <SecureRoute
          path="/Voting/:mode/:navPostId?/:awardProcessTypeId?/:navNominationStatus?/:navCycleId?"
          component={VotingForm}
        />
        <SecureRoute
          path="/Nomination/List/:mode/:navPostId?/:awardProcessTypeId?/:navNominationStatus?/:navCycleId?/:nomineeName?"
          component={NominationsList}
        />
        <SecureRoute
          path="/SignOff/:mode/:navPostId?/:awardProcessTypeId?/:navNominationStatus?/:navCycleId?"
          component={SignOffForm}
        />
        <SecureRoute path="/Reports/TimeOff" component={TimeOff} />
        <SecureRoute path="/Reports/BureauAwards/:mode" component={BureauAwards} />
        <SecureRoute path="/Reports/:mode" component={AllAwards} />
        <SecureRoute path="/Reports/Form/CustomerServiceAward/:formType?" component={FormCustomerServiceAward} />
        <SecureRoute path="/Reports/Form/NominationServiceAward" component={FormNominationServiceAward} />
        <SecureRoute path="/Employee/MyAssignedRoles" component={MyAssignedRoles} />
        <SecureRoute path="/Admin/Employee/Archived" component={ArchivedEmployee} />
        <SecureRoute path="/Admin/Employee/Search/:mode" component={EmployeeSearch} />
        <SecureRoute path="/Admin/Employee/SupervisedBy/:id?" component={SupervisedBy} />
        <SecureRoute path="/Admin/Employee/:mode/:employeeId?/:feature?" component={EmployeeForm} />
        <SecureRoute path="/Admin/Impersonate/:proxyMode?" component={Impersonate} />
        <SecureRoute path="/Admin/AssignProxy" component={AssignProxy} />
        <SecureRoute path="/Admin/Mission/Profile/:key?" component={MissionProfile} />
        <SecureRoute path="/Admin/PostProfile/Profile" component={PostProfileList} />
        <SecureRoute path="/Admin/ManageAwards/GlobalAwards/:mode/:awardTypeId?" component={GlobalAwards} />
        <SecureRoute path="/Admin/PostProfile/:mode/:key?" component={PostProfileSettings} />
        <SecureRoute path="/Admin/Mission/SpecificAwards/:mode/:awardTypeId?" component={MissionSpecificAwards} />
        <SecureRoute path="/Admin/Mission/AwardTypeSettings" component={AwardTypeSettings} />
        <SecureRoute path="/Admin/ManageAwards/ManageAwards" component={ManageAwards} />
        <SecureRoute path="/Admin/Mission/Ceremony/:mode/:ceremonyId?" component={Ceremony} />
        <SecureRoute path="/Admin/Mission/CeremonyList" component={CeremonyList} />
        <SecureRoute path="/Admin/Cycle/List" component={CycleList} />
        <SecureRoute path="/Admin/Cycle/:mode/:cycleId?" component={CycleSettings} />
        <SecureRoute path="/Admin/Mission/AssignProxySpecial" component={AssignProxySpecial} />
        <SecureRoute path="/Admin/AdminRoles" component={AdminRoles} />
        <SecureRoute path="/Bureau/Coordinator/Cycle/List" component={BureauCycleList} />
        <SecureRoute path="/Bureau/Coordinator/Cycle/:mode/:cycleId?" component={BureauCycleSettings} />
        <SecureRoute path="/Bureau/Committee/Voting/:mode/:navMissionId?/:navCycleId?" component={BureauVoting} />
        <SecureRoute path="/Admin/Bureaus/BureauManageRoles/:id?" component={BureauManageRoles} />
        <SecureRoute path="/Admin/Bureaus" component={Bureaus} />
        <SecureRoute path="/Admin/Missions/Missions" component={Missions} />
        <SecureRoute path="/Admin/SignIntoMission" component={SignIntoMission} />
      </Switch>
    );
  }

  return (
    <>
      {isLoading && (
        <>
          <Loading backdrop />
          <SecureRoute path="/Home" />
        </>
      )}
      {!isLoading && sessionHelpers.isAuthenticated() && (
        <>
          <Layout menuItems={getMenuItemsForUser()}>{switchRoutes()}</Layout>
          {isTimeoutWarningOpen && (
            <TimeoutWarning
              isOpen
              closeTimeoutWarning={() => setTimeoutWarningOpen(false)}
              timeoutLogout={() => sessionHelpers.logoutSession()}
            />
          )}
        </>
      )}
    </>
  );
}

export default SecureApp;
