import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSession } from 'hooks';

const SessionContext = React.createContext({});

function SessionProvider({ children }) {
  const { sessionStore, sessionHelpers } = useSession();
  const contextValue = useMemo(() => ({ sessionStore, sessionHelpers }), [sessionHelpers, sessionStore]);
  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
}
SessionProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { SessionContext, SessionProvider };
