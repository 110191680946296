import useApi from '../useApi';

export const useAnytimeService = () => {
  const api = useApi();

  const load = async (nominationId, nominationFormMode) => {
    if (!nominationId) {
      return null;
    }
    return api.get(`/anytime/Load/${nominationId}/${nominationFormMode}`);
  };

  const setNominationApprover = async (requestData) => api.post('/anytime/SetNominationApprover', requestData);

  return {
    load,
    setNominationApprover,
  };
};

export default useAnytimeService;
