import {
  AlignmentType,
  Paragraph,
  TextRun,
  ImageRun,
  PageOrientation,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  WidthType,
  BorderStyle,
} from 'docx';

const borders = {
  top: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
  bottom: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
  left: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
  right: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
};

const generateRows = (columns) => {
  let nomineesTable;
  if (columns.length === 1) {
    nomineesTable = columns[0].map(
      (row) =>
        new TableRow({
          children: [
            new TableCell({
              borders,

              children: [
                new Paragraph({
                  spacing: { line: 196, after: 180 },

                  alignment: AlignmentType.CENTER,
                  children: [new TextRun({ italics: true, text: row, size: 54, font: 'Garamond' })],
                }),
              ],

              // alignment: AlignmentType.CENTER,
              // verticalAlign: VerticalAlign.CENTER,
              // textDirection: TextDirection.LEFT_TO_RIGHT_TOP_TO_BOTTOM,
            }),
          ],
        })
    );
  } else if (columns.length === 2 && columns[0].length === 20) {
    nomineesTable = columns[0].map(
      (row, index) =>
        new TableRow({
          children: [
            new TableCell({
              borders,
              children: [
                new Paragraph({
                  spacing: { line: 200 },

                  alignment: AlignmentType.LEFT,
                  children: [new TextRun({ italics: true, text: row, size: 36, font: 'Garamond' })],
                }),
              ],
            }),

            new TableCell({
              borders,
              children: [
                new Paragraph({
                  spacing: { line: 200 },

                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun({ italics: true, text: columns[1][index], size: 36, font: 'Garamond' })],
                }),
              ],
            }),
          ],
        })
    );
  } else if (columns.length === 2) {
    nomineesTable = columns[0].map(
      (row, index) =>
        new TableRow({
          children: [
            new TableCell({
              borders,
              children: [
                new Paragraph({
                  spacing: { line: 296 },

                  alignment: AlignmentType.LEFT,
                  children: [new TextRun({ italics: true, text: row, size: 40, font: 'Garamond' })],
                }),
              ],
            }),

            new TableCell({
              borders,
              children: [
                new Paragraph({
                  spacing: { line: 296 },

                  alignment: AlignmentType.RIGHT,
                  children: [new TextRun({ italics: true, text: columns[1][index], size: 40, font: 'Garamond' })],
                }),
              ],
            }),
          ],
        })
    );
  }

  return nomineesTable;
};

function PortraitCertificate({ mission, awardType, citation, footertable, sealUrl, columns }) {
  const renderNominees = new Table({
    rows: [...generateRows(columns)],

    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
  });

  return {
    properties: {
      page: {
        size: {
          orientation: PageOrientation.PORTRAIT,
        },
      },
    },
    children: [
      new Paragraph({
        // spacing: { line: 27, before: 640, after: 20 },
        children: [
          sealUrl
            ? new ImageRun({
                data: sealUrl,
                transformation: {
                  width: 109,
                  height: 111,
                },
                floating: {
                  zIndex: 5,
                  horizontalPosition: {
                    relative: HorizontalPositionRelativeFrom.PAGE,
                    align: HorizontalPositionAlign.CENTER,
                  },
                  verticalPosition: {
                    relative: VerticalPositionRelativeFrom.PAGE,
                    //   align: VerticalPositionAlign.TOP,
                    offset: 164400,
                  },
                },
              })
            : [],
        ],
      }),
      new Paragraph({
        spacing: { before: 290 },

        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({ break: 1, text: mission, size: 24 }),
          new TextRun({ break: 1, text: awardType, size: 84, font: 'Times' }),
        ],
      }),

      new Paragraph({
        spacing: { line: 220, before: 1, after: 440 },

        alignment: AlignmentType.CENTER,
        children: [new TextRun({ break: 1, text: 'This certificate is presented to', size: 26, font: 'Arial' })],
      }),
      renderNominees,
      new Paragraph({
        spacing: { line: 220, before: 70, after: 200 },

        alignment: AlignmentType.CENTER,
        children: [new TextRun({ break: 1, text: citation, size: 24, font: 'Arial' })],
      }),

      footertable,
      // new Paragraph({
      //   children: [table],
      // }),

      // ____________________________,
    ],

    // new Paragraph({
    //   children: [table],
    // }),

    // },
  };
}

export default PortraitCertificate;
