import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Label, Row, Col, FormGroup } from 'reactstrap';
import { Skeleton } from 'primereact/skeleton';
import { awardTypeFormReducer, initAwardTypesForm } from 'reducers';
import { formOfRecognition, requestStatus } from 'constants/index';
import { useAwardTypeService } from 'hooks/admin';

function FormOfRecognition({ formStore, helperFunctions, readOnly }) {
  const { nomination } = formStore;
  const [store, dispatch] = useReducer(awardTypeFormReducer, initAwardTypesForm);
  const awardTypeservice = useAwardTypeService();
  const [awardTypeFormOfRecognitions, setAwardTypeFormOfRecognitions] = useState();
  const [selectedAwardType, setSelectedAwardType] = useState({
    awardProcessType: nomination.awardProcessType,
    awardTypeId: nomination.awardTypeId,
  });

  const [isAwardTypeCurrent, setIsAwardTypeCurrent] = useState(false);

  useEffect(() => {
    const loadAwardTypes = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const requestData = {
        missionId: nomination.missionId,
        cycleId: store.currentCycleId,
        awardProcessType: nomination.awardProcessType,
      };

      const response = await awardTypeservice.getList(requestData);

      if (response && response.ok) {
        dispatch({ type: 'LOAD_DATA', data: response.data });
      }
    };

    // First Condition => Request is not initialized and nomination is not loaded
    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED && nomination.missionId !== null) {
      loadAwardTypes();
    }

    // Second Condition => request is completed, and the awardSettings is current
    if (store.loadRequest === requestStatus.REQUEST_COMPLETED && !isAwardTypeCurrent) {
      setSelectedAwardType({
        awardProcessType: nomination.awardProcessType,
        awardTypeId: nomination.awardTypeId,
      });
      const temp = store.loadedAwardTypes.find((_) => _.awardTypeId === nomination.awardTypeId);
      setAwardTypeFormOfRecognitions(temp?.formOfRecognition);
      setIsAwardTypeCurrent(true);
    }

    // Third Condition => request is completed, and awardTypeId is changed
    if (
      store.loadRequest === requestStatus.REQUEST_COMPLETED &&
      isAwardTypeCurrent &&
      nomination.awardTypeId !== selectedAwardType.awardTypeId
    ) {
      setSelectedAwardType({
        awardProcessType: selectedAwardType.awardProcessType,
        awardTypeId: nomination.awardTypeId,
      });

      setIsAwardTypeCurrent(false);
    }

    // Forth Condition => request is completed, and processType is changed, need to refresh the list
    if (
      store.loadRequest === requestStatus.REQUEST_COMPLETED &&
      isAwardTypeCurrent &&
      nomination.awardProcessType !== selectedAwardType.awardProcessType
    ) {
      loadAwardTypes();
      setIsAwardTypeCurrent(false);
    }
  }, [
    selectedAwardType,
    nomination.awardTypeId,
    nomination.awardProcessType,
    awardTypeservice,
    nomination.missionId,
    store.currentCycleId,
    store.loadedAwardTypes,
    store.loadRequest,
    isAwardTypeCurrent,
  ]);

  const isProcessing =
    formStore.userSessionRequest !== requestStatus.REQUEST_COMPLETED &&
    formStore.nominationLoadRequest !== requestStatus.REQUEST_COMPLETED;
  const mode = helperFunctions.getMode();

  return (
    <div className="row">
      <div className="col-12">
        {isProcessing && (
          <Row className="mb-3">
            <Col md="3">
              <Skeleton className="w-80" height="30px" />
            </Col>
            <Col md="3">
              <Skeleton className="w-80" height="30px" />
            </Col>
            <Col md="3" className="offset-md-1">
              <Skeleton className="w-80" height="30px" />
            </Col>
            <Col md="3" className="offset-md-1">
              <Skeleton className="w-80" height="30px" />
            </Col>
          </Row>
        )}
        {!isProcessing && awardTypeFormOfRecognitions !== undefined && (
          <Row>
            <Col className="col-2">
              <h6 className="mt-0">Form of Recognition</h6>

              {(awardTypeFormOfRecognitions === formOfRecognition.CASH_ONLY ||
                awardTypeFormOfRecognitions === formOfRecognition.CASH_OR_TIMEOFF) && (
                <FormGroup check>
                  <Label for="cash">
                    <input
                      type="radio"
                      id="cash"
                      name="formOfRecognition"
                      onChange={() =>
                        helperFunctions.onChangeFormOfRecognition({
                          mode,
                          formOfRecognition: formOfRecognition.CASH_ONLY,
                        })
                      }
                      checked={nomination.formOfRecognition === formOfRecognition.CASH_ONLY}
                      disabled={readOnly}
                    />
                    &nbsp; Cash
                  </Label>
                </FormGroup>
              )}

              {(awardTypeFormOfRecognitions === formOfRecognition.TIMEOFF_ONLY ||
                awardTypeFormOfRecognitions === formOfRecognition.CASH_OR_TIMEOFF) && (
                <FormGroup check>
                  <Label for="timeOff">
                    <input
                      type="radio"
                      id="timeOff"
                      name="formOfRecognition"
                      onChange={() =>
                        helperFunctions.onChangeFormOfRecognition({
                          mode,
                          formOfRecognition: formOfRecognition.TIMEOFF_ONLY,
                        })
                      }
                      checked={nomination.formOfRecognition === formOfRecognition.TIMEOFF_ONLY}
                      disabled={readOnly}
                    />
                    &nbsp; Time Off
                  </Label>
                </FormGroup>
              )}

              {awardTypeFormOfRecognitions === formOfRecognition.CERTIFICATE_ONLY && (
                <FormGroup check>
                  <Label for="certificate">
                    <input
                      type="radio"
                      id="certificate"
                      name="formOfRecognition"
                      onChange={() =>
                        helperFunctions.onChangeFormOfRecognition({
                          mode,
                          formOfRecognition: formOfRecognition.CERTIFICATE_ONLY,
                        })
                      }
                      checked={nomination.formOfRecognition === formOfRecognition.CERTIFICATE_ONLY}
                      disabled={readOnly}
                    />
                    &nbsp; Certificate Only
                  </Label>
                </FormGroup>
              )}
            </Col>
            {awardTypeFormOfRecognitions !== formOfRecognition.CERTIFICATE_ONLY && (
              <>
                {nomination.isProposedAmountVisible && (
                  <Col md="3">
                    <FormGroup>
                      <Label for="proposedAmount" className="text-nowrap">
                        Proposed Amount (per nominee)
                      </Label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            {helperFunctions.getUnitSymbol(
                              nomination.proposedFormOfRecognition ?? nomination.formOfRecognition
                            )}
                          </span>
                        </div>

                        <input
                          type="text"
                          id="proposedAmount"
                          onChange={(evt) => {
                            let amt = null;
                            if (evt.target.value) {
                              amt = parseInt(evt.target.value, 10);
                            }
                            helperFunctions.onChangeProposedAmount(amt);
                          }}
                          value={nomination.proposedAmount}
                          pattern="[0-9]"
                          disabled={!formStore.nomination.isProposedAmountEditable}
                        />
                      </div>
                      {(mode === 'new' || mode === 'edit') && nomination.proposedAmount === null && (
                        <div className="text-danger">Proposed Amount cannot be empty!</div>
                      )}
                    </FormGroup>
                  </Col>
                )}

                {formStore.nomination.isRecommendedAmountVisible && (
                  <Col md="3">
                    <FormGroup>
                      <Label for="recommendedAmount" className="text-nowrap">
                        Recommended Amount (per nominee)
                      </Label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            {helperFunctions.getUnitSymbol(
                              nomination.recommendedFormOfRecognition ?? nomination.formOfRecognition
                            )}
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="recommendedAmount"
                          onChange={(evt) => {
                            let amt = null;
                            if (evt.target.value) {
                              amt = parseInt(evt.target.value, 10);
                            }
                            helperFunctions.onChangeRecommendedAmount(amt);
                          }}
                          value={nomination.recommendedAmount}
                          pattern="[0-9]"
                          disabled={!formStore.nomination.isRecommendedAmountEditable}
                        />
                      </div>
                      {mode === 'review' && nomination.recommendedAmount === null && (
                        <div className="text-danger">Recommended Amount cannot be empty!</div>
                      )}
                    </FormGroup>
                  </Col>
                )}

                {nomination.isApprovedAmountVisible && (
                  <Col md="3">
                    <FormGroup>
                      <Label for="approvedAmount" className="text-nowrap">
                        Approved Amount (per nominee)
                      </Label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            {helperFunctions.getUnitSymbol(
                              nomination.approvedFormOfRecognition ?? nomination.formOfRecognition
                            )}
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="approvedAmount"
                          onChange={(evt) => {
                            let amt = null;
                            if (evt.target.value) {
                              amt = parseInt(evt.target.value, 10);
                            }
                            helperFunctions.onChangeApprovedAmount(amt);
                          }}
                          value={nomination.approvedAmount ? nomination.approvedAmount : 0}
                          pattern="[0-9]"
                          disabled="{true}"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
              </>
            )}
          </Row>
        )}
      </div>
    </div>
  );
}
FormOfRecognition.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};
FormOfRecognition.defaultProps = {
  readOnly: false,
};
export default FormOfRecognition;
