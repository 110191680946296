import useApi from '../useApi';

export const useMissionService = () => {
    const api = useApi();

    const getMissions = async () => {
        return api.get('/Mission/GetList');
    }

    const updateMission = async (data) => {
        return api.put('/Mission/Update', data);
      };

      return {
        getMissions,
        updateMission
      }
};

export default useMissionService;

