import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { SessionContext, NotificationContext } from 'contexts';
import { useNavigationLinks, useFormatting, useAuthorization } from 'hooks';
import { useCycleService, useCycleSettingsValidation } from 'hooks/admin';
import { requestStatus } from 'constants/index';
import { useCeremonyService } from 'hooks/admin/useCeremonyService';
import { nullOrDate, getMomentDate } from 'utils/dateHelpers';
import CycleBasicSettings from './CycleBasicSettings';
import PostAndRolesSettings from './PostAndRolesSettings';
import './CycleSettings.css';

const initialState = {
  year: 0,
  number: 0,
  name: '',
  missionId: 0,
  openingDate: null,
  closingDate: null,
  committeeStartDate: null,
  committeeEndDate: null,
  callDate: null,
  ceremonyId: null,
  isArchived: false,
  cyclePosts: [],
  cycleAssignedEmployees: [],
  missionPosts: [],
  ceremonies: [],
  editCycleAwardType: null,
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
  saveRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  let idx = -1;
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'START_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'COMPLETE_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_COMPLETED };
    case 'LOAD_DATA': {
      return {
        ...state,
        loadRequest: requestStatus.REQUEST_COMPLETED,
        ...action.data,
      };
    }

    case 'SET_DATA':
      return {
        ...state,
        loadRequest: requestStatus.REQUEST_COMPLETED,
        id: action.data.id,
        year: action.data.year,
        number: action.data.number,
        name: action.data.name,
        missionId: action.data.missionId,
        missionPosts: action.data.cycleSettings.missionPosts,
        cyclePosts: action.data.cycleSettings.cyclePosts.map((item) => ({
          key: item.postId,
          value: item.postName,
        })),
        cycleAssignedEmployees: action.data.cycleSettings.cycleAssignedEmployees,
        openingDate: nullOrDate(action.data.openingDate),
        closingDate: nullOrDate(action.data.closingDate),
        committeeStartDate: nullOrDate(action.data.committeeStartDate),
        committeeEndDate: nullOrDate(action.data.committeeEndDate),
        callDate: nullOrDate(action.data.callDate),
        ceremonyId: action.data.ceremonyId,
        isArchived: action.data.isArchived,
      };

    case 'UPDATE_DATA':
      return { ...state, ...action.data };

    case 'UPDATE_ASSIGNED_ROLES':
      idx = state.cycleAssignedEmployees.findIndex((item) => item.roleKey === action.data.roleKey);

      return {
        ...state,
        cycleAssignedEmployees: [
          ...state.cycleAssignedEmployees.slice(0, idx),
          {
            ...state.cycleAssignedEmployees[idx],
            employees: action.data.assignees,
          },
          ...state.cycleAssignedEmployees.slice(idx + 1),
        ],
        isJCACMemberModified: action.data.roleKey === 'JCACMember',
      };

    case 'UPDATE_CEREMONIES':
      idx = state.ceremonies.findIndex((item) => item.ceremonyId === state.editCeremonyId);
      return {
        ...state,
        ceremonies: [...state.ceremonies.slice(0, idx), { ...state.ceremonyId }, ...state.ceremonies.slice(idx + 1)],
      };

    case 'UPDATE_VALIDATION_RESULT':
      return {
        ...state,
        validationResultErrors: action.data,
      };

    case 'SET_YES_NO_MODAL_OPEN':
      return { ...state, YesNoModalOpen: true };

    case 'SET_YES_NO_MODAL_CLOSE':
      return { ...state, isJCACMemberModified: false, YesNoModalOpen: false };

    case 'SET_READY_FOR_SAVE':
      return { ...state, isReadyForSave: true };

    case 'RESET_JCAC_CHANGES':
      return { ...state, loadRequest: requestStatus.REQUEST_NOT_INITIATED };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function CycleSettings() {
  const { sessionStore } = useContext(SessionContext);
  const { showSuccess } = useContext(NotificationContext);
  const { goBack } = useNavigationLinks();
  const [activeTab, setActiveTab] = useState(0);
  const [disabledTab, setDisabledTab] = useState(true);
  const [store, dispatch] = useReducer(reducer, initialState);
  const service = useCycleService();
  const { mode, cycleId } = useParams();
  const missionId = sessionStore.currentMissionId;
  const formMode = mode.toLowerCase();
  const { validate } = useCycleSettingsValidation();
  const [isWarningMessageVisible, toogleWarningMessage] = useState(false);
  const { showError } = useContext(NotificationContext);
  const { formatCycle } = useFormatting();
  const ceremonyService = useCeremonyService();
  const { checkRole } = useAuthorization();
  const { goTo } = useNavigationLinks();

  const onSaveClick = async () => {
    // Check if the changes for JCAC Members were made during voting window

    const dataToSave = {
      id: store.id,
      missionId,
      year: store.year,
      number: store.number,
      name: store.name,
      openingDate: getMomentDate(store.openingDate),
      closingDate: getMomentDate(store.closingDate),
      committeeStartDate: getMomentDate(store.committeeStartDate),
      committeeEndDate: getMomentDate(store.committeeEndDate),
      callDate: getMomentDate(store.callDate),
      ceremonyId: store.ceremonyId,
      isArchived: store.isArchived,
      cycleSettings: {
        cyclePosts: store.cyclePosts.map((item) => ({ postId: item.key })),
        cycleAssignedEmployees: store.cycleAssignedEmployees,
      },
      isJCACMemberModified: false,
      isMessageModalOpen: false,
    };
    if (formMode === 'edit') {
      dataToSave.id = parseInt(cycleId, 10);
    }
    console.log('data to be saved:', dataToSave);

    toogleWarningMessage(false);
    const validationResult = await validate(dataToSave);
    if (!validationResult.isValid) {
      dispatch({
        type: 'UPDATE_VALIDATION_RESULT',
        data: validationResult.errors,
      });
      showError('There was an error saving the cycle settings.');
      toogleWarningMessage(true);
      return false;
    }

    dispatch({ type: 'START_SAVE_REQUEST' });
    const saveResponse = await service.save(dataToSave);
    if (saveResponse && saveResponse.ok) {
      showSuccess('Cycle data successfully saved!');

      if (formMode === 'new') {
        dispatch({ type: 'START_LOAD_REQUEST' });
        const loadResponse = await service.load(saveResponse.data.id);
        if (loadResponse && loadResponse.ok) {
          dispatch({ type: 'SET_DATA', data: loadResponse.data });
          setDisabledTab(false);
        }
      }
    }
    dispatch({ type: 'COMPLETE_SAVE_REQUEST' });
    return saveResponse.ok;
  };
  const onCancelClick = () => goBack();

  useEffect(() => {
    const loadCycle = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const response = await service.load(cycleId);
      if (response && response.ok) {
        dispatch({ type: 'SET_DATA', data: response.data });
      }
    };

    const loadCeremonies = async () => {
      const response = await ceremonyService.getListByMission(missionId);
      if (response && response.ok) {
        dispatch({
          type: 'LOAD_DATA',
          data: {
            ceremonies: response.data,
          },
        });
      }
    };

    const loadMissionPosts = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const response = await service.getPostsByMission(missionId);
      if (response && response.ok) {
        dispatch({
          type: 'UPDATE_DATA',
          data: { missionPosts: response.data },
        });
      }
    };

    if (cycleId && cycleId > 0) {
      setDisabledTab(false);
    }

    if (formMode === 'edit' && cycleId && store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadCycle();
      loadCeremonies();
    }
    if (formMode === 'new' && missionId && store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadMissionPosts();
      loadCeremonies();
    }
  }, [ceremonyService, cycleId, formMode, missionId, service, store.loadRequest]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  return (
    <>
      <h4>Cycle Settings - {formatCycle(store)}</h4>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Basic & Dates">
          <CycleBasicSettings
            mode={formMode}
            store={store}
            dispatch={dispatch}
            onSaveClick={onSaveClick}
            onCancelClick={onCancelClick}
            isWarningMessageVisible={isWarningMessageVisible}
            toogleWarningMessage={toogleWarningMessage}
          />
        </TabPanel>
        <TabPanel header="Posts & Roles" disabled={disabledTab}>
          <PostAndRolesSettings
            store={store}
            dispatch={dispatch}
            onSaveClick={onSaveClick}
            onCancelClick={onCancelClick}
          />
        </TabPanel>
      </TabView>
    </>
  );
}

export default CycleSettings;
