const nominationAction = {
  NONE: 0,
  FORWARD: 10,
  REJECT: 20,
  RETURN: 30,
  DELETE: 60,
  CHANGE: 65,
  SKIPSTEP: 70,
  OTHER: 100,
};

export default nominationAction;
