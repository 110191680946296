import React from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from 'primereact/inputtextarea';
import { Skeleton } from 'primereact/skeleton';

function NominationCitation({
  value,
  readOnly,
  onChange,
  isLoadingInProgress,
  isCitationDirty,
  isCitationSaved,
  isBeingAutoSaved,
}) {
  return (
    <div className="row">
      <div className="form-group col-12">
        {/* <h6 className="mt-0">Citation (space limited to 500 characters, including spaces)</h6> */}
        {isLoadingInProgress && <Skeleton className="p-mb-2" height="4rem" />}
        {!isLoadingInProgress && (
          <InputTextarea
            className="form-control"
            rows={2}
            value={value}
            onChange={(evt) => onChange(evt.target.value)}
            readOnly={readOnly}
            maxLength="500"
          />
        )}
        {isCitationDirty && isBeingAutoSaved ? (
          <small className="text-primary p-2">
            <span className="spinner-border spinner-border-sm" /> saving
          </small>
        ) : (
          isCitationSaved && (
            <small className="text-primary p-2">
              <span> saved</span>{' '}
            </small>
          )
        )}
      </div>
    </div>
  );
}
NominationCitation.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoadingInProgress: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isCitationDirty: PropTypes.bool.isRequired,
  isCitationSaved: PropTypes.bool.isRequired,
  isBeingAutoSaved: PropTypes.bool.isRequired,
};
export default NominationCitation;
