import useApi from '../useApi';

export const useVotingService = () => {
  const api = useApi();

  const getNominations = async (requestData) => api.post('/voting/GetNominations', requestData);

  const getBureauNominations = async (requestData) => api.post('/voting/GetBureauNominations', requestData);

  const getVotingHeader = async (requestData) => api.post('/voting/GetVotingHeader', requestData);

  const vote = async (requestData) => api.post('/voting/vote', requestData);

  const setDecision = async (requestData) => api.post('/voting/SetDecision', requestData);

  const getNominationDetails = async (requestData) => api.post('/voting/GetNominationDetails', requestData);

  const signOffNominations = async (requestData) => api.post('/voting/SignOffNominations', requestData);

  const getVoters = async (id) => api.get(`/voting/GetVoters/${id}`);

  const getBureauVoters = async (id) => api.get(`/voting/GetBureauVoters/${id}`);

  const sendBureauNominationToSignOff = async (requestData) =>
    api.post('/voting/SetBureauNominationForSignOff', requestData);

  return {
    getNominations,
    getBureauNominations,
    getVotingHeader,
    vote,
    setDecision,
    getNominationDetails,
    signOffNominations,
    getVoters,
    getBureauVoters,
    sendBureauNominationToSignOff,
  };
};

export default useVotingService;
