import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'primereact/button';

function MessageModal({ isModalOpened, onClickCloseModal, header, message, isInnerHTML }) {
  return (
    <Modal centered scrollable className="mh-100" isOpen={isModalOpened} toggle={onClickCloseModal} size="md">
      <ModalHeader toggle={onClickCloseModal}>{header}</ModalHeader>
      {isInnerHTML ? <ModalBody dangerouslySetInnerHTML={{ __html: message }} /> : <ModalBody>{message}</ModalBody>}

      <ModalFooter>
        <Button
          label="Ok"
          icon="pi pi-check-circle"
          onClick={() => onClickCloseModal()}
          className="p-button-primary w-100px"
        />
      </ModalFooter>
    </Modal>
  );
}

MessageModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  onClickCloseModal: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isInnerHTML: PropTypes.bool,
};

MessageModal.defaultProps = {
  isInnerHTML: false,
};

export default MessageModal;
