import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'primereact/button';
import { UserSearch } from 'components';
import { SessionContext, NotificationContext } from 'contexts';
import { useEmployeeService } from 'hooks/admin';
import { useAuthorization, useNavigationLinks, useEmployeeSearchService } from 'hooks';
import { Dropdown } from 'primereact/dropdown';
import { roleType } from 'constants/index';

function AssignProxy() {
  const [selectedAssignUser, setSelectedAssignUser] = useState({});
  const [selectedProxyUser, setSelectedProxyUser] = useState({});
  const [currentProxyUser, setCurrentProxyUser] = useState({});
  const [selectedProxyRole, setSelectedProxyRole] = useState();
  const [isCurrentProxyUserSelected, setIsCurrentProxyUserSelected] = useState(false);
  const { sessionStore, sessionHelpers } = useContext(SessionContext);
  const { showError, showSuccess } = useContext(NotificationContext);
  const employeeService = useEmployeeService();
  const employeeSearchService = useEmployeeSearchService();
  const { checkRole } = useAuthorization();
  const { goTo } = useNavigationLinks();

  const proxyRoles = [
    { key: roleType.ExecOfficer, name: 'EXEC Officer' },
    { key: roleType.JCACChair, name: 'JCAC Chair' },
  ];

  const assignProxy = async () => {
    if (selectedProxyUser.employeeId === sessionStore.employeeUser.employeeId) {
      showError('You cannot proxy yourself!');
      return;
    }
    const assignData = {
      assignId: selectedAssignUser.employeeId,
      proxyId: selectedProxyUser.employeeId,
      proxyRole: selectedProxyRole,
    };
    const response = await employeeService.assignProxy(assignData);
    if (response.ok) {
      showSuccess('You have successfully assigned a proxy user.');
      setCurrentProxyUser(selectedProxyUser);
    }
    // await sessionHelpers.initSession();
  };

  const removeProxy = async () => {
    const response = await employeeService.removeProxy(selectedAssignUser.employeeId);
    if (response.ok) {
      showSuccess('You have successfully removed your proxy user.');
      setCurrentProxyUser({});
      setSelectedProxyUser({});
    }
    await sessionHelpers.initSession();
  };

  function renderData(label, data) {
    return (
      <>
        <div className="row mt-3">
          <div className="col">{label}</div>
        </div>
        <div className="row">
          <div className="col">
            {!data && <span>&nbsp;</span>}
            {data && <strong>{data}</strong>}
          </div>
        </div>
      </>
    );
  }

  function renderSelectedUser(selectedUser) {
    return (
      <>
        {renderData('Name', selectedUser?.name)}
        {renderData('Email', selectedUser?.email)}
        {renderData('Post', selectedUser?.postName)}
        {renderData('Section', selectedUser?.sectionName)}
        {renderData('Position Title', selectedUser?.positionTitle)}
      </>
    );
  }

  useEffect(() => {
    async function searchProxyUser() {
      const response = await employeeSearchService.search({
        missionId: selectedAssignUser?.missionId || 0,
        postId: 0,
        sectionId: 0,
        nameTerm: selectedAssignUser?.proxyEmployeeName,
        emailTerm: '',
        positionTerm: '',
        isArchived: false,
      });
      let result = null;
      if (response && response.ok) {
        result = response.data;
      }
      return result[0];
    }
    if (
      selectedAssignUser &&
      selectedAssignUser?.proxyEmployeeId > 0 &&
      !currentProxyUser?.name &&
      !isCurrentProxyUserSelected
    ) {
      searchProxyUser().then((result) => {
        setCurrentProxyUser(result);
        setIsCurrentProxyUserSelected(true);
      });
    }

    if (currentProxyUser?.employeeId !== selectedAssignUser?.proxyEmployeeId && isCurrentProxyUserSelected) {
      setCurrentProxyUser({});
      setIsCurrentProxyUserSelected(false);
    }
  }, [
    selectedAssignUser,
    employeeSearchService,
    currentProxyUser?.name,
    isCurrentProxyUserSelected,
    currentProxyUser?.employeeId,
  ]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  return (
    <>
      <h4>Assign Proxy by Mission</h4>
      <h5>Assigned User</h5>
      <div className="row">
        <div className="col-12">Find the user to be assigned</div>
        <div className="col-12">
          <UserSearch
            value={selectedAssignUser}
            setValue={(user) => {
              setCurrentProxyUser({});
              setSelectedProxyUser({});
              setSelectedAssignUser(user);
            }}
            className="max-w-500px"
          />
        </div>
      </div>
      {renderSelectedUser(selectedAssignUser)}
      <br />
      <h5>Proxy User</h5>
      {currentProxyUser?.name ? (
        <div className="row">
          <div className="col-12">You have assigned following user as proxy.</div>
          <div className="col-12">{renderSelectedUser(currentProxyUser)}</div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-12">Find the user you want to assign as proxy</div>
            <div className="col-12">
              <UserSearch
                value={selectedProxyUser}
                setValue={(user) => {
                  setCurrentProxyUser({});
                  setSelectedProxyUser(user);
                }}
                className="max-w-500px"
              />
            </div>
          </div>
          {renderSelectedUser(selectedProxyUser)}
          <div className="row">
            <div className="form-group col-3">
              Proxy Role
              <Dropdown
                value={selectedProxyRole}
                options={proxyRoles}
                optionLabel="name"
                optionValue="key"
                className="w-100"
                onChange={(e) => setSelectedProxyRole(e.target.value)}
              />
            </div>
          </div>
        </>
      )}
      {!currentProxyUser?.name ? (
        <div className="row">
          <div className="form-group col-6">
            <Button
              label="Assign Proxy"
              icon="pi pi-save"
              className="p-button-primary mr-1"
              onClick={() => assignProxy(true)}
              disabled={!selectedProxyUser.email}
            />
          </div>
        </div>
      ) : (
        <Button
          label="Remove Proxy"
          className="p-button-danger"
          icon="pi pi-times-circle"
          onClick={() => removeProxy()}
          disabled={!selectedAssignUser?.email}
        />
      )}
    </>
  );
}

export default React.memo(AssignProxy);
