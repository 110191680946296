import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';

import Layout from './Layout';
import './ErrorPage.css';

function ErrorPage() {
  const history = useHistory();

  const goToHome = () => history.push('/Home');
  return (
    <Layout>
      <div id="errorPageDiv">
        <div className="error-page">
          <div className="text-right">
            <FontAwesomeIcon icon={faLockAlt} fixedWidth size="5x" />
          </div>
          <div>
            <h4 className="">NOT AUTHORIZED AWARDS SYSTEM</h4>
            <div className="pb-4">
              <p>Welcome to the Overseas Awards System (OAS).</p>
              <p>
                Your account is not configured to access the application right now. Please contact your HR office at
                post.
              </p>
            </div>
            <button type="button" onClick={goToHome} className="btn btn-primary">
              Go To Home
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default React.memo(ErrorPage);
