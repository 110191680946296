import React, { useEffect, useContext, useReducer, useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { faIdBadge } from '@fortawesome/pro-solid-svg-icons';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { formatDate } from 'utils/dateHelpers';
import { ActionButton, Grid, SkeletonTable } from 'components';
import { SessionContext } from 'contexts';
import { useEmployeeService } from 'hooks/admin';
import { useEmployeeSearchService, useNavigationLinks, useQuery, useAuthorization } from 'hooks';
import { dynamicSort } from 'utils/sorting';
import { requestStatus, employeeType } from 'constants/index';

import './ArchivedEmployee.css';

const LSKEY = 'archivedEmployeeResult';

const initialState = {
  availableParamsRequest: requestStatus.REQUEST_NOT_INITIATED,
  availableParams: {
    loaded: false,
    bureaus: [],
    missions: [],
    posts: [],
    sections: [],
    missionsAndBureaus: [],
  },
  searchParams: {
    isLocalStaff: false,
    nameTerm: '',
    emailTerm: '',
    positionTerm: '',
    isArchived: true,
  },
  employeeTypeFilter: employeeType.ALL,
  searchResult: [],
  listSuperviseesResult: [],
  selectedRecords: [],
  searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
  listSuperviseesStatus: requestStatus.REQUEST_NOT_INITIATED,
  saveSupervisorRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_AVAILABLE_PARAMS':
      return {
        ...state,
        availableParams: { ...state.availableParams, ...action.data },
      };

    case 'START_REQUEST_SEARCH_PARAMS':
      return {
        ...state,
        availableParamsRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'UPDATE_FILTER':
      return { ...state, employeeTypeFilter: action.data };

    case 'UPDATE_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: { ...state.searchParams, ...action.data },
        availableParamsRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_SEARCH_RESULT':
      return {
        ...state,
        searchResult: action.data,
        searchRequestStatus: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_LIST_SUPERVISEES_RESULT':
      return {
        ...state,
        listSuperviseesResult: action.data,
        listSuperviseesStatus: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_SELECTED_RECORDS':
      return { ...state, selectedRecords: action.data };

    case 'START_SAVE_SUPERVISOR_REQUEST':
      return {
        ...state,
        saveSupervisorRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'COMPLETE_SAVE_SUPERVISOR_REQUEST':
      return {
        ...state,
        saveSupervisorRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'RESET_SELECTED_RECORDS':
      return {
        ...state,
        selectedRecords: initialState.selectedRecords,
      };

    case 'START_SEARCH_REQUEST':
      return {
        ...state,
        searchRequestStatus: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'RESET_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: initialState.searchParams,
        searchResult: initialState.searchResult,
        searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
        employeeTypeFilter: initialState.employeeTypeFilter,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const getMissionsAndBureausList = (bureaus, missions) => {
  const result = missions.map((item) => {
    const bureauName = bureaus.find((elem) => elem.key === item.bureauId).value;
    return { key: item.id, value: `${bureauName} - ${item.name}` };
  });
  return result.sort(dynamicSort('value'));
};

function ArchivedEmployee() {
  const { checkRole } = useAuthorization();
  const { goTo } = useNavigationLinks();
  const { sessionStore } = useContext(SessionContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const service = useEmployeeSearchService();
  const employeeService = useEmployeeService();
  const query = useQuery();
  const { currentMissionId } = sessionStore;

  const setParamValue = (paramValue) => {
    dispatch({
      type: 'UPDATE_SEARCH_PARAMS',
      data: { ...paramValue },
    });
  };

  const getTitle = () => {
    return 'Mission Admin - Archived Employee';
  };

  const persistResultList = useCallback(() => {
    sessionStorage.setItem(LSKEY, JSON.stringify(state.searchResult));
  }, [state.searchResult]);

  function onResetClick() {
    dispatch({
      type: 'RESET_SEARCH_PARAMS',
    });
    dispatch({
      type: 'RESET_SELECTED_RECORDS',
    });
    dispatch({
      type: 'UPDATE_SEARCH_PARAMS',
      data: {
        selectedMissionId: currentMissionId,
      },
    });
  }

  async function onClickOpenSuperviseesList(id) {
    const response = await employeeService.getEmployeesBySupervisorId(id);
    if (response && response.ok) {
      dispatch({
        type: 'UPDATE_LIST_SUPERVISEES_RESULT',
        data: response.data,
      });
    }
    return goTo('employees_by_supervisor', { id });
  }

  const actionTemplate = (rowData) => {
    const actionsBuffer = [];

    actionsBuffer.push(
      <ActionButton
        key={`edit_${rowData.employeeId}`}
        tooltip="Edit Employee"
        className="p-button-text p-button-plain px-2"
        icon={['far', 'edit']}
        onClick={() => {
          persistResultList();
          goTo('employee_edit', { id: rowData.employeeId });
        }}
      />
    );

    if (rowData.isSupervisor) {
      actionsBuffer.push(
        <ActionButton
          key={`sup_by_${rowData.employeeId}`}
          className="p-button-text p-button-plain px-2"
          tooltip={`Employees supervised by ${rowData.name}`}
          icon={['far', 'user-cog']}
          onClick={() => onClickOpenSuperviseesList(rowData.employeeId)}
        />
      );
    }
    return <div>{actionsBuffer}</div>;
  };

  function autoOpenPostsDropdown(length) {
    if (length > 1) {
      document.getElementById('searchposts').click();
    }
  }

  const nameWithBadgeTemplate = (rowData) => {
    let colorClass = 'badge-les';
    let titleDesc = 'Local Staff';
    if (!rowData.isLocalStaff) {
      colorClass = 'badge-usdh';
      titleDesc = 'USDH';
    }
    return (
      <>
        <i className={colorClass} title={titleDesc}>
          <FontAwesomeIcon size="lg" icon={faIdBadge} fixedWidth />
        </i>
        <span>{rowData.name}</span>
      </>
    );
  };

  const archiveDateTemplate = (rowData) => {
    console.log('rowData', rowData);
    return formatDate(rowData.archivedDate);
  };

  const setSelectedRecords = (selectedRecords) => {
    dispatch({
      type: 'UPDATE_SELECTED_RECORDS',
      data: selectedRecords,
    });
  };

  async function onSearchClick() {
    const searchRequestData = {
      missionId: currentMissionId,
      postId: state.searchParams.selectedPostId,
      nameTerm: state.searchParams.nameTerm,
      isArchived: true,
    };
    dispatch({ type: 'START_SEARCH_REQUEST' });
    const response = await service.search(searchRequestData);
    if (response && response.ok) {
      const employeeResultList = response.data.map((item) => ({
        ...item,
        localStaff: `${item.isLocalStaff ? 'Yes' : 'No'}`,
        assignment: `${item.sectionName} @ ${item.postName}`,
      }));
      dispatch({ type: 'UPDATE_SEARCH_RESULT', data: employeeResultList });
    }
  }

  async function loadPosts(selectedMissionId) {
    const response = await service.getAvailablePostsByMission(selectedMissionId);
    if (response && response.ok) {
      dispatch({
        type: 'UPDATE_AVAILABLE_PARAMS',
        data: {
          posts: response.data,
        },
      });
      dispatch({
        type: 'UPDATE_SEARCH_PARAMS',
        data: {
          selectedPostId: response.data[0].key,
        },
      });
    }
    autoOpenPostsDropdown(response.data.length);
  }

  useEffect(() => {
    onSearchClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (query.get('restoreResult') === 'yes') {
      dispatch({
        type: 'UPDATE_SEARCH_RESULT',
        data: JSON.parse(sessionStorage.getItem(LSKEY)),
      });
      goTo('employee_search');
    } else if (query.get('removeEmployee')) {
      const results = JSON.parse(sessionStorage.getItem(LSKEY));
      const newArr = results.filter((item) => item.employeeId !== parseInt(query.get('removeEmployee'), 10));
      dispatch({ type: 'UPDATE_SEARCH_RESULT', data: newArr });
    } else {
      persistResultList();
    }
  }, [goTo, persistResultList, query]);

  useEffect(() => {
    async function loadSearchParameters() {
      dispatch({ type: 'START_REQUEST_SEARCH_PARAMS' });
      const response = await service.getSearchAvailableParameters(currentMissionId);
      if (response && response.ok) {
        dispatch({
          type: 'UPDATE_AVAILABLE_PARAMS',
          data: {
            bureaus: response.data.bureaus,
            sections: response.data.sections,
            missions: currentMissionId,
            posts: response.data.posts,
            missionsAndBureaus: getMissionsAndBureausList(response.data.bureaus, response.data.missions),
          },
        });
        dispatch({
          type: 'UPDATE_SEARCH_PARAMS',
          data: {
            selectedMissionId: currentMissionId,
            selectedEmployee: response.data.isLocalStaff,
            selectedEmployeeId: response.data.employeeId,
          },
        });
      }
    }

    if (state.availableParamsRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadSearchParameters();
    }
  }, [service, state.availableParamsRequest, state.searchParams.employeeId, employeeService, currentMissionId]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  const filteredResult = useCallback(
    () =>
      state.searchResult.filter((item) => {
        if (state.employeeTypeFilter === employeeType.ALL) {
          return true;
        }
        return item.isLocalStaff === (state.employeeTypeFilter === employeeType.LESTAFF);
      }),
    [state.employeeTypeFilter, state.searchResult]
  );

  return (
    <>
      <h4>{getTitle()}</h4>
      <Container fluid className="min-h-400px archived-employee max-h-90">
        <Row>
          <Col lg="3" md="3" xs="6" className="mt-1">
            Mission
            <div>
              <Dropdown
                readOnly
                disabled
                value={currentMissionId}
                options={state.availableParams.missionsAndBureaus}
                optionLabel="value"
                optionValue="key"
                filterBy="value"
                filter
                className="w-100"
                onChange={(e) => {
                  setParamValue({ selectedMissionId: e.value });
                  loadPosts(e.value);
                }}
              />
            </div>
          </Col>
          <Col lg="3" md="3" xs="6" className="mt-1">
            Post
            <div>
              <Dropdown
                id="searchposts"
                value={state.searchParams.selectedPostId}
                options={state.availableParams.posts}
                optionLabel="value"
                optionValue="key"
                className="w-100"
                showClear
                onChange={(e) => setParamValue({ selectedPostId: e.value })}
              />
            </div>
          </Col>
          <Col lg="2" md="3" xs="6" className="mt-1">
            <span className="text-nowrap">Employee&apos;s Name</span>
            <InputText
              value={state.searchParams.nameTerm}
              onChange={(e) => setParamValue({ nameTerm: e.target.value })}
              className="w-100"
            />
          </Col>
          <Col lg="2" md="6" xs="6" className="mt-1">
            <div className="text-right">
              <Button
                label="Search"
                icon="pi pi-search"
                className="p-button-primary w-100 min-w-100px"
                onClick={() => onSearchClick()}
              />
            </div>
            <div className="text-right mt-1">
              <Button
                label="Reset"
                icon="pi pi-refresh"
                className="p-button-primary p-button-xs p-button-outlined w-100 min-w-100px"
                onClick={() => onResetClick()}
              />
            </div>
          </Col>
          <Col lg="2" md="6" xs="6" className="mt-1">
            <div className="text-right mt-1 text-nowrap">
              <FontAwesomeIcon className="mb-1 mr-1 color-primary" size="lg" icon={faFilter} fixedWidth />
              <span className="p-buttonset">
                <Button
                  type="button"
                  label="All"
                  className={`p-button-xs p-button-primary ${
                    state.employeeTypeFilter === employeeType.ALL ? '' : 'p-button-outlined'
                  }`}
                  onClick={() => dispatch({ type: 'UPDATE_FILTER', data: employeeType.ALL })}
                />
                <Button
                  type="button"
                  label="LES"
                  className={`p-button-xs p-button-primary ${
                    state.employeeTypeFilter === employeeType.LESTAFF ? '' : 'p-button-outlined'
                  }`}
                  onClick={() =>
                    dispatch({
                      type: 'UPDATE_FILTER',
                      data: employeeType.LESTAFF,
                    })
                  }
                />

                <Button
                  type="button"
                  label="USDH"
                  className={`p-button-xs p-button-primary ${
                    state.employeeTypeFilter === employeeType.USDH ? '' : 'p-button-outlined'
                  }`}
                  onClick={() => dispatch({ type: 'UPDATE_FILTER', data: employeeType.USDH })}
                />
              </span>
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            {state.searchRequestStatus === requestStatus.REQUEST_IN_PROGRESS && (
              <SkeletonTable colsSize={6} rowsSize={6} />
            )}
            {state.searchRequestStatus === requestStatus.REQUEST_COMPLETED && (
              <Grid
                items={filteredResult()}
                selectedRecords={state.selectedRecords}
                onSelectionChange={(e) => setSelectedRecords(e.value)}
                dataKey="employeeId"
                sortMode="single"
              >
                <Column field="name" header="Name" body={nameWithBadgeTemplate} sortable />
                <Column field="archivedDate" header="Archived Date" body={archiveDateTemplate} sortable />
                <Column field="roles" header="Roles" sortable />
                <Column header="Action" body={actionTemplate} />
              </Grid>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ArchivedEmployee;
