import React, { memo } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import DashboardButton from './DashboardButton';

function RoleDashboard({ postDashboardOptions }) {
  const { role, employeePostActions } = postDashboardOptions;
  return (
    <Card className="mb-4">
      <CardHeader>
        <h5>{role}</h5>
      </CardHeader>
      <CardBody>
        <div>
          {employeePostActions?.map((postOpt) => (
            <DashboardButton dashboardOptions={postOpt} key={uuid()} />
          ))}
        </div>
      </CardBody>
    </Card>
  );
}

RoleDashboard.propTypes = {
  postDashboardOptions: PropTypes.object,
};

RoleDashboard.defaultProps = {
  postDashboardOptions: {},
};

export default memo(RoleDashboard);
