import useApi from '../useApi';

export const useCycleService = () => {
  const api = useApi();

  const getListByMission = async (missionId, listType, searchEmployeeId) => {
    if (!missionId) {
      return null;
    }
    return api.get(`/cycle/GetListByMission/${missionId}/${listType}/${searchEmployeeId}`);
  };

  const getPostsByMission = async (missionId) => {
    if (!missionId) {
      console.log('missionId is null');
      return null;
    }
    return api.get(`/cycle/GetPostsByMission/${missionId}`);
  };

  const load = async (cycleId) => {
    if (!cycleId) {
      console.log('missionId is null');
      return null;
    }
    return api.get(`/cycle/LoadCycleSettings/${cycleId}`);
  };

  const save = async (data) => api.post('/cycle/Save', data);

  return {
    getListByMission,
    getPostsByMission,
    load,
    save,
  };
};

export default useCycleService;
