import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faBan,
  faFlagAlt,
  faThumbsUp,
  faThumbsDown,
  faClock,
  faHandPaper,
  faUserSlash,
  faArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import uniqBy from 'lodash/uniqBy';
import { useAuthorization } from 'hooks';
import { Dropdown } from 'primereact/dropdown';
import { ActionButton, SkeletonTable } from 'components';
import { requestStatus, votingStatus } from 'constants/index';
import Loading from 'views/Shared/Loading';
import MessageModal from 'components/MessageModal';
import VotingNominationDetails from './VotingNominationDetails';
import VotingNominationItem from './VotingNominationItem';
import VotingButtons from './VotingButtons';
import DecisionButtons from './DecisionButtons';
import VoteComment from './VoteComment';
import ExecSignOffModal from '../SignOff/ExecSignOffModal';

const statusOptions = [
  { value: 'all', text: 'All', colorClass: 'text-secondary', icon: faFlagAlt },
  { value: 'approved', text: 'Approved', colorClass: 'text-success', icon: faThumbsUp },
  { value: 'disapproved', text: 'Disapproved', colorClass: 'text-danger', icon: faThumbsDown },
  { value: 'downgraded', text: 'Downgraded', colorClass: 'text-danger', icon: faArrowDown },
  { value: 'not_voted', text: 'Not Voted', colorClass: 'text-danger', icon: faClock },
  { value: 'on_hold', text: 'On Hold', colorClass: 'text-danger', icon: faHandPaper },
  { value: 'recused', text: 'Recused', colorClass: 'text-danger', icon: faUserSlash },
];

const statusOptionTemplate = (option) => {
  return (
    <div>
      <FontAwesomeIcon icon={option.icon} className={option.colorClass} />
      <span className="ml-2">{option.text}</span>
    </div>
  );
};

const getTypesFromNominationsList = (nominations) => {
  const typesList = nominations.map((item) => ({ value: item.awardTypeId, text: item.awardTypeName }));
  typesList.unshift({ value: 0, text: 'All' });
  return uniqBy(typesList, 'value');
};

function VotingNominationList({ formStore, helperFunctions, onClickCheck, onClickVote, onClickDecision }) {
  const isProcessingVote = formStore.voteRequest === requestStatus.REQUEST_IN_PROGRESS;
  const { checkCyclePermission } = useAuthorization();
  const [showDecisionButtons, setShowDecisionButtons] = useState(true);

  useEffect(() => {
    const isBureau = helperFunctions.isBureauVoting();
    if (formStore.currentCycleId) {
      const chairRole = isBureau ? 'can_signoff_bureau' : 'jcac_chair';
      const doesUserHaveAccess = formStore.currentCycleId && checkCyclePermission(chairRole, formStore.currentCycleId);
      setShowDecisionButtons(doesUserHaveAccess);
    }
  }, [formStore.currentCycleId, checkCyclePermission, helperFunctions]);

  return (
    <>
      {helperFunctions.isPendingMode() && (
        <>
          <div className="row">
            <div className="col-3 text-center">Filter by Vote Result</div>
            <div className="col-3 text-center">Filter by Award Type</div>
          </div>

          <div className="row">
            <div className="col-3 text-center">
              <Dropdown
                value={formStore.filterSummary}
                options={statusOptions}
                onChange={(e) => helperFunctions.onClickSummaryFilter(e.value)}
                valueTemplate={statusOptionTemplate}
                itemTemplate={statusOptionTemplate}
                optionValue="value"
                optionLabel="text"
                className="w-100"
              />
            </div>
            <div className="col-3 text-center">
              <Dropdown
                value={formStore.filterType}
                options={getTypesFromNominationsList(formStore.loadedNominations)}
                onChange={(e) => helperFunctions.onClickTypeFilter(e.value)}
                optionValue="value"
                optionLabel="text"
                className="w-100"
              />
            </div>
          </div>
        </>
      )}
      {formStore.filteredNominations.length > 0 && (
        <div className="mt-3 mx-2">
          <div className="row">
            <div className="col-2 font-weight-bold">
              <span className="pl-4">ID</span>
              {helperFunctions.isSignOffMode() && (
                <ActionButton
                  tooltip="Only Approved or Rejected nominations can be selected for batch sign off"
                  className="p-button-text p-button-plain px-2"
                  icon={['far', 'question-circle']}
                />
              )}
            </div>
            <div className="col-2 font-weight-bold text-center">Type</div>
            <div className="col-2 font-weight-bold text-center">Amount</div>
            <div className="col-3 font-weight-bold">
              Nominees
              <ActionButton
                tooltip="USDH is not eligible for cash or time off, only a certificate will be issued"
                className="p-button-text p-button-plain px-2"
                icon={['far', 'question-circle']}
              />
            </div>
            <div className="col-3 font-weight-bold text-center">
              Votes Summary
              {!helperFunctions.isSignOffMode() && (
                <ActionButton
                  tooltip="Voting summary will only be available after you cast your vote"
                  className="p-button-text p-button-plain px-2"
                  icon={['far', 'question-circle']}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {formStore.loadListRequest === requestStatus.REQUEST_COMPLETED && !formStore.filteredNominations.length && (
        <div className="container mt-3">
          <div className="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
            <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="mr-2" />
            No nomination match the filter configurations, please check your filters.
          </div>
        </div>
      )}

      {formStore.loadListRequest === requestStatus.REQUEST_IN_PROGRESS && <SkeletonTable rowsSize={5} colsSize={6} />}

      <Accordion
        multiple
        className="mt-2"
        onTabOpen={(e) => {
          helperFunctions.toggleAccordionActiveIndex(e.index);
          return helperFunctions.onClickItem(e.index);
        }}
        onTabClose={(e) => helperFunctions.toggleAccordionActiveIndex(e.index)}
        onTabChange={(e) => helperFunctions.setAccordionActiveIndexes(e.index)}
        activeIndex={formStore.accordionActiveIndexes}
      >
        {formStore.filteredNominations.map((item) => (
          <AccordionTab
            key={`_accordion_${item.id}`}
            className="border-top"
            headerClassName="w-100"
            header={
              <VotingNominationItem
                nomination={item}
                showCheckbox={
                  (helperFunctions.isSignOffMode() && item.bureauVotingStatus !== votingStatus.PENDING) ||
                  item.showCheckBox
                }
                onClickCheck={onClickCheck}
                helperFunctions={helperFunctions}
              />
            }
          >
            <Loading
              local
              active={!item.detailsLoaded || (isProcessingVote && formStore.requestNominationId === item.id)}
              type="ring"
              color="gray"
            />
            <div className="row">
              <div className={`row anim-transition ${item.showCommentBox ? 'col-10' : 'col-12'}`}>
                <div className="col-10">
                  <VotingNominationDetails
                    nomination={item}
                    helperFunctions={helperFunctions}
                    onClickMoreDetails={helperFunctions.onClickMoreDetails}
                  />
                </div>
                <div className="col-2 d-flex flex-column justify-content-center">
                  {!helperFunctions.isVotingOpen() && (
                    <div className="alert alert-danger text-center" role="alert">
                      <FontAwesomeIcon icon={faBan} size="2x" style={{ color: 'red', height: 20 }} />
                      Voting window closed
                    </div>
                  )}
                  {helperFunctions.isVotingOpen() && !helperFunctions.isSignOffMode() && (
                    <VotingButtons
                      nomination={item}
                      helperFunctions={helperFunctions}
                      onClickVote={onClickVote}
                      canUserVote={helperFunctions.canUserVote()}
                    />
                  )}
                  {helperFunctions.isVotingOpen() && helperFunctions.isSignOffMode() && showDecisionButtons && (
                    <DecisionButtons
                      nomination={item}
                      helperFunctions={helperFunctions}
                      onClickDecision={onClickDecision}
                    />
                  )}
                  {formStore.isChangeModalOpen && item && (
                    <ExecSignOffModal
                      isModalOpened={formStore.isChangeModalOpen}
                      onClickClose={helperFunctions.onCloseChangeModalClick}
                      formStore={formStore}
                      currentNomination={item}
                      onUpdateChanges={helperFunctions.onSaveNominationChanges}
                    />
                  )}
                  {formStore.isMessageModalOpen && (
                    <MessageModal
                      isModalOpened={formStore.isMessageModalOpen}
                      onClickCloseModal={helperFunctions.onCloseMessageModalClick}
                      header="Changes Confirmation"
                      message="To confirm that this change was approved by the JCAC members, select `Save` or `Save & Sign Off`"
                    />
                  )}
                </div>
              </div>
              <div className={`col box-outer ${item.showCommentBox ? 'd-block' : 'd-none'}`}>
                <VoteComment
                  lastComment={item.lastComment}
                  newComment={item.newComment}
                  voteCreatedDate={item.voteCreatedDate}
                  isVoteDateVisible={!helperFunctions.isSignOffMode()}
                  onChangeNewComment={(value) => helperFunctions.onChangeNewComment(item.id, value)}
                />
              </div>
            </div>
          </AccordionTab>
        ))}
      </Accordion>
    </>
  );
}
VotingNominationList.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  onClickCheck: PropTypes.func.isRequired,
  onClickVote: PropTypes.func.isRequired,
  onClickDecision: PropTypes.func.isRequired,
  onClickTypeFilter: PropTypes.func.isRequired,
};

export default VotingNominationList;
