import {
  AlignmentType,
  Paragraph,
  TextRun,
  ImageRun,
  PageOrientation,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  WidthType,
  BorderStyle,
} from 'docx';

const borders = {
  top: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
  bottom: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
  left: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
  right: { style: BorderStyle.NIL, size: 0, color: 'FFFFFF' },
};

const generateRows = (columns) => {
  let nomineesTable;
  if (columns.length === 3) {
    nomineesTable = columns[0].map(
      (row, index) =>
        new TableRow({
          children: [
            new TableCell({
              borders,
              children: [
                new Paragraph({
                  spacing: { line: 196 },

                  alignment: AlignmentType.CENTER,
                  children: [new TextRun({ italics: true, text: row, size: 22, font: 'Garamond' })],
                }),
              ],
            }),

            new TableCell({
              borders,
              children: [
                new Paragraph({
                  spacing: { line: 196 },

                  alignment: AlignmentType.CENTER,
                  children: [new TextRun({ italics: true, text: columns[1][index], size: 22, font: 'Garamond' })],
                }),
              ],
            }),
            new TableCell({
              borders,
              children: [
                new Paragraph({
                  spacing: { line: 196 },

                  alignment: AlignmentType.CENTER,
                  children: [new TextRun({ italics: true, text: columns[2][index], size: 22, font: 'Garamond' })],
                }),
              ],
            }),
          ],
        })
    );
  }
  return nomineesTable;
};

function LandscapeCertificate({ mission, awardType, nomineesList, citation, footertable, sealUrl, columns }) {
  const renderNominees = () => {
    if (nomineesList.length === 1) {
      return new Paragraph({
        spacing: { line: 180, before: 1, after: 170 },

        alignment: AlignmentType.CENTER,
        children: [new TextRun({ break: 1, italics: true, text: nomineesList[0], size: 88, font: 'Garamond' })],
      });
    }

    return new Table({
      rows: [...generateRows(columns, nomineesList)],

      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      layout: TableLayoutType.FIXED,
    });
  };
  return {
    properties: {
      page: {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
      },
    },
    children: [
      new Paragraph({
        // spacing: { line: 27, before: 640, after: 20 },
        children: [
          sealUrl
            ? new ImageRun({
                data: sealUrl,
                transformation: {
                  width: 109,
                  height: 111,
                },
                floating: {
                  zIndex: 5,
                  horizontalPosition: {
                    relative: HorizontalPositionRelativeFrom.PAGE,
                    align: HorizontalPositionAlign.CENTER,
                  },
                  verticalPosition: {
                    relative: VerticalPositionRelativeFrom.PAGE,
                    //   align: VerticalPositionAlign.TOP,
                    offset: 164400,
                  },
                },
              })
            : [],
        ],
      }),
      new Paragraph({
        spacing: { before: 190 },

        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({ break: 1, text: mission, size: 20 }),
          new TextRun({ break: 1, text: awardType, size: 58, font: 'Times' }),
        ],
      }),

      new Paragraph({
        spacing: { line: 220, before: 1, after: 440 },

        alignment: AlignmentType.CENTER,
        children: [new TextRun({ break: 1, text: 'This certificate is presented to', size: 26, font: 'Arial' })],
      }),

      renderNominees(),
      new Paragraph({
        spacing: { line: 176, before: 70, after: 200 },

        alignment: AlignmentType.CENTER,
        children: [new TextRun({ break: 1, text: citation, size: 24, font: 'Arial' })],
      }),
      footertable,
    ],
  };
}

export default LandscapeCertificate;
