/* eslint-disable react/jsx-key */
import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DashboardButton({ dashboardOptions }) {
  const type = 'primary';

  return (
    <div>
      <h5>{dashboardOptions.postName}</h5>
      <div className="row">
        {dashboardOptions.employeeActions?.map((action) => (
          <div className="col-3">
            <Link
              className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
              to={action.navigateTo ?? '/Home'}
              style={{ textDecoration: 'none' }}
            >
              <div
                className="media shadow-sm rounded-lg p-3"
                style={{
                  minHeight: '110px',
                  // width: props.width,
                  borderTop: '1px solid #dee2e6',
                  borderBottom: '1px solid #dee2e6',
                  borderRight: '1px solid #dee2e6',
                  borderLeft: `.25rem solid var(--${type})`,
                  marginBottom: '15px',
                }}
              >
                <div className="media-body">
                  <h5 className={`mt-0 mb-1 font-weight-bold text-${type} text-uppercase`}>
                    {action.nominationStatus}
                  </h5>
                  <p>
                    <span>{action.awardProcessType?.value && `(${action.awardProcessType?.value})`}</span>
                    {action.cycle?.value && <span> - {action.cycle?.value}</span>}
                  </p>
                  <p>({`${action.totalCount} Pending`})</p>
                </div>
                <FontAwesomeIcon
                  className="ml-3 text-light‑gray align-self-start"
                  icon={['far', action.icon]}
                  size="2x"
                />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

DashboardButton.propTypes = {
  dashboardOptions: PropTypes.object,
};

DashboardButton.defaultProps = {
  dashboardOptions: {},
};

export default memo(DashboardButton);
