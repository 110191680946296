/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback, useReducer } from 'react';
import 'primereact/resources/primereact.css';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { formOfRecognition as formOfRecognitionTypes, votingStatus, requestStatus, processType } from 'constants/index';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useParams } from 'react-router';
import { awardTypeFormReducer, initAwardTypesForm } from 'reducers';
import { useAwardTypeService } from 'hooks/admin';

function ExecSignOffModal({
  isModalOpened,
  onClickClose,
  formStore,
  currentNomination,
  onUpdateChanges,
  selectedNominations,
}) {
  const { mode } = useParams();
  const isJcacSignOffMode = useCallback(() => mode.toLowerCase() === 'signoff', [mode]);
  const isExecSignOffMode = useCallback(() => mode.toLowerCase() === 'exec', [mode]);
  const [store, dispatch] = useReducer(awardTypeFormReducer, initAwardTypesForm);
  const awardTypeservice = useAwardTypeService();
  const [initialState, setInitialState] = useState();
  const [recognition, setRecognition] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const selectedCurrentNomination = currentNomination ?? selectedNominations[0];
  const { awardTypeId, awardTypeName, formOfRecognition, approvedAmount, recommendedAmount } =
    selectedCurrentNomination || {};

  const changeHandler = (e) => {
    const target = e.target.name;
    const val = target === 'formOfRecognition' ? Number(e.target.value) : e.target.value;
    const change = target === 'awardTypeId' ? { awardTypeId: val, formOfRecognition: null } : { [target]: val };
    setRecognition((prevData) => {
      const newData = {
        ...prevData,
        award: {
          ...prevData.award,
          ...change,
        },
      };
      setIsDirty(true);
      return newData;
    });
  };

  useEffect(() => {
    if (store.loadRequest === requestStatus.REQUEST_COMPLETED) {
      const newAwardType = store.loadedAwardTypes.find(
        (_) => _.awardTypeId === (recognition?.award.awardTypeId ?? awardTypeId)
      );

      const getOptions = (formOfRec) => {
        if (formOfRec === formOfRecognitionTypes.CASH_ONLY) {
          return [
            {
              name: 'Cash',
              key: formOfRecognitionTypes.CASH_ONLY,
            },
          ];
        }

        if (formOfRec === formOfRecognitionTypes.TIMEOFF_ONLY) {
          return [
            {
              name: 'Time Off',
              key: formOfRecognitionTypes.TIMEOFF_ONLY,
            },
          ];
        }

        if (formOfRec === formOfRecognitionTypes.CERTIFICATE_ONLY) {
          return [
            {
              name: 'Certificate',
              key: formOfRecognitionTypes.CERTIFICATE_ONLY,
            },
          ];
        }

        return [
          {
            name: 'Cash',
            key: formOfRecognitionTypes.CASH_ONLY,
          },
          {
            name: 'Time Off',
            key: formOfRecognitionTypes.TIMEOFF_ONLY,
          },
        ];
      };

      const options = getOptions(newAwardType?.formOfRecognition);
      const limits = {
        minCashAmount: newAwardType?.minCashAmount,
        maxCashAmount: newAwardType?.maxCashAmount,
        minHoursAmount: newAwardType?.minHoursAmount,
        maxHoursAmount: newAwardType?.maxHoursAmount,
      };

      if (!recognition) {
        setInitialState({
          award: { awardTypeId, awardTypeName, formOfRecognition, amount: approvedAmount },
          options,
          limits,
        });

        setRecognition({
          ...recognition,
          award: {
            awardTypeId,
            awardTypeName,
            formOfRecognition,
            amount: isExecSignOffMode() ? approvedAmount : recommendedAmount,
          },
          options,
          limits,
        });
      }

      if (isDirty) {
        setRecognition({
          ...recognition,
          options,
          limits,
        });
        setIsDirty(false);
      }
    }
  }, [
    approvedAmount,
    awardTypeId,
    awardTypeName,
    recognition?.award?.awardTypeId,
    formOfRecognition,
    store.loadedAwardTypes,
    store.loadRequest,
    recognition,
    isDirty,
    initialState,
    isExecSignOffMode,
    recommendedAmount,
  ]);

  useEffect(() => {
    // Load All Award Types for that mission and cycle
    const loadAwardTypes = async () => {
      if (!selectedCurrentNomination) return;
      dispatch({ type: 'START_LOAD_REQUEST' });
      const requestData = {
        missionId: selectedCurrentNomination?.missionId,
        cycleId: selectedCurrentNomination?.cycleId,
        awardProcessType: processType.ANNUAL,
      };

      const response = await awardTypeservice.getList(requestData);

      if (response && response.ok) {
        // don't allow user to change award type to something that requires external approval, and only active award types
        const awardData = response.data.filter((obj) => {
          return (
            (obj.isActive === true && obj.isExternalApprovalRequired === false) ||
            obj.awardTypeId === selectedCurrentNomination.awardTypeId
          );
        });
        dispatch({ type: 'LOAD_DATA', data: awardData });
      }
    };

    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadAwardTypes();
    }
  }, [selectedCurrentNomination, awardTypeservice, store.loadRequest, formStore.loadedAwardTypes, recognition]);

  const closeModal = () => {
    onClickClose();
  };

  const validateAmount = () => {
    let error = null;
    if (recognition.award.formOfRecognition === formOfRecognitionTypes.CASH_ONLY) {
      if (Number(recognition.award.amount) < recognition.limits.minCashAmount) {
        error = `Cash Amount cannot be less than ${recognition.limits.minCashAmount} `;
      }
      if (Number(recognition.award.amount) > recognition.limits.maxCashAmount) {
        error = `Cash Amount cannot be more than ${recognition.limits.maxCashAmount} `;
      }
    }
    if (recognition.award.formOfRecognition === formOfRecognitionTypes.TIMEOFF_ONLY) {
      if (Number(recognition.award.amount) < recognition.limits.minHoursAmount) {
        error = `Hours Amount cannot be less than ${recognition.limits.minHoursAmount} `;
      }
      if (Number(recognition.award.amount) > recognition.limits.maxHoursAmount) {
        error = `Hours Amount cannot be more than ${recognition.limits.maxHoursAmount} `;
      }
    }

    if (error !== null) {
      setRecognition({ ...recognition, validationError: error });
      return false; // passes validation
    }
    return true;
  };

  const validateAwardType = (newAwardName) => {
    if (
      selectedCurrentNomination.votingStatus === votingStatus.DOWNGRADED &&
      newAwardName === selectedCurrentNomination.awardTypeName
    ) {
      const error = 'The Award Type must be downgraded.';
      setRecognition({ ...recognition, validationError: error });
      return false;
    }

    return true;
  };

  const handleUpdate = () => {
    const newAwardName = store.loadedAwardTypes.find((_) => _.awardTypeId === recognition?.award.awardTypeId)?.name;
    if (validateAmount() && validateAwardType(newAwardName)) {
      const formattedSelectedAmount =
        recognition.award.formOfRecognition !== formOfRecognitionTypes.CERTIFICATE_ONLY
          ? parseInt(recognition.award.amount, 10)
          : 0;

      const updatedChanges = {
        awardTypeId: recognition.award.awardTypeId,
        awardTypeName: newAwardName,
        approvedAmount: formattedSelectedAmount,
        recommendedAmount: isJcacSignOffMode() ? formattedSelectedAmount : selectedCurrentNomination.recommendedAmount,
        formOfRecognition: recognition.award.formOfRecognition,
        approvedFormOfRecognition: recognition.award.formOfRecognition,
        formOfRecognitionDescription: recognition.options.find((x) => x.key === recognition.award.formOfRecognition)
          ?.name,
      };

      let updatedNominations = [];

      if (selectedNominations?.length > 0) {
        updatedNominations = selectedNominations?.map((nomination) => {
          return {
            ...nomination,
            ...updatedChanges,
            overrideVoting: nomination?.votingStatus === votingStatus.DISAPPROVED,
          };
        });
      } else {
        updatedNominations = {
          ...selectedCurrentNomination,
          ...updatedChanges,
          overrideVoting: selectedCurrentNomination?.votingStatus === votingStatus.DISAPPROVED,
        };
      }
      onUpdateChanges(updatedNominations);
    }
  };

  return (
    <Modal centered scrollable className="mh-80" isOpen={isModalOpened} toggle={closeModal} size="md">
      <ModalHeader toggle={closeModal}>
        {currentNomination && currentNomination.votingStatus === votingStatus.DISAPPROVED ? 'Override' : 'Change'}
        {selectedNominations?.length > 1 ? ' Nominations' : ' Nomination'}
      </ModalHeader>
      <ModalBody>
        {recognition?.award !== undefined && (
          <>
            <div className="form-row">
              <div className="col-md-10 ml-0">
                <strong>Award type</strong>
                <div className="col-md-10 pl-0">
                  <Dropdown
                    value={recognition.award.awardTypeId}
                    name="awardTypeId"
                    options={store.loadedAwardTypes.map((item) => {
                      return { key: item.awardTypeId, value: item.name };
                    })}
                    optionLabel="value"
                    optionValue="key"
                    filterBy="value"
                    onChange={changeHandler}
                    className="w-100"
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <strong>Form of recognition</strong>
              {recognition.options &&
                recognition.options.map((category) => {
                  return (
                    <div key={category.key} className="col-md-10 mt-2 pl-0 ">
                      <input
                        type="radio"
                        name="formOfRecognition"
                        value={category.key}
                        className="mr-2"
                        onChange={changeHandler}
                        checked={recognition.award.formOfRecognition === category.key}
                      />
                      <label htmlFor={category.key}>{category.name}</label>
                    </div>
                  );
                })}
            </div>
            {!recognition.options.map((x) => x.key).includes(formOfRecognitionTypes.CERTIFICATE_ONLY) && (
              <div className="form-row">
                <div className="col-md-10 ">
                  <strong>Amount</strong>
                  <div className="col-md-10 pl-0">
                    <input
                      name="amount"
                      type="number"
                      className={
                        selectedCurrentNomination?.votingStatus === votingStatus.DISAPPROVED &&
                        'p-button-danger-outlined'
                      }
                      value={recognition.award.amount}
                      onChange={changeHandler}
                    />
                    <Label className="ml-2">
                      {recognition.award.formOfRecognition === formOfRecognitionTypes.CASH_ONLY
                        ? 'USD'
                        : recognition.award.formOfRecognition === formOfRecognitionTypes.TIMEOFF_ONLY
                        ? 'Hours'
                        : ''}
                    </Label>
                  </div>
                </div>
              </div>
            )}

            {recognition?.validationError && <i className="text-danger">{recognition?.validationError}</i>}
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          label="Update"
          icon="pi pi-save"
          onClick={() => handleUpdate()}
          className="p-button-primary w-100px "
          disabled={
            initialState?.award === recognition?.award ||
            !recognition?.award?.formOfRecognition ||
            !recognition?.award?.amount == null
          }
        />
        <Button
          label="Reset"
          icon="pi pi-refresh"
          onClick={() => setRecognition(initialState)}
          className="p-button-warning w-100px mx-3"
        />
        <Button
          label="Cancel"
          icon="pi pi-times-circle"
          onClick={() => closeModal()}
          className="p-button-secondary w-100px"
        />
      </ModalFooter>
    </Modal>
  );
}
ExecSignOffModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  formStore: PropTypes.object.isRequired,
  currentNomination: PropTypes.object,
  onClickClose: PropTypes.func.isRequired,
  onUpdateChanges: PropTypes.func.isRequired,
  selectedNominations: PropTypes.array,
};

ExecSignOffModal.defaultProps = {
  selectedNominations: [],
  currentNomination: null,
};

export default ExecSignOffModal;
