import React, { useReducer, useState, useEffect, useContext } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useParams } from 'react-router-dom';
import { SessionContext, NotificationContext } from 'contexts';
import { requestStatus } from 'constants/index';
import { useMissionProfileService } from 'hooks/admin';
import { useNavigationLinks, useAuthorization } from 'hooks';
import MissionBasicSettings from './MissionBasicSettings';
import MissionSpecificAwardsList from './MissionSpecificAwardsList';
import MissionRoles from './MissionRoles';

const initialState = {
  missionId: null,
  missionSealImage: null,
  missionCertificateSignatureImage: null,
  missionShortName: '',
  localCurrencyName: '',
  localCurrencyCode: '',
  hideNomineesGender: false,
  hideCitation: false,
  useLocalCurrency: false,
  maxCustomerServiceAwards: 0,
  missionAssignedEmployees: [],
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
  saveRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  let idx = -1;
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'START_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'COMPLETE_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_COMPLETED };

    case 'SET_DATA':
      return {
        ...state,
        ...action.data,
        loadRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_DATA':
      return { ...state, ...action.data };

    case 'UPDATE_ASSIGNED_ROLES':
      idx = state.missionAssignedEmployees.findIndex((item) => item.roleKey === action.data.roleKey);
      return {
        ...state,
        missionAssignedEmployees: [
          ...state.missionAssignedEmployees.slice(0, idx),
          {
            ...state.missionAssignedEmployees[idx],
            employees: action.data.assignees,
          },
          ...state.missionAssignedEmployees.slice(idx + 1),
        ],
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function MissionProfile() {
  const { sessionStore } = useContext(SessionContext);
  const { showSuccess, showError } = useContext(NotificationContext);
  const missionId = sessionStore.currentMissionId;
  const { checkRole } = useAuthorization();
  const { goTo } = useNavigationLinks();
  const [store, dispatch] = useReducer(reducer, initialState);
  const missionProfileService = useMissionProfileService();
  const { key } = useParams();
  const [activeTab, setActiveTab] = useState(key !== '{key}' ? Number(key) : 0);

  const onSaveClick = async () => {
    let response = '';
    const dataToSave = {
      missionId: store.missionId,
      missionSealImage: store.missionSealImage,
      missionCertificateSignatureImage: store.missionCertificateSignatureImage,
      missionShortName: store.missionShortName,
      hideNomineesGender: store.hideNomineesGender,
      hideCitation: store.hideCitation,
      useLocalCurrency: store.useLocalCurrency,
      maxCustomerServiceAwards: store.maxCustomerServiceAwards,
      missionAssignedEmployees: store.missionAssignedEmployees,
    };

    console.log('data to be saved:', dataToSave);
    dispatch({ type: 'START_SAVE_REQUEST' });
    if (store.maxCustomerServiceAwards <= 4) {
      response = await missionProfileService.saveMissionProfile(dataToSave);
      if (response && response.ok) {
        showSuccess('Profile data successfully saved!');
      }
    } else {
      showError('Customer Service Awards cannot exceed 4 per nominees ');
    }
    dispatch({ type: 'COMPLETE_SAVE_REQUEST' });
    return response.ok;
  };

  const onCancelClick = () => console.log('cancel click');

  useEffect(() => {
    const loadProfile = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const response = await missionProfileService.loadMissionProfile(missionId);
      if (response && response.ok) {
        dispatch({ type: 'SET_DATA', data: response.data });
      }
    };
    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadProfile();
    }
  }, [missionId, missionProfileService, store.loadRequest]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  return (
    <>
      <h4>Mission Admin - Mission Profile</h4>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Basic">
          <MissionBasicSettings store={store} dispatch={dispatch} onSaveClick={onSaveClick} />
        </TabPanel>
        <TabPanel header="Roles">
          <MissionRoles store={store} dispatch={dispatch} onSaveClick={onSaveClick} onCancelClick={onCancelClick} />
        </TabPanel>
        <TabPanel header="Specific Awards">
          <MissionSpecificAwardsList />
        </TabPanel>
      </TabView>
    </>
  );
}

export default MissionProfile;
