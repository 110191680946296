import React, { useContext } from 'react';

import { SessionContext } from 'contexts';

function MyAssignedRoles() {
  const { sessionStore } = useContext(SessionContext);

  return (
    <>
      <h4>The Roles I&apos;m in:</h4>
      <ul>
        {sessionStore.userRoles.map((role) => (
          <li key={role}>{role}</li>
        ))}
      </ul>
      <h4>The Permissions I have:</h4>
      <ul>
        {sessionStore.userPermissions.map((permission) => (
          <li key={permission}>{permission}</li>
        ))}
      </ul>
      <h4>Organizations I can Administer:</h4>
      <ul>
        {sessionStore.availableOrganizations.map((organization) => (
          <li key={organization.organizationId}>
            {organization.organizationId} - {organization.name} ({organization.type})
          </li>
        ))}
      </ul>
      <h4>Currently Administering Organization: {sessionStore.currentOrganizationId}</h4>
    </>
  );
}

export default React.memo(MyAssignedRoles);
