import { useContext } from 'react';
import { NotificationContext } from 'contexts';
import { useOktaAuth } from '@okta/okta-react';
import { api } from 'services';

const useApi = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { showAlertMessage } = useContext(NotificationContext);

  if (authState && authState.isAuthenticated) {
    api.setAccessToken(oktaAuth.getAccessToken());
  }

  api.setAlertMessageCallback(showAlertMessage);

  return api;
};

export default useApi;
