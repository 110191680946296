/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

const useGridFormatting = () => {
  const employeeSectionTemplate = (nomination) => {
    return (
      <div>
        {nomination.nominationEmployees.map((e) => (
          <div key={`${nomination.id}_${e.employeeId}`}>{`${e.sectionShortName}`}</div>
        ))}
      </div>
    );
  };

  const headerTemplate = (nomination) => {
    return <span className="image-text">{nomination.nominationKey}</span>;
  };

  return {
    employeeSectionTemplate,
    headerTemplate,
  };
};

export default useGridFormatting;
