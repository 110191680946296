import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Footer from 'views/Shared/Footer';
import TopBar from './TopBar';
import SideBar from './SideBar';

function Layout({ menuItems, children }) {
  const [isSideBarVisible, setSideBarVisible] = useState(true);
  const onClickSidebarToggle = useCallback(() => setSideBarVisible(!isSideBarVisible), [isSideBarVisible]);
  return (
    <>
      <TopBar />
      <div className={`anim-transition no-print page-wrapper ${isSideBarVisible ? 'toggled' : ''}`}>
        <SideBar menuItems={menuItems} isVisible={isSideBarVisible} onClickToggle={onClickSidebarToggle} />
        <div className="container-fluid">{children}</div>
      </div>
      <Footer />
    </>
  );
}
Layout.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.element.isRequired,
};

export default withRouter(Layout);
