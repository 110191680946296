import React, { useReducer, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { InputSwitch } from 'primereact/inputswitch';
import { requestStatus } from 'constants/index';
import { useNavigationLinks, useAuthorization } from 'hooks';
import { Skeleton } from 'primereact/skeleton';
import './Missions.css';
import {useMissionService} from 'hooks/admin/useMissionService';
import { Grid } from 'components';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

const ACTION_TYPE = {
  START_LOAD_REQUEST: 'START_LOAD_REQUEST',
  LOAD_MISSIONS_LIST: 'LOAD_MISSIONS_LIST',
  UPDATE_MISSION: 'UPDATE_MISSION',
};

const initialState = {
  missions: [],
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {

    case ACTION_TYPE.START_LOAD_REQUEST:
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case ACTION_TYPE.LOAD_MISSIONS_LIST:
      return {
        ...state,
        missions: action.data,
        loadRequest: requestStatus.REQUEST_COMPLETED,
      };

    case ACTION_TYPE.UPDATE_MISSION: {
      return {
        ...state,
        missions: state.missions.map((b) => {
          if (b.id === action.data.id) {
            b.isIntegrationEnabled = action.data.value;
          }
          return b;
        }),
      };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function getSkeletonLines() {
  const lines = [];
  for (let i = 0; i < 3; i += 1) {
    lines.push(
      <tr key={`_temp_${i}`}>
        <td>
          <Skeleton width="100%" height="3rem" />
        </td>
        <td>
          <Skeleton width="100%" height="3rem" />
        </td>
        <td>
          <Skeleton width="100%" height="3rem" />
        </td>
      </tr>
    );
  }
  return lines;
}

function Missions() {
  const [store, dispatch] = useReducer(reducer, initialState);
  const missionService = useMissionService();
  const { goTo } = useNavigationLinks();
  const { checkRole } = useAuthorization();

  const [globalFilter, setGlobalFilter] = useState(null);
  const isLoading = store.loadRequest === requestStatus.REQUEST_IN_PROGRESS;

  useEffect(() => {
    const loadMissions = async () => {
      dispatch({ type: ACTION_TYPE.START_LOAD_REQUEST });
      const response = await missionService.getMissions();
      if (response && response.ok) {
        dispatch({ type: ACTION_TYPE.LOAD_MISSIONS_LIST, data: response.data });
      }
    };
    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadMissions();
    }
  }, [missionService, store.loadRequest]);

  useEffect(() => {
    if (!checkRole('system_roles')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  async function enableDisableClick(id, value) {
    dispatch({
      type: ACTION_TYPE.UPDATE_MISSION,
      data: { id, value },
    });
    await missionService.updateMission({ id, IsIntegrationEnabled: value });
  }

  const enableDisableTemplate = (rowData) => {
    return <td className="text-center">
    <InputSwitch id={rowData.id} checked={rowData.isIntegrationEnabled} onChange={(e) => enableDisableClick(rowData.id, e.value)} />
  </td>
  };

  return (
    <>
      
      <Row className="mt-3 d-flex">
      <Col xs="9"><h4>Missions</h4></Col>      
      <Col xs="3">
          <span className="p-input-icon-left ml-3">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Filter" />
          </span>
        </Col>
      </Row>
      <Row>
        <div className="container-fluid table-responsive">
          <Grid
            items={store.missions}
            dataKey="id"
            sortMode="single"
            sortField="modifiedDate"
            sortOrder={-1}
            globalFilter={globalFilter}
          >
            <Column field="name" header="Name" sortable />
            <Column field="isIntegrationEnabled" header="Enable GoMBC Integration" body={enableDisableTemplate} sortable />            
          </Grid>
        </div>
      </Row>
    </>
  );
}

export default Missions;
