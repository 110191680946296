import { React, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown as PrimeDropDown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import download from 'downloadjs';
import { SessionContext } from 'contexts';
import { requestStatus, formOfRecognition } from 'constants/index';
import { useFormatting } from 'hooks';
import _ from 'lodash';

function VotingFormHeader({ formStore, helperFunctions }) {
  const { sessionStore } = useContext(SessionContext);
  const [isPrintingExcel, setisPrintingExcel] = useState(false);
  const { formatCycle } = useFormatting();

  function renderLoadingBlock() {
    return (
      <div className="col-3">
        <div className="my-3">
          <Skeleton width="80%" />
        </div>
        <div className="my-3">
          <Skeleton width="80%" />
        </div>
        <div className="my-3">
          <Skeleton width="80%" />
        </div>
        <div className="my-3">
          <Skeleton width="80%" />
        </div>
      </div>
    );
  }

  function renderCycleInfo() {
    const cycles = helperFunctions.getAvailableCycles().map((item) => ({
      value: item.id,
      label: formatCycle(item),
    }));

    return (
      <PrimeDropDown
        value={formStore.currentCycleId}
        options={cycles}
        onChange={(e) => helperFunctions.setCurrentCycle(e.value)}
        className="ml-2"
        disabled={cycles.length < 2}
      />
    );
  }
  const generateCertificateReportData = (nominations) => {
    const filteredArray = [];

    nominations?.map((obj) => filteredArray.push(obj.history?.filter((y) => y.nominationNote !== null)));
    const jcacChairs = formStore.cycleSummary.chairName.split(';').map((x) => x.trim());
    const jcacMembers = formStore.cycleSummary.members.map((x) => x.trim());

    const comments = [];
    filteredArray.map((x) =>
      _.chain(Array.from(x))
        .groupBy('employeeId')
        .map((group) => _.maxBy(group, 'createdDate'))
        .forEach((comment) => {
          if (comment && comment.nominationNote && comment.nominationNote.content) {
            comments.push({
              comments: comment.nominationNote.content,
              nominationId: comment.nominationId,
              commentBy: comment.employeeName,
              isJcacChair: jcacChairs.includes(comment.employeeName),
            });
          }
        })
        .value()
    );

    const generateIndividualComments = (id, commenter) => {
      const filteredLine = comments.filter((y) => y.nominationId === id && y.commentBy === commenter);
      return {
        [commenter]: filteredLine[0]?.comments ?? '',
      };
    };

    const generateComments = (id) => {
      const jcacChairComments = jcacChairs.map((chair) => generateIndividualComments(id, chair));
      const jcacMemberComments = jcacMembers.map((member) => generateIndividualComments(id, member));

      const combinedObject = jcacChairComments.concat(jcacMemberComments).reduce((result, obj) => {
        return { ...result, ...obj };
      }, {});
      return combinedObject;
    };

    const output = nominations.flatMap((obj) => ({
      'Nomination Key': obj.nominationKey,
      'Award Name': obj.awardTypeName,
      Amount: `${obj.recommendedAmount} ${
        // eslint-disable-next-line no-nested-ternary
        obj.formOfRecognition === formOfRecognition.CERTIFICATE_ONLY
          ? 'Certificate'
          : obj.formOfRecognition === formOfRecognition.CASH_ONLY
          ? 'USD'
          : 'Hours'
      }`,
      Justification: obj.justification,
      Nominees:
        obj.nominees.length === 1
          ? obj.nominees.map((item) => item.value)[0]
          : obj.nominees
              .map((item) => `${item.value}; `)
              .join('')
              .toString(),
      Approved: obj.summary.totalApproved,
      Disapproved: obj.summary.totalDisapproved,
      Downgraded: obj.summary.totalDowngraded,
      Hold: obj.summary.totalOnHold,
      Recused: obj.summary.totalRecused,
      Voted:
        obj.summary.totalApproved === 0 &&
        obj.summary.totalDisapproved === 0 &&
        obj.summary.totalDowngraded === 0 &&
        obj.summary.totalOnHold === 0 &&
        obj.summary.totalRecused === 0
          ? 'Not Voted'
          : '',
      WhoApproved: obj.summary?.totalApprovedVoters?.join('; '),
      WhoDisapproved: obj.summary?.totalDisapprovedVoters?.join('; '),
      WhoDowngraded: obj.summary?.totalDowngradedVoters?.join('; '),
      WhoHolds: obj.summary?.totalOnHoldVoters?.join('; '),
      WhoRecused: obj.summary?.totalRecusedVoters?.join('; '),
      NotVotedYet: obj.summary?.notVotedYet?.join('; '),
      ...generateComments(obj.id),
    }));

    return output;
  };

  const saveAsExcelFile = (buffer, fileName) => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    const filename = `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`;

    //     module.default.saveAs(data, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
    download(data, filename);
    //   }
    // });
  };

  const onExcelClick = async () => {
    setisPrintingExcel(true);
    const selectedNominations = formStore.loadedNominations.filter((x) => x.selected);
    await helperFunctions.loadHistoryDetails(
      selectedNominations.length > 0 ? selectedNominations : formStore.loadedNominations
    );
  };

  const exportExcel = () => {
    // await helperFunctions.loadHistoryDetails(formStore.loadedNominations)
    // await generateCertificateReportData(formStore.loadedNominations)
    import('xlsx').then((xlsx) => {
      const selectedNominations = formStore.loadedNominations.filter((x) => x.selected);
      const worksheet = xlsx.utils.json_to_sheet(
        generateCertificateReportData(
          selectedNominations.length > 0 ? selectedNominations : formStore.loadedNominations
        )
      );
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'JCAC Sign-off');
      setisPrintingExcel(false);
    });
  };

  useEffect(() => {
    if(isPrintingExcel){
      const selectedNominations = formStore.loadedNominations.filter((x) => x.selected);
      const nominationsToPrint = selectedNominations.length > 0 ? selectedNominations : formStore.loadedNominations;
      if (nominationsToPrint.every((x) => x.historyLoaded === true) && isPrintingExcel) {
        exportExcel();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStore.loadedNominations, isPrintingExcel]);

  const renderPosts = () => {
    const postList = Object.values(sessionStore.missionPosts).map((item) => ({
      value: item.id,
      label: item.name,
    }));

    postList.unshift({ value: 0, label: 'All' });

    return (
      !sessionStore.isBureauUser && (
        <div className="col-3">
          <h6 className="mt-0">
            Post:&nbsp;
            <PrimeDropDown
              value={formStore.currentPostId}
              options={postList}
              onChange={(e) => helperFunctions.setCurrentPostId(e.value)}
              className="ml-2 w-50"
              disabled={postList.length === 1}
            />
          </h6>
        </div>
      )
    );
  };

  return (
    <div className="row">
      {formStore.loadCycleSummaryRequest === requestStatus.REQUEST_IN_PROGRESS && renderLoadingBlock()}
      {formStore.loadCycleSummaryRequest !== requestStatus.REQUEST_IN_PROGRESS && (
        <div className="col-3">
          <h6 className="mt-0">Cycle: {renderCycleInfo()}</h6>
          <div>
            Total Nominations:
            <span className="ml-2 font-weight-bold">{formStore.cycleSummary.nominationCounters.nominationsCount}</span>
          </div>
          <div>
            Total Nominees:
            <span className="ml-2 font-weight-bold">{formStore.cycleSummary.nominationCounters.nomineesCount}</span>
          </div>
          {/* <div>
            Total Nominators:
            <span className="ml-2 font-weight-bold">{formStore.cycleSummary.nominationCounters.nominatorsCount}</span>
          </div> */}
          {!helperFunctions.isBureauVoting() && (
            <div>
              Nominations Ready for Sign Off:
              <span className="ml-2 font-weight-bold">
                {formStore.cycleSummary.nominationCounters.readyForSignOffCount}
              </span>
            </div>
          )}
        </div>
      )}
      {renderPosts()}

      {formStore.loadCycleSummaryRequest === requestStatus.REQUEST_IN_PROGRESS && renderLoadingBlock()}
      {formStore.loadCycleSummaryRequest !== requestStatus.REQUEST_IN_PROGRESS && (
        <div className="col-md-3">
          <h6 className="mt-0">{sessionStore.isBureauUser ? 'Bureau Committee Information' : 'JCAC Information'}</h6>
          <div>
            Chair: <span className="ml-2 font-weight-bold">{formStore.cycleSummary.chairName}</span>
          </div>
          <div>
            Members:
            <span className="ml-2 font-weight-bold">{formStore.cycleSummary.members.length}</span>
            <div className="p-d-flex p-ai-center">
              <Tooltip target=".custom-target-icon" className="teal-tooltip" />
              <i
                className="custom-target-icon pi pi-users p-text-secondary"
                data-pr-tooltip={formStore.cycleSummary.members.join('\n')}
                data-pr-position="bottom"
                style={{ fontSize: '2rem', cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="col-md-3">
        <span className="p-input-icon-left w-100">
          <i className="pi pi-search" />
          <InputText
            type="Search"
            placeholder="Global Filter"
            onChange={(e) => helperFunctions.setFilterTerm(e.target.value)}
            value={formStore.term}
            className="w-100"
          />
        </span>
        {helperFunctions.isSignOffMode() && (
          <div className=" text-right pt-5">
            <Button
              label="Export to Excel"
              icon="pi pi-file-excel"
              onClick={() => onExcelClick()}
              disabled={formStore.loadedNominations.length === 0}
              className="min-w-80px btn-sm ml-4"
            />
          </div>
        )}
      </div>
    </div>
  );
}

VotingFormHeader.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
};

export default VotingFormHeader;
