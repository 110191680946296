import React, { useEffect, useState, useContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Label } from 'reactstrap';
import { CKEditor } from 'ckeditor4-react';
import { ckEditorConfig } from 'config/ckEditorConfig';
import { useNavigationLinks, useAuthorization } from 'hooks';
import { SessionContext, NotificationContext } from 'contexts';
import { formOfRecognition, processType, requestStatus } from 'constants/index';
import { useAwardTypeService, useAwardTypeValidation } from 'hooks/admin/';
import WarningMessage from 'views/Shared/WarningMessage';

const initialState = {
  name: null,
  criteria: null,
  formOfRecognitionType: formOfRecognition.CASH_ONLY,
  awardProcessType: processType.ANYTIME,
  awardMissionId: 0,
  isExternalApprovalRequired: false,
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
  saveRequest: requestStatus.REQUEST_NOT_INITIATED,
  hasNominationAttached: false,
  validationResultErrors: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };
    case 'START_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_IN_PROGRESS };
    case 'COMPLETE_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_COMPLETED };
    case 'SET_DATA':
      return {
        ...state,
        loadRequest: requestStatus.REQUEST_COMPLETED,
        awardMissionId: action.data.missionId,
        ...action.data,
      };
    case 'UPDATE_DATA':
      return { ...state, ...action.data };
    case 'UPDATE_VALIDATION_RESULT':
      return {
        ...state,
        validationResultErrors: action.data,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function MissionSpecificAwards() {
  const { goTo } = useNavigationLinks();
  const { checkRole } = useAuthorization();
  const [show, setShow] = useState(false);
  const { sessionStore } = useContext(SessionContext);
  const { showSuccess, showError } = useContext(NotificationContext);
  const [store, dispatch] = useReducer(reducer, initialState);
  const awardTypeService = useAwardTypeService();
  const { mode, awardTypeId } = useParams();
  const { currentMissionId } = sessionStore;

  const formMode = mode.toLowerCase();
  const { validate } = useAwardTypeValidation();
  const [isWarningMessageVisible, toogleWarningMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);

    const loadAwardType = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const response = await awardTypeService.load(awardTypeId);
      if (response && response.ok) {
        dispatch({ type: 'SET_DATA', data: response.data });
      }
    };

    if (formMode === 'edit' && awardTypeId && store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadAwardType();
    }
  }, [awardTypeId, awardTypeService, formMode, show, store.loadRequest]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  if (!show) return null;

  const saveAwardType = async () => {
    const dataToSave = {
      id: 0,
      name: store.name,
      formOfRecognitionType: store.formOfRecognitionType,
      awardProcessType: store.awardProcessType,
      criteria: store.criteria,
      missionId: currentMissionId,
      isExternalApprovalRequired: store.isExternalApprovalRequired,
      hasNominationAttached: store.hasNominationAttached,
    };

    if (formMode === 'edit') {
      dataToSave.id = parseInt(awardTypeId, 10);
    }

    const validationResult = await validate(dataToSave);
    if (!validationResult.isValid) {
      dispatch({
        type: 'UPDATE_VALIDATION_RESULT',
        data: validationResult.errors,
      });
      toogleWarningMessage(true);
      return false;
    }
    dispatch({ type: 'START_SAVE_REQUEST' });

    const response = await awardTypeService.save(dataToSave);
    if (response && response.ok) {
      showSuccess('Award type data successfully saved!');
    } else {
      dispatch({
        type: 'UPDATE_VALIDATION_RESULT',
        data: [response.data],
      });
      showError('There was an error saving the award type.');
      toogleWarningMessage(true);
    }

    dispatch({ type: 'COMPLETE_SAVE_REQUEST' });
    goTo('mission_profile', { key: 2 });

    return response.ok;
  };

  const { awardMissionId, name, criteria, formOfRecognitionType, awardProcessType, isExternalApprovalRequired } = store;
  const isLoading = store.loadRequest === requestStatus.REQUEST_IN_PROGRESS;
  const showWarning = isWarningMessageVisible;

  const getReadOnlyValue = (field) => {
    switch (field) {
      case 'basic': {
        if (formMode === 'new') {
          return false;
        }
        if (awardMissionId === null) {
          return true;
        }

        return false;
      }
      case 'criteria': {
        if (formMode === 'new') {
          return false;
        }
        if (awardMissionId === null) {
          return true;
        }

        return false;
      }

      default:
        return false;
    }
  };

  return (
    <>
      <h4>Mission Specific Awards</h4>
      <fieldset>
        <div className="row">
          <div className="form-group col-6">
            <Label for="name">Award Name</Label>
            {isLoading && <Skeleton width="10rem" className="mb-2" />}
            {!isLoading && (
              <input
                type="text"
                className="form-control"
                id="name"
                value={name || ''}
                maxLength="100"
                disabled={getReadOnlyValue('basic')}
                onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { name: e.target.value } })}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-3">
            <Label for="awardProcessType">Approval Process Type</Label>
            {isLoading && <Skeleton width="10rem" className="mb-2" />}
            {!isLoading && (
              <div>
                <Dropdown
                  value={awardProcessType || ''}
                  className="min-w-300px"
                  disabled={getReadOnlyValue('basic')}
                  onChange={(e) =>
                    dispatch({ type: 'UPDATE_DATA', data: { awardProcessType: parseInt(e.target.value, 10) } })
                  }
                  options={[
                    { value: processType.ANYTIME, label: 'Anytime' },
                    { value: processType.ANNUAL, label: 'Annual' },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-3">
            <Label for="formOfRecognitionType">Form of Recognition</Label>
            {isLoading && <Skeleton width="10rem" className="mb-2" />}
            {!isLoading && (
              <div>
                <Dropdown
                  value={formOfRecognitionType || ''}
                  className="min-w-300px"
                  disabled={getReadOnlyValue('basic')}
                  onChange={(e) =>
                    dispatch({ type: 'UPDATE_DATA', data: { formOfRecognitionType: parseInt(e.target.value, 10) } })
                  }
                  options={[
                    { value: formOfRecognition.CASH_ONLY, label: 'Cash Only' },
                    { value: formOfRecognition.TIMEOFF_ONLY, label: 'Time Off Only' },
                    { value: formOfRecognition.CERTIFICATE_ONLY, label: 'Certificate Only' },
                    { value: formOfRecognition.CASH_OR_TIMEOFF, label: 'Cash or Time Off' },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <Label for="criteria">Criteria</Label>
            {isLoading && <Skeleton width="10rem" className="mb-2" />}
            {!isLoading && (
              <CKEditor
                config={ckEditorConfig}
                type="classic"
                initData={criteria || ''}
                onChange={(e) => dispatch({ type: 'UPDATE_DATA', data: { criteria: e.editor.getData() } })}
                editorUrl="/vendor/ckeditor/ckeditor.js"
                readOnly={getReadOnlyValue('criteria')}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <Label for="isActive">External Approval Required ?</Label>
            {isLoading && <Skeleton width="10rem" className="mb-2" />}
            {!isLoading && (
              <div>
                <InputSwitch
                  checked={isExternalApprovalRequired}
                  disabled={formMode === 'edit' && awardMissionId === null}
                  onChange={(e) =>
                    dispatch({ type: 'UPDATE_DATA', data: { isExternalApprovalRequired: e.target.value } })
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            {showWarning && (
              <WarningMessage
                message="Form validation failed!"
                details={store.validationResultErrors}
                onClickClose={toogleWarningMessage}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <Button
              label="Save"
              icon="pi pi-save"
              className="p-button-primary mr-1"
              disabled={getReadOnlyValue('basic')}
              onClick={() => saveAwardType()}
            />
            <Button
              label="Close/Cancel"
              icon="pi pi-times-circle"
              className="p-button-secondary ml-1"
              onClick={() => goTo('mission_profile', { key: 2 })}
            />
          </div>
        </div>
      </fieldset>
    </>
  );
}

export default MissionSpecificAwards;
