import React, { useState, useEffect, useReducer, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { requestStatus } from 'constants/index';
import { useNavigationLinks, useAuthorization } from 'hooks';
import { NotificationContext } from 'contexts';
import { usePostProfileService } from 'hooks/admin';
import PostBasicSettings from './PostProfileBasicSettings';
import PostAndRoles from './PostProfileRoles';

const initialState = {
  postAssignedEmployees: [],
  postName: '',
  postShortName: '',
  missionId: 0,
  postId: 0,
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
  saveRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  let idx = -1;
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_DATA':
      return {
        ...state,
        loadRequest: requestStatus.REQUEST_COMPLETED,
        postAssignedEmployees: action.data.postAssignedEmployees,
        postName: action.data.postName,
        postShortName: action.data.postShortName,
        postId: action.data.postId,
        missionId: action.data.missionId,
      };

    case 'UPDATE_DATA':
      return { ...state, ...action.data };

    case 'START_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'COMPLETE_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_COMPLETED };

    case 'UPDATE_ASSIGNED_ROLES':
      idx = state.postAssignedEmployees.findIndex((item) => item.roleKey === action.data.roleKey);
      return {
        ...state,
        postAssignedEmployees: [
          ...state.postAssignedEmployees.slice(0, idx),
          {
            ...state.postAssignedEmployees[idx],
            employees: action.data.assignees,
          },
          ...state.postAssignedEmployees.slice(idx + 1),
        ],
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function PostProfileSettings() {
  const [activeTab, setActiveTab] = useState(0);
  const { showSuccess } = useContext(NotificationContext);
  const { checkRole } = useAuthorization();
  const { goTo, goBack } = useNavigationLinks();
  const { key } = useParams();
  const [store, dispatch] = useReducer(reducer, initialState);
  const service = usePostProfileService();

  const onCancelClick = () => goBack();

  const onSaveClick = async () => {
    const dataToSave = {
      postId: store.postId,
      missionId: store.missionId,
      postName: store.postName,
      postShortName: store.postShortName,
      postAssignedEmployees: store.postAssignedEmployees,
    };

    dispatch({ type: 'START_SAVE_REQUEST' });
    const response = await service.save(dataToSave);
    if (response && response.ok) {
      showSuccess('Profile data successfully saved!');
    }
    dispatch({ type: 'COMPLETE_SAVE_REQUEST' });
    return response.ok;
  };

  useEffect(() => {
    const loadProfile = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const response = await service.load(key);
      if (response && response.ok) {
        dispatch({ type: 'SET_DATA', data: response.data });
      }
    };
    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadProfile();
    }
  }, [key, service, store.loadRequest]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  return (
    <>
      <h4>Post Settings - {store.postName}</h4>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Basic Setting">
          <PostBasicSettings
            store={store}
            dispatch={dispatch}
            onSaveClick={onSaveClick}
            onCancelClick={onCancelClick}
          />
        </TabPanel>
        <TabPanel header="Roles">
          <PostAndRoles store={store} dispatch={dispatch} onSaveClick={onSaveClick} onCancelClick={onCancelClick} />
        </TabPanel>
      </TabView>
    </>
  );
}

export default PostProfileSettings;
