import React, { useReducer, useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import {
  Button as ButtonStrap,
  Label,
  Row,
  Col,
  Table,
  Card,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShippingFast, faMegaphone, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { ActionButton, SkeletonTable, TypeBadge } from 'components';
import { SessionContext, NotificationContext } from 'contexts';
import { useFormatting, useNavigationLinks, useAuthorization } from 'hooks';
import { useAwardTypeService } from 'hooks/admin';
import { formOfRecognition, requestStatus, processType } from 'constants/index';
import './AwardTypeSettings.css';
import { awardTypeFormReducer, initAwardTypesForm } from 'reducers';

const formOfRecognitionOptions = [
  { value: formOfRecognition.CASH_ONLY, label: 'Cash Only' },
  { value: formOfRecognition.TIMEOFF_ONLY, label: 'Time Off Only' },
  { value: formOfRecognition.CERTIFICATE_ONLY, label: 'Certificate Only' },
  { value: formOfRecognition.CASH_OR_TIMEOFF, label: 'Cash or Time Off' },
];

function AwardTypeSettings() {
  const { sessionStore } = useContext(SessionContext);
  const [store, dispatch] = useReducer(awardTypeFormReducer, initAwardTypesForm);
  const service = useAwardTypeService();
  const { formatCycle } = useFormatting();
  const { showSuccess, showError } = useContext(NotificationContext);
  const { checkRole } = useAuthorization();
  const { getURL, goTo } = useNavigationLinks();

  const { currentMissionId } = sessionStore;

  useEffect(() => {
    const loadAwardTypes = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const requestData = {
        missionId: currentMissionId,
        cycleId: store.currentCycleId,
        awardProcessType: store.typeFilter,
      };

      const response = await service.getList(requestData);

      if (response && response.ok) {
        dispatch({ type: 'LOAD_DATA', data: response.data });
        console.log(response.data);
      }
    };

    const loadCycles = async () => {
      const response = await service.getCyclesByMission(currentMissionId);
      if (response && response.ok) {
        dispatch({ type: 'LOAD_CYCLES', data: response.data });
      }
    };

    if (store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadAwardTypes();
      if (store.loadedCycles.length === 0) loadCycles();
    }
  }, [currentMissionId, service, store.currentCycleId, store.loadRequest, store.loadedCycles.length, store.typeFilter]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  const onClickSave = async () => {
    const requestData = {
      id: store.editAwardType.id,
      awardProcessType: store.typeFilter,
      missionId: currentMissionId,
      cycleId: store.typeFilter === processType.ANYTIME ? null : store.currentCycleId,
      awardTypeId: store.editAwardType.awardTypeId,
      formOfRecognition: store.editAwardType.formOfRecognition,
      minCashAmount: store.editAwardType.minCashAmount,
      maxCashAmount: store.editAwardType.maxCashAmount,
      minHoursAmount: store.editAwardType.minHoursAmount,
      maxHoursAmount: store.editAwardType.maxHoursAmount,
      IsActive: store.editAwardType.isActive,
    };
    const response = await service.saveSettings(requestData);
    if (response && response.ok) {
      showSuccess('Type settings saved.');
      dispatch({ type: 'UPDATE_AWARD_TYPE' });

      if (response.data !== null) {
        dispatch({
          type: 'OVERRIDE_DATA',
          data: { oldId: store.editAwardType.id, awardType: response.data },
        });
      }
    } else {
      showError('Error trying to save type settings.');
    }
  };

  const onClickCloseModal = () => dispatch({ type: 'CLOSE_EDIT_MODAL' });

  const onClickEdit = (awardType) => dispatch({ type: 'OPEN_EDIT_MODAL', data: awardType });

  const updateAwardTypeData = (awardTypeEditFragment) => {
    dispatch({
      type: 'UPDATE_DATA',
      data: {
        editAwardType: { ...store.editAwardType, ...awardTypeEditFragment },
      },
    });
  };

  const onChangeFormOfRecognition = (event) => {
    const value = parseInt(event.target.value, 10);
    const description = formOfRecognitionOptions.find((item) => item.value === value).label;
    updateAwardTypeData({
      formOfRecognition: value,
      formOfRecognitionDescription: description,
    });
  };

  let cashEnabled = false;
  let hoursEnabled = false;
  if (store.editAwardType) {
    cashEnabled = [formOfRecognition.CASH_OR_TIMEOFF, formOfRecognition.CASH_ONLY].includes(
      store.editAwardType.formOfRecognition
    );
    hoursEnabled = [formOfRecognition.CASH_OR_TIMEOFF, formOfRecognition.TIMEOFF_ONLY].includes(
      store.editAwardType.formOfRecognition
    );
  }

  const renderTypeRules = (item) => {
    const cashLimits = (
      <div>
        Cash Limits (USD): {item.minCashAmount} ~ {item.maxCashAmount}
      </div>
    );
    const hoursLimits = (
      <div>
        Time Off Limits: {item.minHoursAmount}h ~ {item.maxHoursAmount}h
      </div>
    );

    return (
      <>
        {[formOfRecognition.CASH_OR_TIMEOFF, formOfRecognition.CASH_ONLY].includes(item.formOfRecognition) &&
          cashLimits}
        {[formOfRecognition.CASH_OR_TIMEOFF, formOfRecognition.TIMEOFF_ONLY].includes(item.formOfRecognition) &&
          hoursLimits}
      </>
    );
  };

  const currentCycle = store.loadedCycles.find((x) => x.id === store.currentCycleId);
  const isAwardTypeEditable =
    store.typeFilter === processType.ANYTIME || (currentCycle && new Date(currentCycle.closingDate) > new Date());

  const renderEditModal = () => (
    <Modal isOpen={store.isModalOpened} toggle={onClickCloseModal}>
      <ModalHeader toggle={onClickCloseModal}>
        Rules for {store.editAwardType.name}{' '}
        {store.editAwardType.awardProcessType === processType.ANNUAL ? `(Annual)` : '(Anytime)'}
        {store.editAwardType.awardProcessType === processType.ANNUAL && `- Cycle: ${formatCycle(currentCycle)}`}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label for="modalIsActive">Active ?</Label>
              <div>
                <InputSwitch
                  checked={store.editAwardType.isActive}
                  onChange={(e) => updateAwardTypeData({ isActive: e.target.value })}
                />
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="modalIsActive">Archived ?</Label>
              <div>
                <InputSwitch
                  checked={store.editAwardType.isArchived}
                  onChange={(e) => updateAwardTypeData({ isArchived: e.target.value })}
                  disabled
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="modalFormOfRecognition">Form of Recognition</Label>
              <div>
                <Dropdown
                  value={store.editAwardType.formOfRecognition}
                  onChange={(e) => onChangeFormOfRecognition(e)}
                  className="w-100"
                  options={formOfRecognitionOptions}
                  disabled
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="modalMinCash">Minimum Cash</Label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">USD</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="modalMinCash"
                  value={store.editAwardType.minCashAmount}
                  min="0"
                  step="1"
                  onChange={(e) =>
                    updateAwardTypeData({
                      minCashAmount: Number(e.target.value),
                    })
                  }
                  disabled={!cashEnabled}
                />
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="modalMaxCash">Maximum Cash</Label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">USD</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="modalMaxCash"
                  value={store.editAwardType.maxCashAmount}
                  min="0"
                  step="1"
                  onChange={(e) =>
                    updateAwardTypeData({
                      maxCashAmount: Number(e.target.value),
                    })
                  }
                  disabled={!cashEnabled}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="modalMinHours">Minimum Time Off</Label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Hours</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="modalMinHours"
                  value={store.editAwardType.minHoursAmount}
                  step="1"
                  min="0"
                  max="40"
                  onChange={(e) =>
                    updateAwardTypeData({
                      minHoursAmount: Number(e.target.value),
                    })
                  }
                  disabled={!hoursEnabled}
                />
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="modalMaxHours">Maximum Time Off</Label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Hours</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="modalMaxHours"
                  value={store.editAwardType.maxHoursAmount}
                  maxLength="10"
                  step="1"
                  min="0"
                  max="40"
                  onChange={(e) =>
                    updateAwardTypeData({
                      maxHoursAmount: Number(e.target.value),
                    })
                  }
                  disabled={!hoursEnabled}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-primary w-100px"
          disabled={!store.editAwardType.isActive && store.editAwardType.isArchived}
          onClick={() => onClickSave()}
        />
        <Button
          label="Cancel"
          icon="pi pi-times-circle"
          className="p-button-secondary w-100px"
          onClick={() => onClickCloseModal()}
        />
      </ModalFooter>
    </Modal>
  );

  const renderCycles = () => {
    const cyclesOptions =
      store.loadedCycles && store.loadedCycles.length
        ? store.loadedCycles.map((item) => ({
            value: item.id,
            label: item.number ? formatCycle(item) : item.name,
          }))
        : [
            {
              value: 0,
              label: 'No cycle available',
            },
          ];

    return (
      <Dropdown
        value={store.currentCycleId}
        options={cyclesOptions}
        onChange={(e) => dispatch({ type: 'UPDATE_CYCLE', data: e.value })}
        className="w-300px"
        disabled={cyclesOptions.length === 1 || store.typeFilter === processType.ANYTIME}
      />
    );
  };

  const renderResultTable = () => (
    <div className="table-responsive">
      <Table className="w-100 mb-0">
        <thead className="thead-light">
          <tr>
            <th>Award Type</th>
            <th>Process Type</th>
            <th>Form of Recognition</th>
            <th>Type Rules (Limits)</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {store.loadedAwardTypes.map((item) => (
            <tr key={`${item.awardTypeId}_${item.id}}`} className={`${!item.isActive ? 'award-type-disabled' : ''}`}>
              <td>{item.name}</td>
              <td>
                <div style={{ lineHeight: '18px' }}>
                  <TypeBadge type={item.awardProcessType} />
                </div>
              </td>
              <td>{item.formOfRecognitionDescription}</td>
              <td>{renderTypeRules(item)}</td>
              <td className="text-center">
                <ActionButton
                  className="p-button-text p-button-plain px-2"
                  onClick={() => onClickEdit(item)}
                  icon={['far', 'pencil']}
                  disabled={!isAwardTypeEditable}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  return (
    <>
      <h4>Award Type Settings</h4>
      <Row className="mb-3">
        <Col xs="8" md="8" lg="6" xl="6">
          <ButtonGroup className="w-100">
            <ButtonStrap
              className="w-150"
              color={store.typeFilter === processType.ANYTIME ? 'primary' : 'outline-secondary'}
              onClick={() =>
                dispatch({
                  type: 'UPDATE_TYPE_FILTER',
                  data: processType.ANYTIME,
                })
              }
            >
              <FontAwesomeIcon icon={faShippingFast} fixedWidth className="mr-2" />
              Anytime
            </ButtonStrap>
            <ButtonStrap
              className="w-150"
              color={store.typeFilter === processType.ANNUAL ? 'primary' : 'outline-secondary'}
              onClick={() =>
                dispatch({
                  type: 'UPDATE_TYPE_FILTER',
                  data: processType.ANNUAL,
                })
              }
            >
              <FontAwesomeIcon icon={faMegaphone} fixedWidth className="mr-2" />
              Annual
            </ButtonStrap>
          </ButtonGroup>
        </Col>
        <Col xs="3" md="3" lg="3" xl="3">
          <div className="d-flex">
            <div className="mt-2 mr-2">Cycle:</div>
            {renderCycles()}
          </div>
        </Col>
        <Col xs="12">
          <Link to={getURL('new_mission_specific_award')} className="float-right btn btn-primary mb-2">
            Add New Award
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="text-danger text-right py-2">
          {!isAwardTypeEditable && (
            <>
              <FontAwesomeIcon icon={faExclamationTriangle} /> The nomination window is closed for this cycle. Award
              Types cannot be edited.
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="12" xl="12">
          {store.loadRequest === requestStatus.REQUEST_IN_PROGRESS && <SkeletonTable rowsSize={5} colsSize={4} />}
          {store.loadRequest !== requestStatus.REQUEST_IN_PROGRESS && <Card>{renderResultTable()}</Card>}
        </Col>
      </Row>
      {store.editAwardType && renderEditModal()}
    </>
  );
}

export default AwardTypeSettings;
