import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { format } from 'date-fns';
import { ActionButton, Grid, SkeletonTable } from 'components';
import { SessionContext } from 'contexts';
import { useNavigationLinks } from 'hooks';
import { requestStatus } from 'constants/index';
import { useCeremonyService } from 'hooks/admin/useCeremonyService';

function CeremonyList() {
  const { goTo } = useNavigationLinks();
  const ceremonyService = useCeremonyService();
  const { sessionStore } = useContext(SessionContext);
  const missionId = sessionStore.currentMissionId;
  const [ceremonies, setCeremonies] = useState([]);
  const [loadRequest, setLoadRequest] = useState(requestStatus.REQUEST_NOT_INITIATED);
  const [globalFilter, setGlobalFilter] = useState(null);

  const navToEditCeremony = (ceremonyId) => {
    goTo('edit_ceremony', { id: ceremonyId });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <ActionButton
        className="p-button-text p-button-plain px-2"
        onClick={() => navToEditCeremony(rowData.id)}
        icon={['far', 'pencil']}
      />
    );
  };

  const dateBodyTemplate = (rowData) => {
    return rowData.ceremonyDate && format(new Date(rowData.ceremonyDate), 'PPP');
  };

  useEffect(() => {
    const loadCeremonies = async () => {
      setLoadRequest(requestStatus.REQUEST_IN_PROGRESS);
      const response = await ceremonyService.getListByMission(missionId);
      if (response && response.ok) {
        setCeremonies(response.data);
        setLoadRequest(requestStatus.REQUEST_COMPLETED);
      }
    };
    if (loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadCeremonies();
    }
  }, [ceremonyService, loadRequest, missionId]);

  return (
    <>
      <h4>Mission Admin - Ceremony List</h4>
      <Row className="mt-3">
        <span className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Filter" />
        </span>

        <Col xs="12">
          <Button
            icon="pi pi-plus"
            label="New Ceremony"
            onClick={() => goTo('ceremony')}
            className="p-button-primary btn-new-ceremony"
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          {loadRequest === requestStatus.REQUEST_IN_PROGRESS && <SkeletonTable colsSize={4} rowsSize={5} />}

          {loadRequest === requestStatus.REQUEST_COMPLETED && (
            <Grid
              items={ceremonies}
              dataKey="id"
              sortMode="single"
              sortField="modifiedDate"
              sortOrder={-1}
              globalFilter={globalFilter}
            >
              <Column field="name" header="Name" sortable />
              <Column field="ceremonyDate" header="Ceremony Date" body={dateBodyTemplate} sortable />
              <Column field="description" header="Description" />
              <Column field="id" header="Action" body={actionBodyTemplate} />
            </Grid>
          )}
        </Col>
      </Row>
    </>
  );
}
export default CeremonyList;
