import useApi from '../useApi';

export const useBureauCycleService = () => {
  const api = useApi();

  const getListByBureau = async (bureauId) => {
    if (!bureauId) {
      console.log('bureauId is null');
      return null;
    }
    return api.get(`/BureauCycle/GetListByBureau/${bureauId}`);
  };

  const loadCycleSettings = async (cycleId) => {
    if (!cycleId) {
      console.log('cycleId is null');
      return null;
    }
    return api.get(`/BureauCycle/LoadCycleSettings/${cycleId}`);
  };

  const getAssignedEmployees = async (cycleId) => {
    if (!cycleId) {
      console.log('cycleId is null');
      return null;
    }
    return api.get(`/BureauCycle/GetAssignedEmployees/${cycleId}`);
  };

  const load = async (cycleId) => {
    if (!cycleId) {
      console.log('cycleId is null');
      return null;
    }
    return api.get(`/BureauCycle/LoadCycleSettings/${cycleId}`);
  };

  const create = async (data) => {
    return api.post('/BureauCycle/Create', data);
  };

  const update = async (data) => {
    return api.put('/BureauCycle/Update', data);
  };

  const updateAssignedEmployees = async (cycleId, data) => {
    if (!cycleId) {
      console.log('cycleId is null');
      return null;
    }
    return api.put(`/BureauCycle/UpdateAssignedEmployees/${cycleId}`, data);
  };

  return {
    getListByBureau,
    loadCycleSettings,
    getAssignedEmployees,
    load,
    create,
    update,
    updateAssignedEmployees,
  };
};

export default useBureauCycleService;
