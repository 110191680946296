import useApi from '../useApi';

export const useNominationService = () => {
  const api = useApi();

  const getList = async (requestData) => {
    return api.post('/nomination/GetList', requestData);
  };

  const getReasonsAndTypes = async (missionId) => {
    if (!missionId) {
      return null; // TODO: Set notification property with error information
    }
    return api.get(`/nomination/GetReasonsAndTypes/${missionId}`);
  };

  const getNomination = async (requestData) => api.post('/nomination/GetNomination', requestData);

  const saveNomination = async (nominationData) => api.post('/nomination/SaveNomination', nominationData);

  const updateNomineeStatus = async (requestData) => api.post('/nomination/UpdateNomineeStatus', requestData);

  const getNominationDetails = async (requestData) => api.post('/nomination/GetNominationDetails', requestData);

  const getFormData = async (nominationId) => api.get(`/FormReport/GetNominationAwardData/${nominationId}`);

  const getCertificateData = async (requestData) => api.post('/nomination/GetCertificates', requestData);

  const getEmployeeHistory = async (payload) => api.post('/nomination/GetEmployeeHistory', payload);

  const sendToOPS = async (requestData) => api.post('/nomination/SendToOPS', requestData);

  const processComment = async (requestData) => api.post('/nomination/ProcessComment', requestData);

  const getNominationHistory = async (requestData) => api.post('/nomination/GetNominationHistory', requestData);

  const updateCeremony = async (requestData) => api.post('/nomination/UpdateNominationCeremony', requestData);

  const saveFiscalDataForNominations = async (requestData) =>
    api.post(`/nomination/SaveFiscalDataForNominations`, requestData);

  const sendEmailToSupervisor = async (requestData) => api.post(`/nomination/SendEmailToSupervisor`, requestData);

  const sendEmailOfNotEligibleNomineeToNominator = async (requestData) =>
    api.post(`/nomination/SendEmailOfNotEligibleNomineeToNominator`, requestData);

  const sendNominationsForward = async (requestData) => api.post(`/nomination/SendNominationsForward`, requestData);

  const returnNominationTo = async (userEmployeeId, nominationId, status, comment) =>
    await api.put('/nomination/ReturnNominationToStatus', { userEmployeeId, nominationId, status, comment });

  return {
    getList,
    getReasonsAndTypes,
    getNomination,
    saveNomination,
    updateNomineeStatus,
    getNominationDetails,
    getFormData,
    getEmployeeHistory,
    sendToOPS,
    processComment,
    getNominationHistory,
    updateCeremony,
    getCertificateData,
    saveFiscalDataForNominations,
    sendNominationsForward,
    sendEmailToSupervisor,
    sendEmailOfNotEligibleNomineeToNominator,
    returnNominationTo,
  };
};

export default useNominationService;
