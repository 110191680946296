import React, { useEffect, useReducer, useContext } from 'react';
import { useParams } from 'react-router';
import { Label, Input, Col, Row, FormGroup } from 'reactstrap';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { SessionContext, NotificationContext } from 'contexts';
import { useNavigationLinks, useAuthorization } from 'hooks';
import useCeremonyValidation from 'hooks/admin/useCeremonyValidation';
import { requestStatus } from 'constants/index';
import { useCeremonyService } from 'hooks/admin/useCeremonyService';
import { formatUtcDate, getMomentDate } from 'utils/dateHelpers';

const initialState = {
  id: 0,
  name: '',
  description: '',
  missionId: 0,
  ceremonyDate: null,
  loadRequest: requestStatus.REQUEST_NOT_INITIATED,
  saveRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_LOAD_REQUEST':
      return { ...state, loadRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'SET_DATA':
      return {
        ...state,
        id: action.data.id,
        name: action.data.name,
        description: action.data.description,
        missionId: action.data.missionId,
        ceremonyDate: action.data.ceremonyDate,
        loadRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_DATA':
      return { ...state, ...action.data };

    case 'START_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_IN_PROGRESS };

    case 'COMPLETE_SAVE_REQUEST':
      return { ...state, saveRequest: requestStatus.REQUEST_COMPLETED };

    case 'UPDATE_VALIDATION_RESULT':
      return {
        ...state,
        validationResultErrors: action.data,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function Ceremony() {
  const { validate } = useCeremonyValidation();
  const { sessionStore } = useContext(SessionContext);
  const missionId = sessionStore.currentMissionId;
  const { mode, ceremonyId } = useParams();
  const formMode = mode.toLowerCase();
  const [store, dispatch] = useReducer(reducer, initialState);
  const ceremonyService = useCeremonyService();
  const { showError, showSuccess } = useContext(NotificationContext);
  const { checkRole } = useAuthorization();
  const { goTo } = useNavigationLinks();

  const onClickSave = async () => {
    const dataToSave = {
      id: store.id,
      name: store.name,
      description: store.description,
      missionId,
      ceremonyDate: getMomentDate(store.ceremonyDate),
    };
    if (formMode === 'edit') {
      dataToSave.id = parseInt(ceremonyId, 10);
    }

    const validationResult = await validate(dataToSave);
    if (!validationResult.isValid) {
      dispatch({
        type: 'UPDATE_VALIDATION_RESULT',
        data: validationResult.errors,
      });
      showError(`${validationResult.errors.join('; ')}`);
      return false;
    }
    dispatch({ type: 'START_SAVE_REQUEST' });
    const saveResponse = await ceremonyService.save(dataToSave);
    if (saveResponse && saveResponse.ok) {
      showSuccess('Ceremony data successfully saved!');
    }
    dispatch({ type: 'COMPLETE_SAVE_REQUEST' });
    return saveResponse.ok;
  };

  useEffect(() => {
    const loadCeremony = async () => {
      dispatch({ type: 'START_LOAD_REQUEST' });
      const response = await ceremonyService.load(ceremonyId);
      if (response && response.ok) {
        dispatch({ type: 'SET_DATA', data: response.data });
      }
    };

    if (formMode === 'edit' && ceremonyId && store.loadRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadCeremony();
    }
  }, [ceremonyId, ceremonyService, formMode, missionId, store.loadRequest]);

  useEffect(() => {
    if (!checkRole('hr_officer')) {
      goTo('not_authorized');
    }
  }, [checkRole, goTo]);

  return (
    <>
      <h4>Ceremony Settings</h4>
      <div>
        <Row>
          <Col>
            <FormGroup>
              <Label for="ceremonyName">Name</Label>
              <div>
                <Input
                  type="text"
                  id="ceremonyName"
                  value={store.name}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_DATA',
                      data: { name: e.target.value },
                    })
                  }
                  className="w-25"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="ceremonyDescription">Description</Label>
              <div>
                <InputTextarea
                  rows={5}
                  cols={30}
                  value={store.description}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_DATA',
                      data: { description: e.target.value },
                    })
                  }
                  className="w-25"
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="ceremonyDate">Ceremony Date</Label>
              <div>
                <Calendar
                  id="ceremonyDate"
                  showIcon
                  value={store.ceremonyDate && formatUtcDate(store.ceremonyDate)}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_DATA',
                      data: { ceremonyDate: formatUtcDate(e.target.value) },
                    })
                  }
                  className="w-25"
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div>
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-primary min-w-150px mr-1"
          onClick={() => onClickSave()}
        />
        <Button
          label="Close/Cancel"
          icon="pi pi-times-circle"
          className="p-button-secondary min-w-150px mr-1"
          onClick={() => goTo('ceremony_list')}
        />
      </div>
    </>
  );
}

export default Ceremony;
