import React, { memo } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import RoleDashboard from './RoleDashboard';

function MissionDashboard({ missionDashboardOptions }) {
  const { missionName, roles } = missionDashboardOptions;

  return (
    <Card className="mb-4">
      <CardHeader>
        <h4>{missionName}</h4>
      </CardHeader>
      <CardBody>
        {roles?.map((roleInfo) => (
          <div className="row" key={uuid()}>
            <div className="col-12">
              <RoleDashboard postDashboardOptions={roleInfo} />
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  );
}

MissionDashboard.propTypes = {
  missionDashboardOptions: PropTypes.object,
};

MissionDashboard.defaultProps = {
  missionDashboardOptions: {},
};

export default memo(MissionDashboard);
