import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Dropdown as PrimeDropDown } from 'primereact/dropdown';
import { NotificationContext } from 'contexts';
import { useNominationService } from 'hooks/nomination';
import { DocumentCreator } from './ceremony-creator';

const ASSIGN_CEREMONY_MODE = 1;
const GENERATE_SCRIPT_MODE = 0;

function AssignCeremonyModal({
  listToProcess,
  ceremomyId,
  ceremonyName,
  ceremonyList,
  missionName,
  isAssignCeremonyModalOpened,
  onClickToggleModal,
  modalMode,
  ceremonyNominations,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { showSuccess } = useContext(NotificationContext);
  const service = useNominationService();
  const [selectedCeremony, setselectedCeremony] = useState(ceremomyId);

  const title = modalMode === GENERATE_SCRIPT_MODE ? 'Generate Ceremony Script' : 'Assign Ceremony';
  let assignBtnLabel = 'Assign Ceremony';
  let skipBtnLabel = 'Skip Ceremony';
  let generateBtnLabel = 'Generate Ceremony Script';

  if (isProcessing) {
    assignBtnLabel = 'Processing...';
    generateBtnLabel = 'Processing...';
    skipBtnLabel = 'Processing...';
  }

  const skipCeremony = async (isSkipCeremony) => {
    setIsProcessing(true);
    const requestData = {
      nominationList: listToProcess.map((item) => item.id),
      ceremonyId: isSkipCeremony ? 0 : selectedCeremony,
      IsSkipped: !!isSkipCeremony,
    };
    const response = await service.updateCeremony(requestData);
    if (response && response.ok) {
      showSuccess('Ceremony data was successfully saved');
    }
    setIsProcessing(false);

    onClickToggleModal(true); // force refresh = true
  };

  const exportToWord = () => {
    const filtered = ceremonyNominations.map(
      ({ awardTypeName, nominationEmployees, awardReasons, modifiedDate, citation, isGroup }) => ({
        awardTypeName,
        nominationEmployees,
        awardReasons,
        modifiedDate,
        citation,
        isGroup,
      })
    );
    DocumentCreator(
      missionName,
      // filtered.sort((a, b) => (a.awardTypeName.toLowerCase() > b.awardTypeName.toLowerCase() ? 1 : -1))
      filtered.sort((a, b) => a.awardTypeName.localeCompare(b.awardTypeName) || b.isGroup - a.isGroup)
    );
  };

  const onChangeCeremony = (ceremonyId) => {
    setselectedCeremony(ceremonyId);
  };

  function renderCeremonyDropdown() {
    const list = Object.values(ceremonyList).map((item) => ({
      value: item.id,
      label: item.name,
    }));

    return (
      <PrimeDropDown
        value={selectedCeremony}
        options={list}
        onChange={(e) => onChangeCeremony(e.value)}
        className="cycle-dropdown-filter"
        disabled={list && list < 2}
      />
    );
  }

  return (
    <Modal isOpen={isAssignCeremonyModalOpened} toggle={onClickToggleModal} className="modal-lg">
      <ModalHeader toggle={onClickToggleModal}>{title}</ModalHeader>
      <ModalBody>
        <Row>
          <Col />
        </Row>
        <Row>
          <Col md="8">
            {modalMode === ASSIGN_CEREMONY_MODE && (
              <h6>
                Ceremony: <div>{renderCeremonyDropdown()}</div>
              </h6>
            )}
            {modalMode === GENERATE_SCRIPT_MODE && <p>Ceremony name: {ceremonyName} </p>}
          </Col>
          <Col md="8" />
          {modalMode === ASSIGN_CEREMONY_MODE && (
            <Col className="text-right pr-5">
              <Label for="modalNumberOfNominations">
                Number of nominations to be processed:
                <span className="ml-2 font-weight-bold">{listToProcess.length}</span>
              </Label>
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        {modalMode === GENERATE_SCRIPT_MODE && (
          <Button className="min-w-150px" color="primary" onClick={() => exportToWord()} disabled={isProcessing}>
            {generateBtnLabel}
          </Button>
        )}

        {modalMode === ASSIGN_CEREMONY_MODE && (
          <>
            <Button className="min-w-150px" color="primary" onClick={() => skipCeremony(false)} disabled={isProcessing}>
              {assignBtnLabel}
            </Button>
            <Button className="min-w-150px " color="info" onClick={() => skipCeremony(true)} disabled={isProcessing}>
              {skipBtnLabel}
            </Button>
          </>
        )}
        <Button className="min-w-150px" color="secondary" onClick={() => onClickToggleModal()} disabled={isProcessing}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
AssignCeremonyModal.propTypes = {
  listToProcess: PropTypes.array.isRequired,
  ceremonyNominations: PropTypes.array,
  ceremomyId: PropTypes.number,
  ceremonyName: PropTypes.string,
  missionName: PropTypes.string,
  isAssignCeremonyModalOpened: PropTypes.bool.isRequired,
  onClickToggleModal: PropTypes.func.isRequired,
  ceremonyList: PropTypes.array.isRequired,
  modalMode: PropTypes.number.isRequired,
};
AssignCeremonyModal.defaultProps = {
  ceremomyId: undefined,
  ceremonyName: undefined,
  missionName: undefined,
  ceremonyNominations: null,
};
export default React.memo(AssignCeremonyModal);
