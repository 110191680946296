import React, { useEffect, useContext, useReducer } from 'react';
import { Row, Col } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Grid } from 'components';
import { useAuthorization, useEmployeeSearchService, useReportSearchService, useNavigationLinks } from 'hooks';
import { SessionContext } from 'contexts';
import { requestStatus } from 'constants/index';
import { dynamicSort } from 'utils/sorting';
import { faUser, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const loadingRows = new Array(5);
const rowLoadingTemplate = () => <Skeleton />;

const initialState = {
  availableParamsRequest: requestStatus.REQUEST_NOT_INITIATED,
  availableParams: {
    awardTypeName: [],
    missions: [],
    posts: [],
  },
  searchParams: {
    selectedAwardTypeId: 0,
    selectedMissionId: 0,
    selectedPostId: 0,
  },
  searchResult: [],
  searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
  listSuperviseesStatus: requestStatus.REQUEST_NOT_INITIATED,
  saveSupervisorRequest: requestStatus.REQUEST_NOT_INITIATED,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_AVAILABLE_PARAMS':
      return {
        ...state,
        availableParams: { ...state.availableParams, ...action.data },
      };

    case 'START_REQUEST_SEARCH_PARAMS':
      return {
        ...state,
        availableParamsRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'UPDATE_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: { ...state.searchParams, ...action.data },
        availableParamsRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_SEARCH_RESULT':
      return {
        ...state,
        searchResult: action.data,
        searchRequestStatus: requestStatus.REQUEST_COMPLETED,
      };

    case 'START_SEARCH_REQUEST':
      return {
        ...state,
        searchRequestStatus: requestStatus.REQUEST_IN_PROGRESS,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const getMissionsAndBureausList = (bureaus, missions) => {
  const result = missions.map((item) => {
    const bureauName = bureaus.find((elem) => elem.key === item.bureauId).value;
    return { key: item.id, value: `${bureauName} - ${item.name}` };
  });
  return result.sort(dynamicSort('value'));
};

function TimeOff() {
  const service = useEmployeeSearchService();
  const timeOffService = useReportSearchService();
  const { sessionStore } = useContext(SessionContext);
  const { missionId, postId } = sessionStore.employeeUser;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { checkRole, checkPermission } = useAuthorization();
  const { getURL, goTo } = useNavigationLinks();

  const awardTypeId = (awards) => {
    awards.unshift({ key: 0, value: 'All' });
    return awards;
  };

  useEffect(() => {
    const doesUserHaveHrRole = checkRole('hr_officer');
    const doesUserHaveTimeKeepingPermissions = checkRole('timeKeeping');

    const doesUserHaveAccess = doesUserHaveHrRole || doesUserHaveTimeKeepingPermissions;

    if (!doesUserHaveAccess) {
      goTo('not_authorized');
    }
  }, [checkRole, checkPermission, goTo]);

  const setParamValue = (paramValue) => {
    dispatch({
      type: 'UPDATE_SEARCH_PARAMS',
      data: { ...paramValue },
    });
  };

  function autoOpenPostsDropdown(length) {
    if (length > 1) {
      document.getElementById('searchposts').click();
    }
  }

  async function loadPosts(selectedMissionId) {
    const response = await service.getAvailablePostsByMission(selectedMissionId);
    if (response && response.ok) {
      dispatch({
        type: 'UPDATE_AVAILABLE_PARAMS',
        data: {
          posts: response.data,
        },
      });
      dispatch({
        type: 'UPDATE_SEARCH_PARAMS',
        data: {
          selectedPostId: response.data[0].key,
        },
      });
    }
    autoOpenPostsDropdown(response.data.length);
  }

  const groupOrIndividualTemplate = (nomination) => {
    let text = 'Individual';
    let icon = faUser;
    if (nomination.isGroup) {
      text = 'Group';
      icon = faUserFriends;
    }
    return (
      <>
        <FontAwesomeIcon icon={icon} className="mr-1" fixedWidth />
        {text}
      </>
    );
  };

  const nomineeBodyTemplate = (rowData) => {
    const body = rowData.nominationEmployees.length
      ? rowData.nominationEmployees.map((e) => {
          return <div key={e.id}>{e.name}</div>;
        })
      : '-';
    return <div>{body}</div>;
  };

  async function onSearchClick() {
    const timeOffRequestReport = {
      missionId: state.searchParams.selectedMissionId,
      postId: state.searchParams.selectedPostId,
      awardTypeId: state.searchParams.selectedAwardTypeId,
    };
    dispatch({ type: 'START_SEARCH_REQUEST' });
    const response = await timeOffService.getTimeOff(timeOffRequestReport);
    if (response && response.ok) {
      const timeOffResult = response.data.map((item) => ({
        ...item,
        Individual_Group: `${item.isGroup ? 'Group' : 'Individual'}`,
      }));
      dispatch({ type: 'UPDATE_SEARCH_RESULT', data: timeOffResult });
    }
  }

  useEffect(() => {
    async function loadSearchParameters() {
      dispatch({ type: 'START_REQUEST_SEARCH_PARAMS' });
      const response = await timeOffService.getSearchAvailableParameters(missionId);
      if (response && response.ok) {
        dispatch({
          type: 'UPDATE_AVAILABLE_PARAMS',
          data: {
            bureaus: response.data.bureaus,
            sections: response.data.sections,
            missions: response.data.missions,
            posts: response.data.posts,
            missionsAndBureaus: getMissionsAndBureausList(response.data.bureaus, response.data.missions),
            awardTypeName: awardTypeId(response.data.awardTypes),
          },
        });
        dispatch({
          type: 'UPDATE_SEARCH_PARAMS',
          data: {
            selectedPostId: postId,
            selectedMissionId: missionId,
            selectedAwardTypeId: awardTypeId,
          },
        });
      }
    }

    if (missionId && state.availableParamsRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadSearchParameters();
    }
  }, [timeOffService, service, missionId, state.availableParamsRequest, postId, state.searchParams.employeeId]);
  return (
    <div className="all-awards">
      <h4>Reports - Time Off Report</h4>
      <Row>
        <Col>
          Bureau / Mission
          <div>
            <Dropdown
              value={state.searchParams.selectedMissionId}
              options={state.availableParams.missionsAndBureaus?.filter(
                (x) => x.key === state.searchParams.selectedMissionId
              )}
              optionLabel="value"
              optionValue="key"
              filterBy="value"
              filter
              disabled
              className="w-100"
              onChange={(e) => {
                setParamValue({ selectedMissionId: e.value });
                loadPosts(e.value);
              }}
            />
          </div>
        </Col>
        <Col>
          Post
          <div>
            <Dropdown
              id="searchposts"
              value={state.searchParams.selectedPostId}
              options={state.availableParams.posts}
              optionLabel="value"
              optionValue="key"
              className="w-100"
              showClear
              onChange={(e) => setParamValue({ selectedPostId: e.value })}
            />
          </div>
        </Col>
        <Col>
          <div>
            Type
            <Dropdown
              value={state.searchParams.selectedAwardTypeId}
              options={state.availableParams.awardTypeName}
              optionLabel="value"
              optionValue="key"
              filterBy="value"
              showClear
              onChange={(e) => setParamValue({ selectedAwardTypeId: e.value })}
              placeholder="All"
              className="w-100"
            />
          </div>
        </Col>
        <Col lg="1" md="3" xs="6" className="mt-1" />
        <Col className="mt-4">
          <div className="text-right">
            <Button
              label="Search"
              icon="pi pi-search"
              className="p-button-primary w-100 min-w-100px"
              onClick={() => onSearchClick()}
            />
          </div>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          {state.searchRequestStatus === requestStatus.REQUEST_NOT_INITIATED && (
            <div>Please, fill the parameters and click on Search Button</div>
          )}

          {state.searchRequestStatus === requestStatus.REQUEST_IN_PROGRESS && (
            <Grid items={loadingRows}>
              <Column field="nominationKey" header="ID" body={rowLoadingTemplate} />
              <Column field="awardTypeName" header="Award Type" body={rowLoadingTemplate} />
              <Column field="Individual_Group" header="Group/Individual" body={rowLoadingTemplate} />
              <Column field="nominationEmployees" header="Nominees" body={rowLoadingTemplate} />
              <Column field="approvedAmount" header="Hours" body={rowLoadingTemplate} />
            </Grid>
          )}
          {state.searchRequestStatus === requestStatus.REQUEST_COMPLETED && (
            <Grid items={state.searchResult} dataKey="nominationKey" sortMode="single">
              <Column field="nominationKey" header="ID" sortable />
              <Column field="awardTypeName" header="Award Type" sortable />
              <Column field="Individual_Group" header="Group/Individual" body={groupOrIndividualTemplate} sortable />
              <Column field="nominationEmployees" header="Nominees" body={nomineeBodyTemplate} sortable />
              <Column field="approvedAmount" header="Hours" sortable />
            </Grid>
          )}
        </Col>
      </Row>
    </div>
  );
}
export default TimeOff;
