const useFormatting = () => {
  const formatCycle = (cycle) => {
    if (cycle) {
      if (cycle.number) {
        return `${cycle.year}-${cycle.number.toString().padStart(2, '0')}-${cycle.name}`;
      }
      return `${cycle.year}-${cycle.name}`;
    }
    return '';
  };

  const joinIfExists = (arrList, separator) => arrList.filter(Boolean).join(separator);

  return {
    formatCycle,
    joinIfExists,
  };
};

export default useFormatting;
