/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useReducer, useCallback, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import differenceBy from 'lodash/differenceBy';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import download from 'downloadjs';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { faUser, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import './AllAwards.css';
import { processType, requestStatus, nominationStatus, approvalStatus, votingStatus } from 'constants/index';
import { SessionContext, NotificationContext } from 'contexts';
import {
  useNavigationLinks,
  useReportSearchService,
  useEmployeeSearchService,
  useFormatting,
  useCertificateService,
  useAuthorization,
} from 'hooks';
import { dynamicSort } from 'utils/sorting';
import { getMomentDate } from 'utils/dateHelpers';
import { getEnumName, removeHtmlEntities } from 'utils/helperFunctions';
import { Grid, SkeletonTable } from 'components';
import reportType from 'constants/reportType';

import CertificatePrintModal from './CertificatePrintModal';

const initialState = {
  availableParamsRequest: requestStatus.REQUEST_NOT_INITIATED,
  generateCertificatesRequest: requestStatus.REQUEST_NOT_INITIATED,
  availableParams: {
    loaded: false,
    bureaus: [],
    missions: [],
    posts: [],
    sections: [],
    cycles: [],
    nominationStatuses: [],
    awardTypes: [],
    missionsAndBureaus: [],
    groupAttributeOptions: [],
    postAgencies: [],
  },
  searchParams: {
    selectedMissionId: 0,
    selectedPostId: 0,
    selectedSectionId: 0,
    selectedCycleId: 0,
    selectedNominationStatusId: 0,
    selectedNominationStatuses: [0],
    selectedProcessType: 0,
    selectedAwardTypeId: 0,
    selectedGroupAttributeOptionId: 0,
    selectedNomineePostAgencyId: 0,
    selectedNominatorPostAgencyId: 0,
    textNominee: '',
    selectedDateRange: null,
    selectedEffectiveDate: null,
  },
  awardNominations: [],
  financeReportData: [],
  selectedRecords: [],
  searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
  isCertificatePrintModalOpened: false,
};

const generateAllAwardsReportData = (nominations) => {
  const output = nominations.flatMap((obj) =>
    obj.nominationEmployees.map((arr) => ({
      Nomination_Key: obj.nominationKey,
      Status: obj.nominationStatusName,
      AwardType: obj.awardTypeName,
      Type: obj.isGroup ? 'Group' : 'Individual',
      EmployeeId: arr.payrollId,
      PositionTitle: arr.positionTitle,
      Nominees: arr.name,
      EmployeeSection: arr.sectionShortName,
      EmployeePost: arr.postName,
      EmployeeType: arr.isLocalStaff ? 'LE' : 'USDH',
      NominatorName: obj.nominatorName,
      NominatorPost: obj.postName,
      NominatorSection: obj.nominatorSection,
      approvingUSDHSupervisors: arr.usdhSupervisorName,
      citation: obj.citation,
      EmployeeApprovalStatus:
        arr.approvalStatus === approvalStatus.PENDING
          ? 'PENDING'
          : arr.approvalStatus === approvalStatus.APPROVED
          ? 'APPROVED'
          : 'DISAPPROVED',
    }))
  );
  return output;
};

const fiscalStripData = (fiscalStrip, column) => {
  if (!fiscalStrip) {
    return null;
  }

  const fiscalData = fiscalStrip.split('/');

  if (fiscalData.length < 2) {
    return null;
  }
  const [
    agency,
    bbfy,
    ebfy,
    appropriation,
    bureau,
    allotment,
    operatingAllowance,
    obligation,
    functionCode,
    objectCode,
    projectCode,
    organizationCode,
  ] = fiscalData;

  switch (column) {
    case 'agency':
      return agency;

    case 'bbfy':
      return bbfy;

    case 'ebfy':
      return ebfy;
    case 'appropriation':
      return appropriation;

    case 'bureau':
      return bureau;

    case 'allotment':
      return allotment;

    case 'operatingAllowance':
      return operatingAllowance;

    case 'obligation':
      return obligation;

    case 'functionCode':
      return functionCode;

    case 'objectCode':
      return objectCode;

    case 'projectCode':
      return projectCode;

    case 'organizationCode':
      return organizationCode;

    default:
      return fiscalStrip;
  }
};

function removeRejected(obj, condition) {
  if (condition(obj)) {
    return null;
  }

  if (obj.nominationEmployees) {
    obj.nominationEmployees = obj.nominationEmployees.filter((child) => {
      return removeRejected(child, condition) !== null;
    });
  }
  return obj;
}

const generateFinanceReportData = (nominations) => {
  Object.values(nominations).forEach((item) => {
    removeRejected(item, (obj) => obj.approvalStatus === approvalStatus.DISAPPROVED);
  });

  const output = nominations.flatMap((obj) =>
    obj.nominationEmployees.map((arr) => ({
      NominationKey: obj.nominationKey,
      Nominees: arr.name,
      EmployeeId: arr.payrollId,
      EmployeeSection: arr.sectionShortName,
      JobTitle: arr.positionTitle,
      EmployeePost: arr.postName,
      EmployeeType: arr.isLocalStaff ? 'LE' : 'USDH',
      AwardTypeName: obj.awardTypeName,
      ApprovedAmount: obj.approvedAmount,
      NominatorName: obj.nominatorName,
      NominatorAgency: obj.nominatorAgency,
      NominatorPost: obj.nominatorPost,
      NominatorSection: obj.nominatorSection,
      NominatorFunding: '',
      EffectiveDate: obj.effectiveDate,
      FiscalStrip: arr.fiscalStrip,
      Agency: fiscalStripData(arr.fiscalStrip, 'agency'),
      bbfy: fiscalStripData(arr.fiscalStrip, 'bbfy'),
      ebfy: fiscalStripData(arr.fiscalStrip, 'ebfy'),
      Appropriation: fiscalStripData(arr.fiscalStrip, 'appropriation'),
      Aureau: fiscalStripData(arr.fiscalStrip, 'bureau'),
      Allotment: fiscalStripData(arr.fiscalStrip, 'allotment'),
      OperatingAllowance: fiscalStripData(arr.fiscalStrip, 'operatingAllowance'),
      Obligation: fiscalStripData(arr.fiscalStrip, 'obligation'),
      FunctionCode: fiscalStripData(arr.fiscalStrip, 'functionCode'),
      ObjectCode: fiscalStripData(arr.fiscalStrip, 'objectCode'),
      ProjectCode: fiscalStripData(arr.fiscalStrip, 'projectCode'),
      OrganizationCode: fiscalStripData(arr.fiscalStrip, 'organizationCode'),
    }))
  );
  return output;
};

const generateCertificateReportData = (nominations) => {
  Object.values(nominations).forEach((item) => {
    removeRejected(item, (obj) => obj.approvalStatus === approvalStatus.DISAPPROVED);
  });
  const output = nominations.flatMap((obj) => ({
    nominationKey: obj.nominationKey,
    awardTypeName: obj.awardTypeName,
    citation: obj.citation,
    nominatorName: obj.nominatorName,
    nominatorAgency: obj.nominatorAgency,
    status: obj.nominationStatusName,
    cycle: obj.cycleName,
    nominee: obj.nominationEmployees.map((nominee) => `${nominee.name} (${nominee.sectionShortName})`).join('; '),
  }));
  return output;
};

const generateExecutiveReportData = (nominations) => {
  Object.values(nominations).forEach((item) => {
    removeRejected(item, (obj) => obj.approvalStatus === approvalStatus.DISAPPROVED);
  });

  const output = nominations.flatMap((obj) => ({
    nominationKey: obj.nominationKey,
    jcacVote: getEnumName(votingStatus, obj.votingStatus),
    nominator: obj.nominatorName,
    nominees: obj.nominationEmployees.map((nominee) => `${nominee.name} (${nominee.sectionShortName})`).join('; '),
    approvingUSDHSupervisors: obj.nominationEmployees.map((nominee) => `${nominee.usdhSupervisorName}`).join('; '),
    awardTypeName: obj.awardTypeName,
    awardAmount: obj.approvedAmount,
    JCACComments: obj.jcacChairComments,
    citation: obj.citation,
    justification: removeHtmlEntities(obj.justification),
  }));
  return output;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_REQUEST_SEARCH_PARAMS':
      return {
        ...state,
        availableParamsRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'UPDATE_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: { ...state.searchParams, ...action.data },
        availableParamsRequest: requestStatus.REQUEST_COMPLETED,
      };

    case 'START_SEARCH_REQUEST':
      return {
        ...state,
        searchRequestStatus: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'START_CERTIFICATE_GENERATION':
      return {
        ...state,
        generateCertificatesRequest: requestStatus.REQUEST_IN_PROGRESS,
      };

    case 'COMPLETE_CERTIFICATE_GENERATION':
      return {
        ...state,
        generateCertificatesRequest: requestStatus.REQUEST_COMPLETED,
        selectedRecords: [],
      };

    case 'UPDATE_SEARCH_RESULT':
      return {
        ...state,
        awardNominations: action.data,
        financeReportData: [],
        searchRequestStatus: requestStatus.REQUEST_COMPLETED,
      };

    case 'UPDATE_FINANCE_REPORT':
      return {
        ...state,
        financeReportData: action.data,
        searchRequestStatus: requestStatus.REQUEST_COMPLETED,
      };

    case 'RESET_SEARCH_FORM':
      return {
        ...state,
        awardNominations: [],

        searchRequestStatus: requestStatus.REQUEST_NOT_INITIATED,
        availableParamsRequest: requestStatus.REQUEST_NOT_INITIATED,
      };

    case 'UPDATE_AVAILABLE_PARAMS':
      return {
        ...state,
        availableParams: { ...state.availableParams, ...action.data },
      };

    case 'UPDATE_SELECTED_RECORDS':
      return { ...state, selectedRecords: action.data };

    case 'CLOSE_CERTIFICATE_PRINT_MODAL':
      return { ...state, isCertificatePrintModalOpened: false };

    case 'OPEN_CERTIFICATE_PRINT_MODAL':
      return { ...state, isCertificatePrintModalOpened: true };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const getMissionsAndBureausList = (bureaus, missions) => {
  const result = missions.map((item) => {
    const bureauName = bureaus.find((elem) => elem.key === item.bureauId).value;
    return { key: item.id, value: `${bureauName} - ${item.name}` };
  });
  return result.sort(dynamicSort('value'));
};

const addDefaultValueToList = (list) => {
  list.unshift({
    key: 0,
    value: 'All',
  });
  return list;
};

function AllAwards() {
  const { sessionStore } = useContext(SessionContext);
  const { showSuccess } = useContext(NotificationContext);
  const useReportSearchServices = useReportSearchService();
  const useEmployeeSearchServices = useEmployeeSearchService();
  const certificateService = useCertificateService();
  const { formatCycle } = useFormatting();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(false);
  const [calendarLabel, setCalendarLabel] = useState({
    label: 'Select Date Range',
    color: 'black',
  });
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedFinanceStatus, setSelectedFinanceStatus] = useState([0]);
  const [globalFilterValues, setGlobalFilterValues] = useState([]);
  const { postId } = sessionStore.employeeUser;
  const { currentMissionId } = sessionStore;
  const {
    cycleId,
    sectionId,
    nominationStatusId,
    awardTypeId,
    groupAttributeOptionId,
    nomineePostAgencyId,
    nominatorPostAgencyId,
    nomineeTerm,
    dateRange,
    awardProcessType,
    effectiveDate,
  } = 0;
  const { mode } = useParams();
  const isCertificateMode = useCallback(() => mode.toLowerCase() === 'certificates', [mode]);
  const isAllAwardsMode = useCallback(() => mode.toLowerCase() === 'allawards', [mode]);
  const isFinanceMode = useCallback(() => mode.toLowerCase() === 'finance', [mode]);
  const isExecutiveMode = useCallback(() => mode.toLowerCase() === 'executive', [mode]);
  const isCheckboxEnabled = isCertificateMode();
  const dt = useRef(null);
  const { getURL, goTo } = useNavigationLinks();
  const { checkRole, checkPermission } = useAuthorization();

  const setSelectedRecords = (selectedRecords) => {
    dispatch({
      type: 'UPDATE_SELECTED_RECORDS',
      data: selectedRecords,
    });
  };

  const generateCertificates = async (sealUrl, signatureUrl, signer, format) => {
    dispatch({ type: 'START_CERTIFICATE_GENERATION' });
    const selectedNominationIds = await state.selectedRecords.map((x) => x.id);
    if (format === 'PDF') {
      await certificateService.generateCertificate(
        selectedNominationIds,
        currentMissionId,
        sealUrl,
        signatureUrl,
        signer
      );
    } else if (format === 'WORD') {
      await certificateService.generateWordCertificate(
        selectedNominationIds,
        currentMissionId,
        sealUrl,
        signatureUrl,
        signer
      );
    }
    dispatch({ type: 'COMPLETE_CERTIFICATE_GENERATION' });
  };

  const onClickCloseCertificatePrintModal = () => {
    dispatch({ type: 'CLOSE_CERTIFICATE_PRINT_MODAL' });
  };

  const onClickOpenCertificatePrintModal = () => {
    dispatch({ type: 'OPEN_CERTIFICATE_PRINT_MODAL' });
  };

  const setParamValue = (paramValue) => {
    dispatch({
      type: 'UPDATE_SEARCH_PARAMS',
      data: { ...paramValue },
    });
  };

  const getCyclesList = useCallback(
    (cycles) => {
      const result = cycles.map((item) => {
        return { key: item.id, value: formatCycle(item) };
      });
      return result.sort(dynamicSort('value'));
    },
    [formatCycle]
  );

  const saveAsExcelFile = (buffer, fileName) => {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    const filename = `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`;

    //     module.default.saveAs(data, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
    download(data, filename);
    //   }
    // });
  };
  const exportExcel = () => {
    const filteredResults =
      globalFilterValues.length > 0 && (globalFilter !== '' || globalFilter !== null)
        ? globalFilterValues
        : state.awardNominations;

    import('xlsx').then((xlsx) => {
      let worksheet;
      if (isAllAwardsMode()) {
        worksheet = xlsx.utils.json_to_sheet(generateAllAwardsReportData(filteredResults));
      } else if (isFinanceMode()) {
        worksheet = xlsx.utils.json_to_sheet(generateFinanceReportData(filteredResults));
      } else if (isCertificateMode()) {
        worksheet = xlsx.utils.json_to_sheet(generateCertificateReportData(filteredResults));
      } else if (isExecutiveMode()) {
        worksheet = xlsx.utils.json_to_sheet(generateExecutiveReportData(filteredResults));
      }
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      saveAsExcelFile(excelBuffer, 'Report');
    });
  };

  const groupOrIndividualTemplate = (nomination) => {
    let text = 'Individual';
    let icon = faUser;
    if (nomination.isGroup) {
      text = 'Group';
      icon = faUserFriends;
    }
    return (
      <>
        <FontAwesomeIcon icon={icon} className="mr-1" fixedWidth />
        {text}
      </>
    );
  };

  const getLink = useCallback(
    (id, status) => {
      return getURL('view_nomination_form', { id });
    },
    [getURL, mode]
  );

  const keyBodyTemplate = useCallback(
    (rowData) => {
      return (
        <Link to={getLink(rowData.id, rowData.nominationStatus)}>
          <span>{rowData.nominationKey}</span>
        </Link>
      );
    },
    [getLink]
  );

  const linkBodyTemplate = useCallback(
    (rowData) => {
      return <Link to={getLink(rowData.id, rowData.nominationStatus)}>{rowData.nominationStatusName}</Link>;
    },
    [getLink]
  );

  const dateDisplayTemplate = useCallback((rowData) => {
    if (rowData.effectiveDate) {
      const date = new Date(rowData.effectiveDate);
      return date.toLocaleDateString();
    }
    return rowData.effectiveDate;
  });

  const nomineesTemplate = (nomination) => {
    return (
      <div className="module line-clamp">
        {nomination.nominationEmployees
          .filter((x) => x.approvalStatus === approvalStatus.APPROVED)
          .map((e) => (
            <div key={`${nomination.id}_${e.employeeId}`}>{e.agency ? `${e.name} (${e.agency})` : `${e.name}`}</div>
          ))}
      </div>
    );
  };

  const usdhSupervisorsTemplate = (nomination) => {
    return (
      <div className="module line-clamp">
        {nomination.nominationEmployees
          .filter((x) => x.approvalStatus === approvalStatus.APPROVED)
          .map((e) => (
            <div key={`${e.employeeId}_${e.usdhSupervisorName}`}>{e.usdhSupervisorName ?? 'No Supervisor'}</div>
          ))}
      </div>
    );
  };

  const votingStatusTemplate = (nomination) => {
    return <div className="module line-clamp">{getEnumName(votingStatus, nomination.votingStatus)}</div>;
  };

  async function onNominationsSearchClick() {
    const searchRequestData = {
      missionId: state.searchParams.selectedMissionId,
      postId: state.searchParams.selectedPostId,
      sectionId: state.searchParams.selectedSectionId,
      cycleId: state.searchParams.selectedCycleId,
      awardProcessType: state.searchParams.selectedProcessType,
      nominationStatusId: state.searchParams.selectedNominationStatusId,
      nominationStatusesforFinance: isExecutiveMode()
        ? [
            nominationStatus.PENDING_EXEC_SIGNOFF,
            nominationStatus.PENDING_FINANCIAL_SIGNOFF,
            nominationStatus.PENDING_FINANCIAL_INFORMATION,
          ]
        : state.searchParams.selectedNominationStatuses,
      awardTypeId: state.searchParams.selectedAwardTypeId,
      groupAttributeOptionId: state.searchParams.selectedGroupAttributeOptionId,
      nomineePostAgencyId: state.searchParams.selectedNomineePostAgencyId,
      nominatorPostAgencyId: state.searchParams.selectedNominatorPostAgencyId,
      nomineeTerm: state.searchParams.textNominee,
      dateRange: state.searchParams.selectedDateRange,
      effectiveDate:
        state.searchParams.selectedEffectiveDate && getMomentDate(state.searchParams.selectedEffectiveDate),
      reportType: isExecutiveMode()
        ? reportType.Executive
        : isFinanceMode()
        ? reportType.Finance
        : isCertificateMode()
        ? reportType.Certificate
        : reportType.All,
    };
    dispatch({ type: 'START_SEARCH_REQUEST' });
    const response =
      isFinanceMode() || isExecutiveMode()
        ? await useReportSearchServices.financeReportSearch(searchRequestData)
        : await useReportSearchServices.nominationsSearch(searchRequestData);
    if (response && response.ok) {
      showSuccess(`Number of results ${response.data.length}`);
      const searchResultList = response.data.map((item) => ({
        ...item,
        Individual_Group: `${item.isGroup ? 'Group' : 'Individual'}`,
      }));
      dispatch({ type: 'UPDATE_SEARCH_RESULT', data: searchResultList });
    }
  }

  async function onResetNominationsSearchClick() {
    setSelectedFinanceStatus([0]);
    dispatch({ type: 'RESET_SEARCH_FORM', data: [] });
  }

  async function loadPostsByMissionId(selectedMissionId) {
    const response = await useEmployeeSearchServices.getAvailablePostsByMission(selectedMissionId);

    if (response && response.ok) {
      dispatch({
        type: 'UPDATE_AVAILABLE_PARAMS',
        data: {
          posts: addDefaultValueToList(response.data),
        },
      });
      dispatch({
        type: 'UPDATE_SEARCH_PARAMS',
        data: {
          selectedPostId: response.data.length > 0 ? response.data[0].key : 0,
        },
      });
    }
  }

  // test remove later
  useEffect(() => {
    async function loadSearchParameters() {
      dispatch({ type: 'START_REQUEST_SEARCH_PARAMS' });
      const response = await useReportSearchServices.getSearchAvailableParameters(currentMissionId);
      if (response && response.ok) {
        dispatch({
          type: 'UPDATE_AVAILABLE_PARAMS',
          data: {
            missions: response.data.missions,
            bureaus: response.data.bureaus,
            missionsAndBureaus: getMissionsAndBureausList(response.data.bureaus, response.data.missions),
            posts: loadPostsByMissionId(currentMissionId),
            sections: addDefaultValueToList(response.data.sections),
            cycles: addDefaultValueToList(getCyclesList(response.data.cycles)),
            nominationStatuses: addDefaultValueToList(
              differenceBy(response.data.nominationStatuses, [{ key: 100, value: 'Draft' }], 'key')
            ),
            awardTypes: addDefaultValueToList(response.data.awardTypes),
            groupAttributeOptions: addDefaultValueToList(response.data.groupAttributeOptions),
            postAgencies: addDefaultValueToList(response.data.postAgencies),
          },
        });
        dispatch({
          type: 'UPDATE_SEARCH_PARAMS',
          data: {
            selectedMissionId: currentMissionId,
            selectedPostId: postId,
            selectedSectionId: sectionId,
            selectedCycleId: cycleId,
            selectedNominationStatusId: isAllAwardsMode() ? nominationStatusId : nominationStatus.COMPLETED,
            selectedNominationStatuses: selectedFinanceStatus,
            selectedProcessType: awardProcessType,
            selectedAwardTypeId: awardTypeId,
            selectedGroupAttributeOptionId: groupAttributeOptionId,
            sselectedNomineePostAgencyId: nomineePostAgencyId,
            selectedNominatorPostAgencyId: nominatorPostAgencyId,
            textNominee: nomineeTerm,
            selectedDateRange: dateRange,
            selectedEffectiveDate: effectiveDate,
          },
        });
      }
    }
    if (currentMissionId && state.availableParamsRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadSearchParameters();
    }
  }, [
    currentMissionId,
    getCyclesList,
    isAllAwardsMode,
    isCertificateMode,
    postId,
    state.availableParamsRequest,
    useReportSearchServices,
  ]);

  useEffect(() => {
    dispatch({ type: 'RESET_SEARCH_FORM', data: [] });
  }, [isAllAwardsMode, isCertificateMode, isFinanceMode, isExecutiveMode]);

  useEffect(() => {
    // const reportPages = ['certificates', 'allawards', 'finance', 'executive'];

    const doesUserHaveHrRole = checkRole('hr_officer');
    const doesUserHaveExecPermissions = checkRole('exec_signoff');
    const doesUserHaveFinancePermissions = checkRole('fm_input_fiscaldata');
    const doesHaveFMOPermissions = checkRole('fmo_signoff');

    if ((isAllAwardsMode() || isCertificateMode()) && !doesUserHaveHrRole) {
      goTo('not_authorized');
    }

    if (isFinanceMode() && !(doesUserHaveFinancePermissions || doesHaveFMOPermissions || doesUserHaveHrRole)) {
      goTo('not_authorized');
    }

    if (isExecutiveMode() && !(doesUserHaveExecPermissions || doesUserHaveHrRole)) {
      goTo('not_authorized');
    }
  }, [mode, checkRole, checkPermission, goTo]);

  const setTitle = () => {
    const title =
      mode === 'Finance'
        ? 'Finance'
        : mode === 'Certificates'
        ? 'Certificates'
        : mode === 'Executive'
        ? 'Executive'
        : 'All Awards';
    return <h4>Reports - {title}</h4>;
  };

  const handleDateRange = (dates) => {
    setParamValue({ selectedDateRange: dates });
    if (dates === null) {
      setIsSearchButtonDisabled(false);
      setCalendarLabel({ label: 'Select date range', color: 'black' });
    } else if (dates[0] !== null && dates[1] !== null) {
      setIsSearchButtonDisabled(false);
      setCalendarLabel({ label: 'Select date range', color: 'black' });
    } else if (dates[1] === null) {
      setIsSearchButtonDisabled(true);
      setCalendarLabel({ label: 'Please pick two dates', color: 'red' });
    }
  };

  const handleSelectedFinanceStatus = (data) => {
    setSelectedFinanceStatus(data);
    setParamValue({
      selectedNominationStatuses: data.keys,
      selectedProcessType: data.proccess,
    });
  };

  const financeStatuses = [
    {
      key: { keys: [0], proccess: processType.NONE },
      value: 'All',
    },
    {
      key: {
        keys: [
          nominationStatus.PENDING_EXEC_SIGNOFF,
          nominationStatus.PENDING_FINANCIAL_SIGNOFF,
          nominationStatus.PENDING_FINANCIAL_INFORMATION,
        ],
        proccess: processType.ANNUAL,
      },
      value: 'JCAC Endorsed',
    },
    {
      key: {
        keys: [
          nominationStatus.PENDING_ADDITIONAL_APPROVAL,
          nominationStatus.PENDING_MANAGEMENT_APPROVAL,
          nominationStatus.PENDING_FINANCIAL_SIGNOFF,
          nominationStatus.PENDING_FINANCIAL_INFORMATION,
        ],
        proccess: processType.ANYTIME,
      },
      value: 'Anytime',
    },
    {
      key: { keys: [nominationStatus.COMPLETED], proccess: processType.NONE },
      value: 'Completed',
    },
  ];

  return (
    <div className="all-awards">
      {setTitle()}
      <Row>
        <Col>
          Bureau / Mission
          <div>
            <Dropdown
              value={state.searchParams.selectedMissionId}
              options={state.availableParams.missionsAndBureaus.filter(
                (x) => x.key === state.searchParams.selectedMissionId
              )}
              optionLabel="value"
              optionValue="key"
              filterBy="value"
              filter
              showClear
              disabled
              className="w-100"
            />
          </div>
        </Col>
        <Col>
          <div>
            Post
            <Dropdown
              value={state.searchParams.selectedPostId}
              options={state.availableParams.posts}
              optionLabel="value"
              optionValue="key"
              showClear
              onChange={(e) => {
                setParamValue({ selectedPostId: e.value });
              }}
              className="w-100"
            />
          </div>
        </Col>
        <Col>
          <div>
            Section
            <FontAwesomeIcon icon={faExclamationCircle} id="SectionID" className="ml-2" />
            <UncontrolledTooltip placement="top" target="SectionID">
              Other sections may appear in the search results for the group nominations
            </UncontrolledTooltip>
            <Dropdown
              value={state.searchParams.selectedSectionId}
              options={state.availableParams.sections}
              optionLabel="value"
              optionValue="key"
              showClear
              onChange={(e) => {
                setParamValue({ selectedSectionId: e.value });
              }}
              placeholder="All"
              className="w-100"
            />
          </div>
        </Col>
        <Col>
          <div>
            Cycle
            <Dropdown
              value={state.searchParams.selectedCycleId}
              options={state.availableParams.cycles}
              optionLabel="value"
              optionValue="key"
              showClear
              onChange={(e) => setParamValue({ selectedCycleId: e.value })}
              placeholder="All"
              className="w-100"
            />
          </div>
        </Col>
        {isFinanceMode() && (
          <>
            <Col>
              <div>
                <p className="mb-0 " style={{ color: calendarLabel.color }}>
                  {calendarLabel.label}
                </p>
                <Calendar
                  id="range"
                  value={state.searchParams.selectedDateRange}
                  onChange={(e) => handleDateRange(e.value)}
                  selectionMode="range"
                  readOnlyInput
                  showIcon
                  showButtonBar
                />
              </div>
            </Col>
            <Col>
              <div>
                <p className="mb-0 ">Effective Date</p>
                <Calendar
                  value={state.searchParams.selectedEffectiveDate}
                  onChange={(e) => setParamValue({ selectedEffectiveDate: e.value })}
                  readOnlyInput
                  showIcon
                  showButtonBar
                />
              </div>
            </Col>
          </>
        )}
      </Row>
      <Row className="mt-3 justify-content-md-end ">
        {(isAllAwardsMode() || isCertificateMode()) && (
          <Col>
            <div>
              Status
              <Dropdown
                value={state.searchParams.selectedNominationStatusId}
                options={state.availableParams.nominationStatuses}
                optionLabel="value"
                optionValue="key"
                showClear
                onChange={(e) => setParamValue({ selectedNominationStatusId: e.value })}
                placeholder="All"
                className="w-100"
              />
            </div>
          </Col>
        )}
        {isFinanceMode() && (
          <Col>
            <div>
              Status
              <Dropdown
                value={selectedFinanceStatus}
                options={financeStatuses}
                optionLabel="value"
                optionValue="key"
                showClear
                onChange={(e) => handleSelectedFinanceStatus(e.value)}
                placeholder="All"
                className="w-100"
              />
            </div>
          </Col>
        )}
        <Col>
          <div>
            Type
            <Dropdown
              value={state.searchParams.selectedAwardTypeId}
              options={state.availableParams.awardTypes}
              optionLabel="value"
              optionValue="key"
              filterBy="value"
              showClear
              onChange={(e) => setParamValue({ selectedAwardTypeId: e.value })}
              placeholder="All"
              className="w-100"
            />
          </div>
        </Col>
        <Col xs="2">
          <div>
            Individual / Group
            <Dropdown
              value={state.searchParams.selectedGroupAttributeOptionId}
              options={state.availableParams.groupAttributeOptions}
              optionLabel="value"
              optionValue="key"
              filterBy="value"
              showClear
              onChange={(e) => setParamValue({ selectedGroupAttributeOptionId: e.value })}
              placeholder="All"
              className="w-100"
            />
          </div>
        </Col>
        {isAllAwardsMode() && (
          <Col>
            <div>
              Nominee Agency
              <FontAwesomeIcon icon={faExclamationCircle} id="AgencyID" className="ml-2" />
              <UncontrolledTooltip placement="top" target="AgencyID">
                Other agencies may appear in the search results for the group nominations
              </UncontrolledTooltip>
              <Dropdown
                value={state.searchParams.sselectedNomineePostAgencyId}
                options={state.availableParams.postAgencies}
                optionLabel="value"
                optionValue="key"
                filterBy="value"
                filter
                showClear
                onChange={(e) => setParamValue({ sselectedNomineePostAgencyId: e.value })}
                placeholder="All"
                className="w-100"
              />
            </div>
          </Col>
        )}
        {isAllAwardsMode() ||
          (isFinanceMode() && (
            <Col>
              <div>
                Nominator Agency
                <Dropdown
                  value={state.searchParams.selectedNominatorPostAgencyId}
                  options={state.availableParams.postAgencies}
                  optionLabel="value"
                  optionValue="key"
                  filterBy="value"
                  filter
                  showClear
                  onChange={(e) => setParamValue({ selectedNominatorPostAgencyId: e.value })}
                  placeholder="All"
                  className="w-100"
                />
              </div>
            </Col>
          ))}
        <Col xs="3">
          <div>
            Nominee
            <InputText
              value={state.searchParams.textNominee || ''}
              onChange={(e) => setParamValue({ textNominee: e.target.value })}
              className="w-100"
            />
          </div>
        </Col>
        <Col sm="2">
          <div className="d-flex flex-column">
            <Button
              label="Search"
              icon="pi pi-search"
              className="mb-1 p-button-primary p-button-sm"
              onClick={() => onNominationsSearchClick()}
              disabled={isSearchButtonDisabled}
            />
            <Button
              label="Reset"
              icon="pi pi-refresh"
              onClick={() => onResetNominationsSearchClick()}
              className="p-button-outlined p-button-xs"
            />
          </div>
        </Col>
      </Row>
      <hr className="solid" />
      <Row className="mt-3">
        <Col>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Filter" />
          </span>

          {isCertificateMode() && (
            <>
              <Button
                label="Generate Certificates"
                icon="pi pi-print"
                onClick={() => onClickOpenCertificatePrintModal()}
                disabled={state.selectedRecords.length < 1}
                className="min-w-240px  btn-sm ml-4"
              />
              <Button
                label="Export to Excel"
                icon="pi pi-file-excel"
                onClick={() => exportExcel()}
                disabled={state.selectedRecords.length < 1}
                className="min-w-80px btn-sm ml-4"
              />
            </>
          )}
          {(isFinanceMode() || isAllAwardsMode() || isExecutiveMode()) && (
            <Button
              label="Export to Excel"
              icon="pi pi-file-excel"
              onClick={() => exportExcel()}
              disabled={state.awardNominations.length < 1}
              className="min-w-80px btn-sm ml-4"
            />
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {/* {state.searchRequestStatus === requestStatus.REQUEST_NOT_INITIATED && state.awardNominations.length === 0 && (
            <div>Please, fill the parameters and click on Search Button.</div>
          )} */}
          {state.searchRequestStatus === requestStatus.REQUEST_IN_PROGRESS && (
            <SkeletonTable colsSize={4} rowsSize={3} />
          )}
          {state.searchRequestStatus === requestStatus.REQUEST_COMPLETED && (isAllAwardsMode() || isCertificateMode()) && (
            <Grid
              items={state.awardNominations}
              className="p-datatable-striped all-awards-datatable-result"
              scrollable
              scrollHeight="400px"
              autoLayout
              metaKeySelection={false}
              dataKey="id"
              globalFilter={globalFilter}
              emptyMessage="No records found."
              selectedRecords={state.selectedRecords}
              onSelectionChange={(e) => setSelectedRecords(e.value)}
              ref={dt}
              onValueChange={(filteredData) => setGlobalFilterValues(filteredData)}
            >
              {isCheckboxEnabled && <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />}
              <Column
                className="w-15"
                field="nominationKey"
                header="Key"
                sortable
                exportable={false}
                body={keyBodyTemplate}
              />
              <Column
                className="w-15"
                field="nominationStatusName"
                header="Status"
                body={linkBodyTemplate}
                hidden={isCertificateMode()}
                sortable
              />
              <Column className="w-25" field="awardTypeName" header="Type" sortable />
              <Column
                className="w-15"
                field="Individual_Group"
                header="Individual / Group"
                body={groupOrIndividualTemplate}
                sortable
              />
              <Column className="w-25" field="nominees" header="Nominees" body={nomineesTemplate} sortable />
              <Column className="w-20" field="nominatorName" header="Nominator" sortable />
            </Grid>
          )}

          {state.searchRequestStatus === requestStatus.REQUEST_COMPLETED && isFinanceMode() && (
            <Grid
              items={state.awardNominations}
              className="p-datatable-striped all-awards-datatable-result"
              scrollable
              scrollHeight="400px"
              autoLayout
              metaKeySelection={false}
              dataKey="id"
              globalFilter={globalFilter}
              emptyMessage="No records found."
              selectedRecords={state.selectedRecords}
              onSelectionChange={(e) => setSelectedRecords(e.value)}
              // eslint-disable-next-line react/jsx-boolean-value
              showGridlines={true}
              onValueChange={(filteredData) => setGlobalFilterValues(filteredData)}
            >
              {isCheckboxEnabled && <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />}
              <Column className="w-15" field="nominationKey" header="Key" sortable />
              <Column className="w-15" field="nominees" header="Name of Employee" body={nomineesTemplate} sortable />
              <Column className="w-25" field="awardTypeName" header="Type" sortable />
              <Column className="w-25" field="approvedAmount" header="Amount" sortable />
              <Column className="w-25" field="effectiveDate" header="Effective Date" body={dateDisplayTemplate} />
              <Column className="w-25" field="nominatorName" header="Nominator" sortable />
              <Column className="w-25" field="nominatorAgency" header="Nominator's Agency" sortable />
              <Column className="w-25" header="Nominator's Funding" sortable />
              <Column className="w-25" field="fiscalStrip" header="Fiscal Data" sortable />
            </Grid>
          )}

          <CertificatePrintModal
            isModalOpened={state.isCertificatePrintModalOpened}
            onClickCloseModal={onClickCloseCertificatePrintModal}
            nominationList={state.selectedRecords}
            generateCertificates={generateCertificates}
            generateCertificatesRequest={state.generateCertificatesRequest}
            missionId={currentMissionId}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          {state.searchRequestStatus === requestStatus.REQUEST_IN_PROGRESS && (
            <SkeletonTable colsSize={4} rowsSize={3} />
          )}
          {state.searchRequestStatus === requestStatus.REQUEST_COMPLETED && isExecutiveMode() && (
            <Grid
              items={state.awardNominations}
              className="p-datatable-striped all-awards-datatable-result"
              scrollable
              scrollHeight="400px"
              autoLayout
              metaKeySelection={false}
              dataKey="id"
              globalFilter={globalFilter}
              emptyMessage="No records found."
              selectedRecords={state.selectedRecords}
              onSelectionChange={(e) => setSelectedRecords(e.value)}
              ref={dt}
              onValueChange={(filteredData) => setGlobalFilterValues(filteredData)}
            >
              <Column
                className="w-15"
                field="nominationKey"
                header="Key"
                sortable
                exportable={false}
                body={keyBodyTemplate}
              />
              <Column className="w-10" field="votingStatus" header="JCAC Vote" body={votingStatusTemplate} sortable />
              <Column className="w-15" field="nominatorName" header="Nominator" sortable />
              <Column className="w-20" field="nominees" header="Nominees" body={nomineesTemplate} sortable />
              <Column
                className="w-20"
                field="USDH Supervisors"
                header="USDH Supervisors"
                body={usdhSupervisorsTemplate}
                sortable
              />
              <Column className="w-15" field="awardTypeName" header="Award Type" sortable />
              <Column className="w-5" field="approvedAmount" header="Award Amount" sortable />
            </Grid>
          )}

          <CertificatePrintModal
            isModalOpened={state.isCertificatePrintModalOpened}
            onClickCloseModal={onClickCloseCertificatePrintModal}
            nominationList={state.selectedRecords}
            generateCertificates={generateCertificates}
            generateCertificatesRequest={state.generateCertificatesRequest}
            missionId={currentMissionId}
          />
        </Col>
      </Row>
    </div>
  );
}

export default AllAwards;
