import React from 'react';
import { Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { SessionProvider, NotificationProvider } from 'contexts';
import { oktaConfig } from 'config/index';
import { Landing, Logout, ErrorPage, NotAuthorized, NotAuthorizedOAS, Forbidden } from 'views/Public';
import SecureApp from './SecureApp';

import './App.css';

const oktaAuth = new OktaAuth(oktaConfig);

function App() {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/login/callback" component={LoginCallback} />
        <Route exact path="/Logout" component={Logout} />
        <Route exact path="/NotAuthorized" component={NotAuthorized} />
        <Route exact path="/NotAuthorizedOAS" component={NotAuthorizedOAS} />
        <Route exact path="/Forbidden" component={Forbidden} />
        <Route exact path="/ErrorPage/:errorType" component={ErrorPage} />
        <Route exact path="/" component={Landing} />
        <Route>
          <SessionProvider>
            <NotificationProvider>
              <SecureApp />
            </NotificationProvider>
          </SessionProvider>
        </Route>
      </Switch>
    </Security>
  );
}

export default App;
